import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import {
  Box,
  Row,
  Column,
  Typography,
  Switch,
  When,
  CommonSelect,
  Divider,
  CustomButton,
  Icon,
  Checkbox,
  CustomAlert,
  ActionLink,
} from "..";
import DisableAutomationModal from "./DisableAutomationModal";
import {
  deleteMappedAutomation,
  downloadWorkFlowMappingList,
  getAutomationTogglesAPI,
  getERPCategorisation,
  getIntegrationCount,
  getMappedAutomation,
  getWorkFlowPreventCount,
  saveAutomationMapping,
  saveAutomationTogglesAPI,
  savePreventWorkFlowApi,
  updateAutomationMapping,
} from "../../../api";
import AddMappingDropdown from "../AddMappingDropdown";
import MappingRow from "./MappingRow";
import { useToggle } from "../../../hooks";
import {
  NOTIFICATION_TYPE,
  isEmpty,
  showNotification,
  constructClassName,
  sortingArray,
  isDevelopmentEnvironment,
  isStageEnvironment,
  INVOICE_PREVENT_TYPE,
  downloadFileByJS,
  fileNameForExcel,
  SUPPORT_EMAIL,
} from "../../../utils";
import {
  ACCENTS,
  AUTOMATION_CONSTANT,
  BUTTON_VARIANT,
  CONFIGURATIONS_KEYS,
  ERROR_MSG,
  INTEGRATION_TYPE,
  PREVENT_ASSIGNMENT_WORKFLOW,
  REMAINING_OPTION,
  SMART_APPROVALS_TABS_KEYS,
  STATIC_ERP_TRIGGER_FIELDS,
  VIEW_LESS_BUTTON,
  VIEW_MORE_BUTTON,
  WORKFLOW_ALERT_MESSAGE,
} from "../../../constants";
import "./WorkflowAutomation.scss";
import CommonToolTip from "../Tooltip";
import {
  isAutomationEnabledForItemsPO,
  isEnableOnlyWhenConfirmedInvoice,
} from "../../../helper";

function WorkflowAutomation(props) {
  const {
    workflows,
    title,
    subtitle,
    getAutomationList,
    type,
    getAutomationFieldValue,
    configData,
  } = props;

  const [automationToggleData, setAutomationToggleData] = useState(null);
  const [automationList, setAutomationList] = useState(null);
  const [automationField, setAutomationField] = useState(null);
  const [automationFieldValues, setAutomationFieldValues] = useState(null);
  const [selectedOption, setSelectedOptions] = useState([]);
  const [savedSelectedOption, setSavedSelectedOptions] = useState([]);
  const [deleteSelection, setDeleteSelection] = useState([]);
  const [showWorkflowMapping, setShowWorkflowMapping] = useState(false);
  const [mappingExist, setMappingExist] = useState(false);
  const [mappedFields, setMappedFields] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const [MappingDeleteId, setMappingDeleteId] = useState([]);
  const [fromInitialState, setFormInitialState] = useState({});
  const [apiMAppingData, setApiMAppingData] = useState([]);
  const [deleteMAppingKey, setDeleteMAppingKey] = useState("");
  const [triggerMapping, setTriggerMapping] = useState([]);
  const [saveBtnDisable, setSaveBtnDisable] = useState(false);
  const [remainingDelete, setRemainingDelete] = useState({});
  const { isOpen: disableModal, toggle } = useToggle(false);
  const [checkBoxPrevent, setCheckBoxPrevent] = useState(false);
  const [isPreventCheckBoxModal, setIsPreventCheckBoxModal] = useState(false);
  const [isWorkflowAutomationHide, setWorkflowAutomationShow] = useState(false);
  const [isVisibleWorkFlowAutomation, setWorkFlowAutomationToggle] =
    useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    handleSaveDisable();
  }, [triggerMapping, savedSelectedOption]);

  const handleSaveDisable = async () => {
    const values = await form.validateFields();
    const notChangeMapping =
      values &&
      Object.keys(values).length > 0 &&
      Object.keys(values).every(
        (d, i) =>
          (values[d] === undefined ? null : values[d]) === fromInitialState[d]
      );
    if (!notChangeMapping) {
      setSaveBtnDisable(true);
    } else {
      if (triggerMapping.length === savedSelectedOption.length) {
        setSaveBtnDisable(false);
      } else {
        setSaveBtnDisable(true);
      }
    }
  };

  const handleAutomationList = async (key) => {
    let mappedEntity = [];
    try {
      mappedEntity = await getERPCategorisation();
    } catch (error) {
      console.error("Failed to fetch ERP categorisation:", error);
      mappedEntity = [];
    }

    const entityId =
      type === INTEGRATION_TYPE.po && mappedEntity.length > 1
        ? null
        : mappedEntity[0]?.entity_id;
    const [list, mappedValue] = await Promise.all([
      getAutomationList(type, entityId),
      getMappedAutomation(type),
    ]);
    resetFormToLatestState(mappedValue, list, key);
    setAutomationList(list);
  };

  const resetFormToLatestState = (mappedValue, list = automationList, key) => {
    if (mappedValue?.mapped_fields?.length) {
      const optionGroup = list.find(
        (item) => item.value === mappedValue.mapped_fields[0]?.field_type_id
      );
      const option = optionGroup.options.find(
        (item) => item.value === mappedValue.mapped_fields[0]?.field_id
      );
      const selectedField = [];
      const valueMapping = [];
      let formObject = {};
      let mappedValueID = {};

      mappedValue.mapped_fields.forEach((field) => {
        formObject[`${field.field_value_id}`] = field.workflow_id;
        selectedField.push(field.field_value_id);
        mappedValueID[`${field.field_value_id}`] = field.id;
        valueMapping.push({ [`${field.field_value_id}`]: field.workflow_id });
        if (field.field_value_id === REMAINING_OPTION.value) {
          setRemainingDelete({
            fId: field?.field_value_id ? true : false,
            wId: field?.workflow_id ? true : false,
          });
        }
      });
      const addRemaining = selectedField.some(
        (field) => field == REMAINING_OPTION.value
      );
      if (!addRemaining) {
        selectedField.push(REMAINING_OPTION.value);
      }
      let isRemaining = false;
      valueMapping &&
        valueMapping.forEach((k) => {
          if (Object.keys(k)[0] === REMAINING_OPTION.value) {
            isRemaining = true;
          }
        });

      if (!isRemaining) {
        valueMapping.push({
          [`${REMAINING_OPTION.value}`]: null,
        });
        formObject[`${REMAINING_OPTION.value}`] = null;
      }
      setViewMore(false);
      setTriggerMapping(valueMapping);
      setMappedFields(mappedValueID);
      setFormInitialState(formObject);
      form.setFieldsValue(formObject);
      setSelectedOptions(selectedField);
      setSavedSelectedOptions(selectedField);
      setApiMAppingData(selectedField);
      setShowWorkflowMapping(true);
      setMappingExist(true);
      handleAutomationSelect(null, option, true, true);
    } else {
      setMappedFields(null);
      setSelectedOptions([]);
      setSavedSelectedOptions([]);
      setShowWorkflowMapping(false);
      setMappingExist(false);
    }
  };

  const handleAutomationSwitch = async () => {
    const list = await getAutomationTogglesAPI();
    setAutomationToggleData(list.find((i) => i.type === type) || null);
  };

  const handleAutomationSelect = async (
    id,
    value,
    appendRemainingOption = false,
    fromOtherSource = false,
    selectedField
  ) => {
    setAutomationField(value);
    if (value?.api_path) {
      try {
        const automationFieldValues = await getAutomationFieldValue(
          value?.api_path
        );
        let values = Array.isArray(automationFieldValues)
          ? automationFieldValues
          : automationFieldValues.data;
        const mappedValueSort = sortingArray(values, "label");
        setAutomationFieldValues(mappedValueSort);
      } catch (e) {}
    } else {
      setAutomationFieldValues([]);
    }
    if (!fromOtherSource) {
      setSelectedOptions([]);
      setSavedSelectedOptions([]);
      setDeleteSelection([]);
      setShowWorkflowMapping(false);
    }
  };

  const toggleAutomationEnabled = (checked) => {
    if (!checked) {
      toggle();
      return;
    }
    saveToggleData(true);
  };

  useEffect(() => {
    handleAutomationSwitch();
    if (configData && Object.keys(configData)?.length) {
      if (isEnableOnlyWhenConfirmedInvoice()) {
        let workflowData =
          configData?.[CONFIGURATIONS_KEYS?.workFlowAsignmentInvoice];
        if (workflowData?.is_key_enabled) {
          setCheckBoxPrevent(workflowData.is_key_enabled === 1);
        }
      }
      let checkPOWorkflowKeyExist =
        configData?.[CONFIGURATIONS_KEYS?.poWorkflowAssignmentRetoolUpload];
      if (
        checkPOWorkflowKeyExist?.is_key_enabled &&
        type === INTEGRATION_TYPE.po
      ) {
        setWorkflowAutomationShow(checkPOWorkflowKeyExist.is_key_enabled == 1);
      }
      setWorkFlowAutomationToggle(true);
    }
  }, [configData]);

  useEffect(() => {
    if (
      !isDevelopmentEnvironment() &&
      !isStageEnvironment() &&
      type !== SMART_APPROVALS_TABS_KEYS.PURCHASE_REQUEST &&
      automationToggleData?.automation_toggles?.is_automation_on
    ) {
      handleAutomationList();
    }
  }, [automationToggleData]);

  useEffect(() => {
    if (
      isDevelopmentEnvironment() ||
      isStageEnvironment() ||
      type === SMART_APPROVALS_TABS_KEYS.PURCHASE_REQUEST
    ) {
      handleAutomationList();
    }
  }, []);

  const handleAddMapping = () => {
    setShowWorkflowMapping(true);
    setViewMore(true);
  };

  function deleteSelectedMapping(workFlowId, item) {
    if (workFlowId) {
      setMappingDeleteId([item]);
      setDeleteMAppingKey("DeleteMApping");
      toggle();
    } else {
      const apiDataDelete = apiMAppingData.includes(item);
      handleDelete("", false, [item], apiDataDelete);
    }
  }

  const handleDeletionSelection = async (item) => {
    const workFlowId = fromInitialState[item];
    if (workFlowId) {
      const res = await getIntegrationCount(type, mappedFields[item]);
      if (res.integrations_associated_count > 0) {
        deleteSelectedMapping(workFlowId, item);
      } else {
        const apiDataDelete = apiMAppingData.includes(item);
        handleDelete("", false, [item], apiDataDelete);
      }
    } else {
      deleteSelectedMapping(workFlowId, item);
    }
  };

  const handleSave = async () => {
    const requestPayload = {
      field_id: automationField?.id,
      field_type_id: automationField?.workflow_automation_field_type_id,
    };
    try {
      const values = await form.validateFields();
      const addRemaining = savedSelectedOption.some(
        (field) => field == REMAINING_OPTION.value
      );
      let allField = addRemaining
        ? savedSelectedOption
        : [...savedSelectedOption, REMAINING_OPTION.value];
      requestPayload.mappings = allField.map((item, index) => {
        let req = {
          workflow_id: values[item] || "",
          field_value_id: item,
          order: index + 1,
        };
        if (mappedFields && mappedFields[item]) {
          req.id = mappedFields[item];
        }
        return req;
      });
      if (mappedFields && !isEmpty(mappedFields)) {
        await updateAutomationMapping(type, requestPayload);
      } else {
        await saveAutomationMapping(type, requestPayload);
      }
      onSuccess();
      showNotification(
        NOTIFICATION_TYPE.success,
        `Mappings ${
          mappedFields && !isEmpty(mappedFields) ? "updated" : "saved"
        } successfully`
      );
    } catch (error) {
      showNotification(NOTIFICATION_TYPE.error, ERROR_MSG.common);
    }
  };

  const handleDelete = async (formField, keepWorkflow, Id, key) => {
    let deleteAutomationId = MappingDeleteId.length > 0 ? MappingDeleteId : Id;
    const filterOptions = savedSelectedOption.filter(
      (item) => !deleteAutomationId.includes(item)
    );

    const newFromValue = {};
    const newMappedFields = { ...mappedFields };
    const apiIDs = [];
    deleteAutomationId.forEach((id) => {
      newFromValue[`${id}`] = null;
      delete newMappedFields[id];
      if (mappedFields && !isEmpty(mappedFields) && mappedFields[id]) {
        apiIDs.push(mappedFields[id]);
      }
    });
    form.setFieldsValue(newFromValue);
    if (mappedFields && !isEmpty(mappedFields) && apiIDs.length) {
      const payload = {
        ids: apiIDs,
        is_assigned_workflow_to_be_kept: keepWorkflow,
      };
      await deleteMappedAutomation(type, payload);
      setMappedFields(isEmpty(newMappedFields) ? null : newMappedFields);
    }

    if (MappingDeleteId.length > 0) {
      setDeleteMAppingKey("");

      setMappingDeleteId([]);
      formField.resetFields();
      toggle();
    }
    setSelectedOptions(filterOptions);
    setSavedSelectedOptions(filterOptions);
    setDeleteSelection([]);
    if (filterOptions.length == 0) {
      setMappingExist(false);
    }
    const deleteMappingApi = triggerMapping.findIndex(
      (item) => Object.keys(item)[0] === deleteAutomationId[0]
    );
    if (
      deleteMappingApi !== -1 &&
      deleteAutomationId[0] !== REMAINING_OPTION.value
    ) {
      delete fromInitialState[deleteAutomationId[0]];
      triggerMapping.splice(deleteMappingApi, 1);
      setTriggerMapping(triggerMapping);
    } else {
      setRemainingDelete({});
      fromInitialState[deleteAutomationId[0]] = null;
      triggerMapping.splice(deleteMappingApi, 1);
      setTriggerMapping(triggerMapping);
    }
    if (
      filterOptions.length == 1 &&
      filterOptions[0] == REMAINING_OPTION.value &&
      !remainingDelete["wId"]
    ) {
      const payload = {
        ids: [mappedFields[REMAINING_OPTION.value]],
        is_assigned_workflow_to_be_kept: false,
      };
      setShowWorkflowMapping(false);
      await deleteMappedAutomation(type, payload).then((result) => {
        handleAutomationList();
      });
    }
    const DeleteMSgRemain =
      deleteAutomationId[0] == REMAINING_OPTION.value &&
      filterOptions.length !== 0 &&
      remainingDelete["fId"] &&
      remainingDelete["wId"];
    if (apiIDs?.length > 0) {
      showNotification(
        NOTIFICATION_TYPE.success,
        `${
          DeleteMSgRemain
            ? "Mapping cleared successfully"
            : "Mapping deleted successfully"
        }`
      );
    }
  };

  const onSuccess = async () => {
    const res = await getMappedAutomation(type);
    resetFormToLatestState(res);
  };
  const toggleViewMore = () => {
    setViewMore(!viewMore);
  };
  const checkWorkflowValue = ({ keepWorkflow }) => {
    return keepWorkflow === 3;
  };

  const saveToggleData = async (automationOn, keepWorkflow, formField) => {
    try {
      let payload = {
        integration_id: automationToggleData.id,
        is_automation_on: automationOn,
      };
      if (automationToggleData?.automation_toggles) {
        payload.id = automationToggleData?.automation_toggles?.id;
      }
      if (!automationOn) {
        if (isEnableOnlyWhenConfirmedInvoice()) {
          payload.is_assigned_workflow_to_be_kept = checkWorkflowValue({
            keepWorkflow,
          })
            ? null
            : keepWorkflow;
        } else {
          payload.is_assigned_workflow_to_be_kept = keepWorkflow;
        }
      }
      if (isEnableOnlyWhenConfirmedInvoice()) {
        payload.action_type = checkWorkflowValue({ keepWorkflow })
          ? keepWorkflow
          : null;
      }
      await saveAutomationTogglesAPI(payload);
      if (!automationOn) {
        formField.resetFields();
        toggle();
      }
      handleAutomationSwitch();
    } catch (error) {}
  };

  const handleCancel = () => {
    setSaveBtnDisable(false);
    setDeleteSelection([]);
    form.resetFields();
    handleAutomationList(true);
  };

  const handleOnChange = () => {
    handleSaveDisable();
  };

  const mappingPrints = (automationFieldValues) => {
    const dataValue = [];
    const viewMoreField = [];

    savedSelectedOption &&
      savedSelectedOption.forEach((item) => {
        automationFieldValues &&
          automationFieldValues.forEach((d) => {
            if (item !== "remaining_values") {
              d.value === item && dataValue.push(d);
            }
          });
      });
    if (!viewMore) {
      dataValue &&
        dataValue.forEach((item, i) => {
          i <= 3 && viewMoreField.push(item);
        });
    }
    dataValue.push(REMAINING_OPTION);
    viewMoreField.push(REMAINING_OPTION);

    return viewMore ? dataValue : viewMoreField;
  };

  const handlePreventCheckBox = async () => {
    try {
      const count = await getWorkFlowPreventCount({ checkBoxPrevent });
      if (count > 0) {
        setIsPreventCheckBoxModal(true);
        preventModalAction();
      } else {
        savePreventCheckBoxData(false, null, null);
      }
    } catch (error) {
      showNotification(NOTIFICATION_TYPE.error, ERROR_MSG.common);
    }
  };

  const preventType = () => {
    if (checkBoxPrevent) {
      return INVOICE_PREVENT_TYPE.disablePrevent;
    } else {
      return INVOICE_PREVENT_TYPE.enablePrevent;
    }
  };

  const savePreventCheckBoxData = async (
    automationOn,
    keepWorkflow,
    formField
  ) => {
    try {
      let payload = {
        config_key_name: CONFIGURATIONS_KEYS.workFlowAsignmentInvoice,
        integration_id: automationToggleData.id,
        is_key_enabled: !checkBoxPrevent,
        action_type: keepWorkflow || null,
      };
      await savePreventWorkFlowApi({
        type,
        data: {
          ...payload,
        },
      });
      setCheckBoxPrevent(!checkBoxPrevent);
      setIsPreventCheckBoxModal(false);
      if (formField) {
        preventModalAction(formField);
      }
    } catch (error) {
      showNotification(NOTIFICATION_TYPE.error, ERROR_MSG.common);
      if (formField) {
        preventModalAction(formField);
      }
      setIsPreventCheckBoxModal(false);
    }
  };

  const preventModalAction = (formField = null) => {
    toggle();
    if (formField) {
      formField.resetFields();
    }
  };

  const downloadWorkflowExcel = async () => {
    const excelLink = await downloadWorkFlowMappingList({ type });
    let fileName = fileNameForExcel({
      fileName: excelLink,
    });
    downloadFileByJS(excelLink, fileName);
  };

  const isRequiredERPTriggerField = STATIC_ERP_TRIGGER_FIELDS.includes(
    automationField?.label
  );

  const disableReassignmentForERPFields =
    isAutomationEnabledForItemsPO() &&
    type === INTEGRATION_TYPE.po &&
    isRequiredERPTriggerField;

  const isDisableReassignmentForERPFields = ({ item }) => {
    const itemKeyValuePair = triggerMapping?.find((i) => i[item?.value]);
    return !itemKeyValuePair?.[item?.value]
      ? false
      : disableReassignmentForERPFields;
  };

  return (
    <div className="workflow-automation">
      <When
        condition={!isWorkflowAutomationHide && isVisibleWorkFlowAutomation}
      >
        <When
          condition={
            automationToggleData?.automation_toggles?.is_automation_on !== null
          }
        >
          <div className={"top-panel"}>
            <Row align={"middle"}>
              <Column span={20}>
                <Box
                  alignContent="center"
                  alignItem="center"
                  justifyContent="flexStart"
                  className={"w-100"}
                >
                  <Typography
                    variant="title"
                    text={title}
                    fontSize={24}
                    responsiveFontSize={false}
                    fontStyle="semibold"
                  />
                </Box>
              </Column>
              <Column span={4}>
                <Box>
                  <Switch
                    className={constructClassName([
                      "m-r-60px",
                      automationToggleData?.automation_toggles?.is_automation_on
                        ? ""
                        : "automation-switch-disable",
                    ])}
                    name="workflowSwitch"
                    onClick={toggleAutomationEnabled}
                    checked={
                      automationToggleData?.automation_toggles?.is_automation_on
                    }
                  />
                </Box>
              </Column>
            </Row>
            <Row align={"middle"}>
              <Column span={24}>
                <Box
                  alignContent="center"
                  alignItem="center"
                  justifyContent="flexStart"
                  className={"w-100"}
                >
                  <Typography
                    variant="body"
                    fontSize={14}
                    text={subtitle}
                    className="m-t-16px m-b-0px"
                  />
                </Box>
              </Column>
            </Row>
            <When condition={!!automationList?.length}>
              <Row className={"mapping-workflow-row m-v-16px"}>
                <When
                  condition={
                    !!(!isDevelopmentEnvironment() &&
                    !isStageEnvironment() &&
                    type !== SMART_APPROVALS_TABS_KEYS.PURCHASE_REQUEST
                      ? automationToggleData?.automation_toggles
                          ?.is_automation_on && automationList?.length
                      : automationList?.length)
                  }
                >
                  <Column span={24}>
                    <CommonToolTip
                      placement="topLeft"
                      trigger={!!mappedFields ? ["click", "hover"] : []}
                      title={
                        <Typography
                          variant="tertiary"
                          text={AUTOMATION_CONSTANT.DISABLED_INFO_TOOLTIP}
                        />
                      }
                    >
                      <div className="select-automation">
                        <CommonSelect
                          className={`select-automation ${
                            !!mappedFields ? "custom-text-optional-select" : ""
                          }`}
                          options={automationList}
                          newSelect
                          placeholder={
                            AUTOMATION_CONSTANT.FIELD_SELECT_PLACEHOLDER
                          }
                          value={automationField?.id}
                          onChange={handleAutomationSelect}
                          disabled={!!mappedFields}
                        />
                      </div>
                    </CommonToolTip>
                  </Column>
                </When>

                <When condition={isEnableOnlyWhenConfirmedInvoice()}>
                  <Box
                    justifyContent="flexStart"
                    alignItem="center"
                    className={constructClassName([
                      "w-100",
                      "kid-item-cnt-child",
                      "mx-1",
                    ])}
                  >
                    <Checkbox
                      className="kid-checkbox"
                      checked={checkBoxPrevent}
                      onChange={handlePreventCheckBox}
                    />
                    <Typography
                      variant={"body"}
                      text={PREVENT_ASSIGNMENT_WORKFLOW.text}
                    />
                    <CommonToolTip
                      title={PREVENT_ASSIGNMENT_WORKFLOW?.tootTip?.text}
                      placement={"right"}
                    >
                      <Icon
                        iconName={
                          PREVENT_ASSIGNMENT_WORKFLOW?.tootTip?.iconName
                        }
                        className="material-icons fs-20px cursor-pointer"
                        showIconOutline
                      />
                    </CommonToolTip>
                  </Box>
                </When>
              </Row>
              <When
                condition={
                  !isDevelopmentEnvironment() &&
                  !isStageEnvironment() &&
                  type !== SMART_APPROVALS_TABS_KEYS.PURCHASE_REQUEST
                    ? !!automationField &&
                      automationToggleData?.automation_toggles?.is_automation_on
                    : !!automationField
                }
              >
                <div className="automation-item-border m-b-16px">
                  <Row>
                    <Column span={8}>
                      <Typography
                        variant="body"
                        fontStyle="semibold"
                        text={automationField?.label}
                      />
                    </Column>
                    <Column span={8}>
                      <Typography
                        variant="body"
                        fontStyle="semibold"
                        text={AUTOMATION_CONSTANT.WORKFLOW_COLUMN}
                      />
                    </Column>
                  </Row>
                  <Form
                    form={form}
                    initialValues={fromInitialState}
                    className="m-b-20px"
                  >
                    {showWorkflowMapping &&
                      savedSelectedOption?.length > 0 &&
                      mappingPrints(automationFieldValues)?.map(
                        (item, index) => (
                          <MappingRow
                            selectedOptions={deleteSelection}
                            showCheckBox={!mappingExist}
                            isDisableReassignmentForERPFields={
                              isDisableReassignmentForERPFields
                            }
                            item={item}
                            apiMAppingData={apiMAppingData}
                            onCheck={handleDeletionSelection}
                            className="mapping-workflow-row"
                            label={item?.label}
                            workflows={workflows}
                            handleOnChange={handleOnChange}
                            savedSelectedOption={savedSelectedOption}
                            remainingDelete={remainingDelete}
                          />
                        )
                      )}
                  </Form>
                  <Row>
                    <When
                      condition={
                        !isDevelopmentEnvironment() &&
                        !isStageEnvironment() &&
                        type !== SMART_APPROVALS_TABS_KEYS.PURCHASE_REQUEST
                          ? automationToggleData?.automation_toggles
                              ?.is_automation_on &&
                            automationFieldValues?.length > 0 &&
                            !mappingExist
                          : automationFieldValues?.length > 0 && !mappingExist
                      }
                    >
                      <AddMappingDropdown
                        items={automationFieldValues}
                        selectedOption={selectedOption}
                        setSelectedOptions={setSelectedOptions}
                        onAdd={handleAddMapping}
                        savedSelectedOption={savedSelectedOption}
                        setSavedSelectedOptions={setSavedSelectedOptions}
                      />
                    </When>
                  </Row>
                </div>
              </When>
            </When>
          </div>
          <div className={"bottom-panel"}></div>
          <When
            condition={
              !isDevelopmentEnvironment() &&
              !isStageEnvironment() &&
              type !== SMART_APPROVALS_TABS_KEYS.PURCHASE_REQUEST
                ? savedSelectedOption.length > 4 &&
                  showWorkflowMapping &&
                  automationToggleData?.automation_toggles?.is_automation_on
                : savedSelectedOption.length > 4 && showWorkflowMapping
            }
          >
            <Row className="mapping-workflow-row">
              <Column {...{ xs: 20, sm: 20, md: 20, lg: 20, xl: 20, xxl: 22 }}>
                <Divider orientation="right" />
              </Column>
              <Column {...{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 2 }}>
                <CustomButton
                  variant={BUTTON_VARIANT.LINK}
                  accent={ACCENTS.SECONDARY}
                  icon={
                    <Icon
                      iconName={
                        viewMore ? "keyboard_arrow_up" : "keyboard_arrow_down"
                      }
                    />
                  }
                  text={viewMore ? VIEW_LESS_BUTTON : VIEW_MORE_BUTTON}
                  onClick={toggleViewMore}
                />
              </Column>
            </Row>
          </When>

          <When condition={showWorkflowMapping}>
            <Row>
              <Column span={18} />
              <Column span={6}>
                <Box justifyContent={"flexEnd"}>
                  {mappingExist ? (
                    <CustomButton
                      text={AUTOMATION_CONSTANT.BTN_LABELS.MANAGE_MAPPING}
                      accent={ACCENTS.PRIMARY}
                      variant={BUTTON_VARIANT.BUTTON_FILLED}
                      onClick={() => {
                        handleSaveDisable();
                        setMappingExist(false);
                      }}
                      customSize={"large"}
                    />
                  ) : (
                    <>
                      {deleteSelection.length ? (
                        <CustomButton
                          text={AUTOMATION_CONSTANT.BTN_LABELS.DELETE}
                          accent={ACCENTS.DESTRUCTIVE}
                          variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                          showLoader={true}
                          loaderText={
                            AUTOMATION_CONSTANT.BTN_LABELS.LOADING_DELETE
                          }
                          onClick={handleDelete}
                          className="w-40"
                          customSize={"large"}
                        />
                      ) : (
                        <When condition={selectedOption.length > 0}>
                          <CustomButton
                            text={AUTOMATION_CONSTANT.BTN_LABELS.SAVE}
                            accent={ACCENTS.PRIMARY}
                            variant={BUTTON_VARIANT.BUTTON_FILLED}
                            showLoader={true}
                            loaderText={
                              AUTOMATION_CONSTANT.BTN_LABELS.LOADING_SAVE
                            }
                            disabled={!saveBtnDisable}
                            onClick={handleSave}
                            className="w-40"
                            customSize={"large"}
                          />
                          <CustomButton
                            className="w-40"
                            accent={ACCENTS.SECONDARY}
                            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
                            text={AUTOMATION_CONSTANT.BTN_LABELS.CANCEL}
                            onClick={() => handleCancel()}
                            customSize={"large"}
                          />
                        </When>
                      )}
                    </>
                  )}
                </Box>
              </Column>
            </Row>
          </When>

          <When condition={!!savedSelectedOption.length}>
            <Divider />
          </When>

          <When condition={isPreventCheckBoxModal}>
            <DisableAutomationModal
              type={preventType()}
              open={disableModal}
              toggle={toggle}
              onConfirm={savePreventCheckBoxData}
              setDeleteMAppingKey={preventModalAction}
              showCheckbox={false}
              setIsPreventCheckBoxModal={setIsPreventCheckBoxModal}
              mappingData={mappingPrints(automationFieldValues)}
            />
          </When>
          <When condition={!isPreventCheckBoxModal}>
            <DisableAutomationModal
              type={type}
              open={disableModal}
              keyDelete={deleteMAppingKey}
              toggle={toggle}
              setDeleteMAppingKey={setDeleteMAppingKey}
              handleDelete={handleDelete}
              onConfirm={saveToggleData}
              checkBoxPrevent={checkBoxPrevent}
            />
          </When>
        </When>
      </When>
      <When condition={isWorkflowAutomationHide}>
        <CustomAlert
          className={"my-3"}
          message={
            <>
              <Typography {...WORKFLOW_ALERT_MESSAGE.title} />
              <Typography
                {...WORKFLOW_ALERT_MESSAGE.subTitle}
                className="d-inline"
              />
              <ActionLink {...SUPPORT_EMAIL} className="d-inline" />
              <Typography
                {...WORKFLOW_ALERT_MESSAGE.subTitle2}
                className="d-inline"
              />
            </>
          }
          icon={<Icon iconName="info" className="icon-style large-icon" />}
          type={"success"}
          showIcon
        />

        <CustomButton
          {...WORKFLOW_ALERT_MESSAGE.downloadButton}
          icon={<Icon iconName={"save_alt"} />}
          accent={ACCENTS.PRIMARY}
          variant={BUTTON_VARIANT.BUTTON_FILLED}
          onClick={downloadWorkflowExcel}
        />
        <Divider className={"mb-0"} />
      </When>
    </div>
  );
}

WorkflowAutomation.propTypes = {
  workflows: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  getAutomationList: PropTypes.func,
  getAutomationFieldValue: PropTypes.func,
};

WorkflowAutomation.defaultProps = {
  workflows: [],
  title: "",
  subtitle: "",
  getAutomationList: () => {},
  getAutomationFieldValue: () => {},
};

export default WorkflowAutomation;

import React from "react";
import { Form, Input } from "antd";
import Label from "../Label";
import {
  alphanumericAllowedWithoutScriptTags,
  constructClassName,
} from "../../../utils";
import "../../../assets/scss/FormElement.scss";

const { TextArea } = Input;

const CommonInput = ({
  type,
  style,
  name,
  value,
  label,
  onChange,
  placeholder,
  rules = [],
  avoidScriptTagRule = false,
  validateTrigger,
  className,
  disabled,
  readOnly,
  defaultValue,
  formatter,
  parser,
  maxLength,
  onBlur,
  isTextArea,
  isPassword,
  onKeyPress,
  prefix,
  suffix,
  iconRender,
  size,
  rows,
  autoComplete,
  wrapperClass,
  dependencies,
  hasFeedback,
  bordered,
  optionalField,
  autoSize,
  hideControlsInNumberType = false,
  onInput,
  onPressEnter,
  tooltip,
  labelCol,
  wrapperCol,
  suffixIcon = false,
  suffixIconClassName,
  suffixIconTooltip,
  suffixTooltipList,
  inputRef,
  autoFocus = false,
  allowClear,
}) => {
  const withAvoidScriptRule = isPassword
    ? rules
    : [...rules, alphanumericAllowedWithoutScriptTags];

  const isMandatoryField =
    Array.isArray(rules) && rules?.some((el) => el?.required === true);

  const optionalFieldClassName = isMandatoryField ? "" : "optional-field";

  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <Label
            text={label}
            optional={!isMandatoryField}
            suffixIcon={suffixIcon}
            suffixIconClassName={suffixIconClassName}
            suffixIconTooltip={suffixIconTooltip}
            suffixTooltipList={suffixTooltipList}
          />
        ) : null
      }
      rules={avoidScriptTagRule ? rules : withAvoidScriptRule}
      validateTrigger={validateTrigger}
      style={style}
      className={wrapperClass}
      dependencies={dependencies}
      hasFeedback={hasFeedback}
      colon={false}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      {isTextArea ? (
        <TextArea
          label={
            <Label
              text={label}
              suffixIcon={suffixIcon}
              suffixIconClassName={suffixIconClassName}
              suffixIconTooltip={suffixIconTooltip}
              suffixTooltipList={suffixTooltipList}
            />
          }
          onBlur={onBlur}
          className={constructClassName([
            className || "",
            "kloo-input kloo-input-textarea",
            optionalFieldClassName,
          ])}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          defaultValue={defaultValue}
          formatter={formatter}
          parser={parser}
          onKeyUp={onKeyPress}
          size={size}
          rows={rows}
          autoSize={autoSize}
          onInput={onInput}
          onPressEnter={onPressEnter}
          ref={inputRef}
        />
      ) : isPassword ? (
        <Input.Password
          iconRender={iconRender}
          className={constructClassName([
            className || "",
            "kloo-input",
            optionalFieldClassName,
          ])}
          prefix={prefix}
          suffix={suffix}
          type={type}
          placeholder={placeholder}
          size={size}
          name={name}
          disabled={disabled}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          onInput={onInput}
          onPressEnter={onPressEnter}
          ref={inputRef}
        />
      ) : (
        <Input
          onBlur={onBlur}
          type={type}
          className={constructClassName([
            className || "",
            "kloo-input",
            optionalFieldClassName,
            hideControlsInNumberType ? "hide-controls" : "",
          ])}
          placeholder={placeholder}
          name={name}
          prefix={prefix}
          suffix={suffix}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          defaultValue={defaultValue}
          formatter={formatter}
          parser={parser}
          onKeyUp={onKeyPress}
          bordered={bordered}
          onInput={onInput}
          onPressEnter={onPressEnter}
          ref={inputRef}
          autoFocus={autoFocus}
          onKeyPress={onKeyPress}
          allowClear={allowClear}
        />
      )}
    </Form.Item>
  );
};

export default CommonInput;

import React, { useEffect, useState } from "react";
import Box from "../Box";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import DocViewer from "./RightSection";
import LineItemsHeader from "./Header";
import LineItemsFooter from "./Footer";
import LineItemsTable from "./LineItemsTable/index";
import {
  ITEM_TABLE_LOADER_TEXT,
  LINE_ITEMS_CONTAINER_CLASS,
} from "../../../constants/LineItems";
import { TRACKING_CATEGORY_KEY_PREFIX } from "../../../constants";
import { generateLineItemsPayload } from "../../../helper/LineItems";
import { Form } from "antd";
import When from "../When";
import Typography from "../Typography";
import { ComponentLoader } from "..";
import { getTaxRateList } from "../../../api";

const LineItems = ({
  entityId,
  lineItemsTableData,
  setLineItemsTableData,
  previewImage,
  module,
  onBackClick,
  onSave,
  deleteLineItem,
  disabled,
  setLineItemsValuesChanged,
  onKlooAIClick,
  klooAITooltipText,
  currency,
  totalAmount,
  loadingLineItems,
  isDrawerRequired = true,
  customFields,
  setFieldOptions,
  version,
  showKlooAIButton = true,
  tab,
  disableActions = false,
  erpFieldsOptional,
  isSageIntactPlatform = false,
  taxSolution,
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(isDrawerRequired);
  const [tempLineItems, setTempLineItems] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [trackingCategories, setTrackingCategories] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [isERPMapped, setIsERPMapped] = useState(false);
  const [form] = Form.useForm();
  const [addLineItemForm] = Form.useForm();

  const trackingCategoriesCheck = (lineItems) => {
    if (trackingCategories && !Object.keys(trackingCategories)?.length) {
      return;
    }
    const newValues = lineItems.map((row) => {
      const trackingKeys = Object.keys(row).filter((key) =>
        key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
      );
      trackingKeys.forEach((key) => {
        const category = key.replace(TRACKING_CATEGORY_KEY_PREFIX, "");
        if (!trackingCategories[category]) {
          delete row[key];
        }
      });
      return row;
    });
    form.setFieldValue("line_items", newValues);
    return newValues;
  };

  useEffect(() => {
    // Function to update line items with tax rates
    const updateLineItemsWithTaxRates = async () => {
      // If there's no data in lineItemsTableData or accounts, clear tempLineItems and exit early
      if (!lineItemsTableData?.length) {
        setTempLineItems([]);
        return;
      }

      // If taxSolution is already available, simply update tempLineItems with the original data
      if (taxSolution) {
        setTempLineItems([...lineItemsTableData]);
        return;
      }

      // Find all classifications from accounts where accountTaxRates is not already present
      const classifications = lineItemsTableData.reduce((acc, item) => {
        const account = accounts.find(
          (acc) => item.chartOfAccounts === acc?.erp_id
        );
        if (account && !item?.accountTaxRates?.length) {
          acc.push(account?.classification);
        }
        return acc;
      }, []);

      // If no classifications were found, update tempLineItems with the original data and exit early
      if (!classifications.length) {
        setTempLineItems([...lineItemsTableData]);
        return;
      }

      try {
        // Fetch tax rates for each classification
        const taxRatePromises = classifications.map((classification) =>
          getTaxRateList(entityId, classification)
        );

        // Wait for all tax rate fetches to complete
        const taxRates = await Promise.all(taxRatePromises);

        // Map through the original lineItemsTableData and update accountTaxRates with the fetched tax rates
        const updatedLineItems = lineItemsTableData.map((item, index) => ({
          ...item,
          accountTaxRates:
            taxRates[index]?.map((rate) => ({
              ...rate,
              label: rate?.description,
              value: rate?.TaxType,
            })) || [],
        }));
        // Update the state with the new line items including the tax rates
        setTempLineItems(updatedLineItems);
      } catch (error) {
        // Log any error that occurs during the fetch and revert to original line items
        console.error("Error fetching tax rates:", error);
        setTempLineItems([...lineItemsTableData]);
      }
    };

    // Call the function to update line items with tax rates whenever dependencies change
    updateLineItemsWithTaxRates();
  }, [lineItemsTableData, accounts, taxSolution, entityId]);

  const handleDrawerCollapse = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const handleOnSave = () => {
    form.validateFields().then((values) => {
      const newValues = isERPMapped
        ? trackingCategoriesCheck(tempLineItems)
        : tempLineItems;
      setLineItemsTableData(newValues);
      const payload = generateLineItemsPayload(newValues, isERPMapped);
      if (onSave) {
        onSave(payload);
      }
    });
  };

  const handleKlooAIClick = () => {
    setTempLineItems([]);
    onKlooAIClick();
    setEditingIndex(-1);
  };

  return (
    <Row className={LINE_ITEMS_CONTAINER_CLASS}>
      <Column
        className="h-100 p-0px kloo-scrollbar overflow-y-scrollable"
        span={isDrawerVisible ? 16 : 24}
      >
        <Box
          rowGap={0}
          columnGap={0}
          className="h-100"
          justifyContent="flexStart"
          direction="column"
          wrap="flex-nowrap"
          alignItem="normal"
        >
          <LineItemsHeader
            onBackClick={onBackClick}
            module={module}
            disabled={disabled}
            lineItemsTableData={lineItemsTableData}
            onKlooAIClick={handleKlooAIClick}
            klooAITooltipText={klooAITooltipText}
            showKlooAIButton={showKlooAIButton}
          />
          <LineItemsTable
            entityId={entityId}
            lineItemsTableData={tempLineItems}
            setLineItemsTableData={setTempLineItems}
            deleteLineItem={deleteLineItem}
            disabled={disabled}
            setLineItemsValuesChanged={setLineItemsValuesChanged}
            currency={currency}
            editingIndex={editingIndex}
            setEditingIndex={setEditingIndex}
            form={form}
            addLineItemForm={addLineItemForm}
            loadingLineItems={loadingLineItems}
            customFields={customFields}
            setFieldOptions={setFieldOptions}
            version={version}
            tab={tab}
            disableActions={disableActions}
            module={module}
            erpFieldsOptional={erpFieldsOptional}
            taxSolution={taxSolution}
            setTrackingCategories={setTrackingCategories}
            setAccounts={setAccounts}
            setIsERPMapped={setIsERPMapped}
          />
          <When condition={loadingLineItems}>
            <Box direction="column">
              <ComponentLoader wrapperClassName={"h-auto"} />
              <Typography variant="body" text={ITEM_TABLE_LOADER_TEXT} />
            </Box>
          </When>
          <LineItemsFooter
            entityId={entityId}
            onSave={handleOnSave}
            onCancel={onBackClick}
            lineItemsTableData={tempLineItems}
            disabled={disabled}
            currency={currency}
            initialTotalAmount={totalAmount}
          />
        </Box>
      </Column>
      <When condition={previewImage}>
        <DocViewer
          previewImage={previewImage}
          isDrawerVisible={isDrawerVisible}
          handleDrawer={handleDrawerCollapse}
        />
      </When>
    </Row>
  );
};

export default LineItems;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import {
  Drawer,
  Row,
  Column,
  When,
  CustomField,
  CustomSelect,
  CustomButton,
  CommonDatePicker,
  Icon,
  ItemList,
  useGlobalModalContext,
  Typography,
  Box,
  CustomAlert,
  ComponentLoader,
  Label,
} from "../../common";
import CommonSelect from "../../common/Selector";
import CommonInput from "../../common/Input";
import CommonInputNumber from "../../common/Input/InputNumber";
import CurrencySelect from "../../InvoiceManagement/components/PaidDataForm/CurrencySelect";
import UploadAttachmentForPO from "../UploadAttachmentForPO";
import {
  addAttachments,
  createPurchaseOrder,
  customFieldsFromGenAI,
  deletePurchaseOrder,
  getAiTriggers,
  getAutomationListAPI,
  getCustomFieldsByPoId,
  getERPCategorisation,
  getPurchaseOrderDetail,
  getTaxCodeList,
  getTaxSolutionOptions,
  isTaxSolutionValid,
  removeAttachment,
  resubmitPurchaseOrder,
  updatePurchaseOrder,
} from "../../../api";
import {
  convertJSObjectToFormData,
  showNotification,
  PO_CREATE_FORM_RULE,
  getFormattedDate,
  getFormattedMoment,
  keyDifference,
  generateCustomValues,
  getCustomFieldForFromValues,
  roundOff,
  removeWhiteSpace,
  amountFormatterWithoutCurrency,
  OWNER,
  MODAL_TYPES,
  automationTriggerMsg,
  addRequiredKeysForCustomFields,
  moneyFormatter,
  disableUpcomingDates,
  disableOlderDates,
  getArrayLength,
  deepClone,
  convertFormDataToJSObject,
  calculateTotalAmount,
  getDefaultCurrency,
  constructClassName,
  getItem,
  LOCAL_STORAGE_KEY,
} from "../../../utils";
import { useCurrencies } from "../../../hooks";
import {
  ACCENTS,
  BUTTON_LABELS,
  BUTTON_VARIANT,
  ERROR_MSG,
  NOTIFICATION_TYPE,
  PURCHASE_ORDER_CREATE_FROM,
  PURCHASE_ORDER_TOAST_MSGS,
  CUSTOM_FIELD_OPTION,
  UPDATE_TRIGGER_MODAL,
  CONFIGURATIONS_KEYS,
  SELECT_TYPE_ATTRIBUTES,
  MODULE_TYPES,
  PURCHASE_ORDER_GENERATED_USING_AI,
  ERROR_MSG_CUSTOM_FIELD,
  LC_SUPPLIER_PLACEHOLDER,
  GEN_AI_TRIGGER_MAPPING,
  VISIBILITY_CONFIG,
  PURCHASE_ORDER_POPULATED_USING_AI,
  AUTOMATION_TRIGGER_MODAL,
  ASSIGNMENT_TYPES,
  STATIC_TRIGGER_FIELDS,
  PURCHASE_ORDER_TABS_KEYS,
  PURCHASE_ORDER_REVIEW_DRAWER,
  TRACKING_CATEGORY_KEY_PREFIX,
  IM_CREATE_INVOICE_FORM,
  INVOICE_LINE_ITEMS,
  itemERPFieldId,
  STATIC_ERP_TRIGGER_FIELDS,
  INVOICE_FORM,
  TAX_SOLUTION_NO_TAX_LABEL,
} from "../../../constants";
import "./create-purchase-order.scss";
import {
  versionForNewCustomField,
  resetWorkflowAutomation,
  getWorkflowByTrigger,
  getModalAssignmentType,
  isFeat11894Enabled,
  isAutomationEnabledForItemsPO,
  getAutomationForItemsIdFromPoDetails,
  getAutomationForItemsId,
  getTriggerHasWorkflowMappingV2,
  cleanERPFieldsFromLineItemObject,
} from "../../../helper";
import useERPFields from "../../../hooks/ERPFields";
import { useSelector } from "react-redux";
import LineItems from "../../common/LineItems";
import {
  checkLineItemsFormItemStatus,
  getNetAmount,
  getTrackingCategoryForPayload,
} from "../../../helper/LineItems";
import { LINE_ITEM_TABLE_COLUMNS_KEY } from "../../../constants/LineItems";
import {
  BC_ORG_ID,
  ERP_INTEGRATIONS_PAGE_CONFIG,
} from "../../../constants/Integration/OpenBanking";

function PurchaseOrderForm({
  open,
  isEditView,
  poDetails: poData,
  onClose,
  reloadTable,
  customFields,
  customFieldsV2,
  initialFieldsV2,
  setFieldOptions,
  setFieldOptionsV2,
  toggleConditionalFields,
  ownerList,
  supplierList,
  mappedAutomation,
  version,
  taxCodeEnabled,
  entityEnabled,
  entityList,
  defaultEntityList,
  activeKey,
  updateSupplierListOnEntityChange,
}) {
  const deletedLineItemIdArray = useRef([]);
  const [form] = Form.useForm();
  const accountDetail =
    useSelector(
      (state) =>
        state &&
        state?.modular &&
        state?.modular?.accountDetail &&
        state?.modular?.accountDetail[0]
    ) || {};
  const isErpIntegration = accountDetail?.is_erp_integration || 0;
  const isItemAdded = Form.useWatch("itemSwitch", form);
  const grossAmount = Form.useWatch("grossAmount", form);
  const completionDate = Form.useWatch("deliveryDate", form);
  const startDate = Form.useWatch("startDate", form);
  const netAmount = Form.useWatch(
    PURCHASE_ORDER_CREATE_FROM.netAmount.name,
    form
  );
  const taxRate = Form.useWatch(PURCHASE_ORDER_CREATE_FROM.taxRate.name, form);
  const taxCodeIdWatch = Form.useWatch(
    PURCHASE_ORDER_CREATE_FROM.taxCode.name,
    form
  );
  const entityValue = Form.useWatch(
    PURCHASE_ORDER_CREATE_FROM.entity.name,
    form
  );
  const taxSolutionValue = Form.useWatch(INVOICE_FORM.TAX_SOLUTION.name, form);

  const [entityId, setEntityId] = useState("");
  const {
    ChartOfAccounts,
    TaxRate,
    TrackingCategories,
    chartsOfAccountList,
    trackingCategoriesList,
    isERPMapped,
    isFetchingData,
    fetchTaxRate,
    connectedErpPlatformName,
  } = useERPFields({
    entityId,
    form,
    getUpdatedSuppliers: () => updateSupplierListOnEntityChange(entityId),
  });
  const automationTriggerField = Form.useWatch(
    mappedAutomation?.field_id || "",
    form
  );
  const [automationSelectValue, setAutomationSelectValue] = useState("");
  const [poTriggerField, setPoTriggerField] = useState("");
  const { showModal, hideModal } = useGlobalModalContext();
  const { currencies, rawCurrencies } = useCurrencies();
  const [newCreatedField, setNewCreatedField] = useState(null);
  const [billOfMaterials, setBillOfMaterials] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [currencyValue, setCurrencyValue] = useState({});
  const [attachments, setAttachments] = useState(
    poData?.attachments ? [...poData.attachments] : []
  );
  const [toBeDeletedAttachments, setToBeDeletedAttachments] = useState([]);
  const [viewLineItems, setViewLineItems] = useState(false);
  const [viewAttachments, setViewAttachments] = useState(false);
  const [SelectCurrency, setSelectCurrency] = useState("");
  const [taxCodeList, setTaxCodeList] = useState([]);
  const [entityFieldDisabled, setEntityFieldDisabled] = useState(false);
  const entityFieldEnabled = entityEnabled && entityList?.length > 0;

  const [isTaxRequired, setIsTaxRequired] = useState(false);
  const taxCodeFieldEnabled = taxCodeEnabled && taxCodeList?.length > 0;
  const isCustomExpenseVersion2 = versionForNewCustomField(version);
  const [dataPopulatedByAI, setDataPopulatedByAI] = useState({});
  const [autoFillTriggerField, setAutoFillTriggerField] = useState(null);
  const [previousAutofillFields, setPreviousAutofillFields] = useState();
  const [uploadContainerDisabled, setUploadContainerDisabled] = useState(false);
  const [showLineItems, setShowLineItems] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [lineItemsValuesChanged, setLineItemsValuesChanged] = useState(false);
  const [taxSolutionOptions, setTaxSolutionOptions] = useState([]);
  const [poDetails, setPODetails] = useState(null);

  const selectedTaxSolution = taxSolutionOptions.find(
    (item) => item.value === taxSolutionValue
  );

  const [initialState] = useState({
    supplier: null,
    description: "",
    owner: null,
    grossAmount: "",
    net_amount: "",
    tax_code_id: undefined,
    tax_rate: "",
    deliveryDate: "",
    startDate: "",
    currency: "",
    entity_id: null,
    chartOfAccounts: null,
    taxRate: null,
    taxSolution: null,
  });

  useEffect(() => {
    if (poData?.id) {
      getPurchaseOrderDetail(poData.id).then(setPODetails);
    }
  }, []);

  useEffect(() => {
    if (poDetails && isEditView) {
      if (isCustomExpenseVersion2) {
        let lineItemIds = [];
        if (poDetails.bill_of_materials.length) {
          poDetails.bill_of_materials.forEach((item) => {
            if (item.custom_fields?.length) {
              item.custom_fields.forEach((field) => {
                field.user_input = field.value;
              });
            }
            lineItemIds.push(item.id);
          });
        }
        const lineItemIdsString = lineItemIds.join(",");
        async function getData() {
          try {
            const res = await getCustomFieldsByPoId(
              poDetails.id,
              lineItemIdsString
            );
            poDetails.custom_expense_details = addRequiredKeysForCustomFields(
              res.default || []
            );
            poDetails.line_items = addRequiredKeysForCustomFields(
              res.line_items || []
            );
            poDetails.line_item_data = res.line_item_data;
            setFormData();
          } catch (e) {
            if (e?.response?.data?.message.includes(ERROR_MSG_CUSTOM_FIELD)) {
              poDetails.custom_expense_details = addRequiredKeysForCustomFields(
                []
              );
              poDetails.line_items = addRequiredKeysForCustomFields([]);
              poDetails.line_item_data = [];
              setFormData();
            }
          }
        }
        getData();
      } else {
        setFormData();
      }
    }
    if (poDetails?.ai_generated_values) {
      setDataPopulatedByAI(poDetails?.ai_generated_values);
    }
    setPOTriggerValueField();
    getAutofillTriggers();
  }, [poDetails]);

  useEffect(() => {
    setSelectCurrency(getDefaultCurrency({ accountDetail }));
  }, [accountDetail]);

  useEffect(() => {
    if (poDetails) {
      const supplier = supplierList.find(
        (list) => list.value === poDetails.po_supplier_id
      );
      form.setFieldValue("supplier", supplier?.value || null);
    }
  }, [supplierList, poDetails]);

  useEffect(() => {
    if (poDetails && customFieldsV2?.length) {
      setFormData();
    }
  }, [poDetails, customFieldsV2]);

  useEffect(() => {
    if (automationTriggerField) {
      setNewCreatedField((field) => {
        return {
          ...field,
          isSelected: automationTriggerField !== field?.id ? false : true,
        };
      });
    }
  }, [automationTriggerField]);

  useEffect(() => {
    if (rawCurrencies && rawCurrencies?.result?.length && poDetails) {
      const currencyId =
        poDetails && isEditView ? poDetails.currencyData.id : SelectCurrency;
      const defaultSelect = rawCurrencies.result?.filter(
        (data) => data.id === currencyId
      );
      if (defaultSelect && defaultSelect?.length) {
        form.setFieldsValue({ currency: defaultSelect[0].id });
        setCurrencyValue(defaultSelect);
      }
    }
  }, [rawCurrencies, poDetails]);

  useEffect(() => {
    form.setFieldValue("grossAmount", totalAmount);
    form.setFieldValue("net_amount", totalAmount);
  }, [totalAmount]);

  useEffect(() => {
    if ((viewLineItems || isItemAdded) && billOfMaterials?.length) {
      form.setFieldValue(
        "grossAmount",
        roundOff(
          billOfMaterials.reduce(
            (accumulator, item) => accumulator + item.amount,
            0
          )
        )
      );
      form.setFieldValue(
        "net_amount",
        roundOff(
          billOfMaterials.reduce(
            (accumulator, item) => accumulator + item.amount,
            0
          )
        )
      );
      setTotalAmount(
        roundOff(
          billOfMaterials.reduce(
            (accumulator, item) => accumulator + item.amount,
            0
          )
        )
      );
    }
  }, [viewLineItems, isItemAdded]);

  const handleBillOfMaterial = React.useCallback((items) => {
    setTotalAmount(
      roundOff(
        items.reduce((accumulator, item) => accumulator + item.amount, 0)
      )
    );
    setBillOfMaterials(items);
  }, []);

  const lineItemPayload = () => {
    const lineItemsData = lineItems;
    if (lineItemsData?.length) {
      const billOfMaterialsList = lineItemsData.map((item) => {
        const extraData = {};
        if (item?.deleted_line_items_custom_fields?.length) {
          extraData.deleted_line_items_custom_fields =
            item.deleted_line_items_custom_fields;
        }
        const erpObject = isERPMapped
          ? {
              erp_account:
                item[LINE_ITEM_TABLE_COLUMNS_KEY.ACCOUNT] || undefined,
              erp_item_id:
                item[LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST] || undefined,
              erp_tax_rate:
                item[LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE] || undefined,
              erp_tracking_categories: getTrackingCategoryForPayload(item),
              is_ai_generated:
                item[LINE_ITEM_TABLE_COLUMNS_KEY.IS_AI_GENERATED],
            }
          : {};

        return {
          item: item?.description,
          unit_price: item?.unit_cost,
          quantity: item?.quantity,
          id: item.id,
          total_amount: getNetAmount(item, true),
          ...erpObject,
          custom_expense_fields:
            item.innerCustomFields || item.customExpenseFieldLineItem,
          ...extraData,
        };
      });
      return JSON.stringify(billOfMaterialsList);
    }
  };

  const getPRPayload = async (values) => {
    const {
      supplier,
      description,
      owner,
      grossAmount,
      deliveryDate,
      startDate,
    } = values;
    let payload = {};

    let selectedSupplier = supplierList.find(
      (item) => item.value === supplier
    )?.label;

    if (!!owner) {
      payload.owner = owner;
    }
    if (!!selectedSupplier) {
      payload.po_supplier_name = selectedSupplier;
    }
    if (!!description) {
      payload.description = description;
    }
    if (!!values?.net_amount) {
      payload.gross_amount = values?.net_amount;
    }
    if (!!values?.net_amount) {
      payload.net_amount = values?.net_amount;
    }
    if (!!values?.tax_code_id) {
      payload.tax_code_id = values?.tax_code_id;
    }
    if (!!values?.taxSolution) {
      payload.tax_solution_id = values?.taxSolution;
    }
    if (deliveryDate) {
      payload.delivery_date = getFormattedDate(deliveryDate, "YYYY-MM-DD");
    }
    if (!!currencyValue[0]?.id) {
      payload.currency = currencyValue[0]?.id;
    }
    if (!!supplier) {
      payload.po_supplier_id = supplier;
    }
    if (values?.entity_id || defaultEntityList?.[0]?.id) {
      payload.entity_id = values?.entity_id || defaultEntityList?.[0]?.id;
    }
    if (!!startDate) {
      payload.start_date = getFormattedDate(startDate, "YYYY-MM-DD");
    }
    if (isErpIntegration && values?.chartOfAccounts) {
      payload.erp_account = values.chartOfAccounts;
    }
    if (isErpIntegration && values?.taxRate) {
      payload.erp_tax_rate = values.taxRate;
    }
    const trackingCategorieskeys = Object.keys(values).filter((key) =>
      key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
    );
    if (isErpIntegration) {
      payload.erp_tracking_categories = trackingCategorieskeys?.length
        ? JSON.stringify(
            trackingCategorieskeys
              .filter((key) => values[key] !== null)
              .map((key) => ({
                type: key.split("_")[1],
                value: values[key],
              }))
          )
        : [];
    }
    const lineItemsData = lineItems;
    if (lineItemsData?.length) {
      if (deletedLineItemIdArray?.current?.length) {
        payload.deleted_line_items = JSON.stringify(
          deletedLineItemIdArray.current
        );
      }
      payload.bill_of_materials = lineItemPayload();
    }
    const customKeys = keyDifference({
      comparingObj: initialState,
      obj: values,
    }).filter(
      (key) =>
        key !== "itemSwitch" &&
        key !== "attachmentsSwitch" &&
        !key.includes(TRACKING_CATEGORY_KEY_PREFIX)
    );

    let filteredConditionalData = resultObject?.purchaseOrders?.filter(
      (conditionalData) => {
        return (
          conditionalData?.triggeredBy && conditionalData?.triggeredBy?.size > 0
        );
      }
    );

    if (customKeys.length) {
      payload.custom_expense_fields = JSON.stringify(
        generateCustomValues({
          customFieldsData:
            isCustomExpenseVersion2 && customFieldsV2?.default
              ? customFieldsV2.default
              : [],
          customKeys,
          values,
          forEdit: isEditView,
          filteredConditionalData,
        })
      );
    }
    payload.is_custom_expense_created_dynamically = newCreatedField?.isSelected
      ? 1
      : 0;
    if (Object.keys(dataPopulatedByAI).length > 0) {
      payload.ai_values = JSON.stringify(dataPopulatedByAI);
    }
    return payload;
  };

  useEffect(() => {
    populateErpFieldsValues();
  }, [chartsOfAccountList, trackingCategoriesList]);

  async function populateErpFieldsValues() {
    if (isErpIntegration && poDetails?.erp_tracking_categories?.length) {
      poDetails?.erp_tracking_categories?.forEach((category) => {
        const selectedTrackingCategoryFound = trackingCategoriesList?.[
          category?.type
        ]?.find((data) => data.value === category?.value);
        if (selectedTrackingCategoryFound) {
          form.setFieldsValue({
            [`${TRACKING_CATEGORY_KEY_PREFIX}${category.type}`]: category.value,
          });
        }
      });
    }
    if (isErpIntegration && poDetails?.erp_account) {
      const selectedAccountFound = chartsOfAccountList?.find(
        (data) => data.erp_id === poDetails?.erp_account
      );
      if (selectedAccountFound) {
        form.setFieldsValue({
          chartOfAccounts: poDetails?.erp_account,
        });
        const response = await fetchTaxRate(
          form.getFieldValue("entity_id") || poDetails?.entity_id,
          selectedAccountFound
        );
        form.setFieldsValue({
          taxRate:
            response?.find((data) => data?.TaxType === poDetails?.erp_tax_rate)
              ?.TaxType || null,
        });
      }
    }
  }

  const isRequiredERPTriggerField =
    STATIC_ERP_TRIGGER_FIELDS.includes(poTriggerField);

  const addAutomationPayload = (payload) => {
    const payloadModified = { ...payload };
    if (isRequiredERPTriggerField) {
      const erp_line_item_automation_current = getAutomationForItemsId(payload);
      const erp_line_item_automation_previous =
        getAutomationForItemsIdFromPoDetails(poDetails);

      payloadModified.erp_line_item_automation = [];

      //get the key for current data
      payloadModified.erp_line_item_automation.push({
        current: [{ erp_item_id: erp_line_item_automation_current || null }],
      });
      //get the key for previous data
      payloadModified.erp_line_item_automation.push({
        previous: [{ erp_item_id: erp_line_item_automation_previous || null }],
      });
      payloadModified.erp_line_item_automation = JSON.stringify(
        payloadModified.erp_line_item_automation
      );
    }

    return payloadModified;
  };

  const validateAndHandleForm = () => {
    form.validateFields().then(async (data) => {
      const taxSolutionValid = await validateTaxSolution();
      if (!taxSolutionValid) {
        return;
      }
      handleForm(data);
    });
  };

  const draftHandleForm = () => {
    const values = form.getFieldsValue();
    handleForm(values, true);
  };

  const handleForm = async (params, isDraft = false) => {
    try {
      setUploadContainerDisabled(true);
      let payloadJson = await getPRPayload(params);
      let payload = { ...payloadJson };

      if (isAutomationEnabledForItemsPO()) {
        payload = addAutomationPayload(payload);
      }

      payload = convertJSObjectToFormData(payload);
      if (!isEditView && viewAttachments && attachments?.length) {
        attachments.forEach((file, index) => {
          payload.append(`files[${index}]`, file);
        });
      }
      if (isEditView) {
        if (toBeDeletedAttachments?.length) {
          await removeAttachment(
            toBeDeletedAttachments.map((attachment) => attachment?.id)
          );
        }
        if (!viewAttachments && attachments?.length) {
          await removeAttachment(
            attachments
              ?.filter((f) => f?.isAttachedFile)
              .map((attachment) => attachment?.id)
          );
        }
        if (viewAttachments && attachments?.length) {
          let payload = {};
          let isNewAttachments = attachments?.filter((f) => !f?.isAttachedFile);
          if (isNewAttachments?.length) {
            payload = convertJSObjectToFormData(payload);
            isNewAttachments.forEach((file, index) => {
              payload.append(`files[${index}]`, file);
            });
            await addAttachments(payload, poDetails.id);
          }
        }
      }
      if (isEditView) {
        if (resetWorkflowAutomation()) {
          onUpdateClickV2(payload, isDraft);
          return;
        }
        if (poTriggerField && poTriggerField === automationSelectValue) {
          onUpdateClick(poDetails.id, payload, payloadJson, isDraft);
          return;
        }
        switch (activeKey) {
          case PURCHASE_ORDER_TABS_KEYS.REJECTED:
            await resubmitPurchaseOrder(poDetails.id, payload, isDraft);
            break;
          default:
            await updatePurchaseOrder(poDetails.id, payload, isDraft);
        }
      } else {
        await createPurchaseOrder(payload, isDraft);
      }
      onSuccess({ isDraft });
    } catch (error) {
      onError(error);
      setUploadContainerDisabled(false);
    }
  };

  const onSuccess = (extraData = {}) => {
    const { isDelete, isDraft } = extraData;
    let notificationText = "";
    if (isEditView) {
      switch (activeKey) {
        case PURCHASE_ORDER_TABS_KEYS.REJECTED:
          if (isDelete) {
            notificationText = PURCHASE_ORDER_TOAST_MSGS.deleted();
          } else {
            notificationText = PURCHASE_ORDER_TOAST_MSGS.resubmitted;
          }
          break;
        default:
          notificationText = PURCHASE_ORDER_TOAST_MSGS.updated;
      }
    } else {
      notificationText = PURCHASE_ORDER_TOAST_MSGS.created;
    }
    notificationText = isDraft
      ? PURCHASE_ORDER_TOAST_MSGS.drafted
      : notificationText;
    showNotification(NOTIFICATION_TYPE.success, notificationText);
    reloadTable();
    onClose();
  };

  const onError = (e) => {
    if (e?.errorFields) return;
    showNotification(
      NOTIFICATION_TYPE.error,
      e?.response?.data?.message || ERROR_MSG.common
    );
  };

  const onSelectHandle = (value) => {
    const defaultSelect = rawCurrencies?.result?.filter(
      (data) => data.id === value
    );
    if (grossAmount) form.validateFields(["grossAmount"]);
    if (netAmount) form.validateFields(["net_amount"]);
    setCurrencyValue(defaultSelect);
    setSelectCurrency(value);
  };

  let resultObject = {};

  if (isCustomExpenseVersion2) {
    if (customFieldsV2) {
      resultObject.purchaseOrders = addRequiredKeysForCustomFields([
        ...(customFieldsV2.default || []),
      ]);
      resultObject.purchaseOrderLineItems = addRequiredKeysForCustomFields([
        ...(customFieldsV2.line_items || []),
      ]);
    }
  } else {
    resultObject = customFields?.reduce(
      (result, field) => {
        const optionName = field.fieldOptions?.[0]?.option_name;
        if (
          removeWhiteSpace(optionName) === CUSTOM_FIELD_OPTION.PO_LINE_ITEMS
        ) {
          result.purchaseOrderLineItems.push(field);
        } else if (
          removeWhiteSpace(optionName) === CUSTOM_FIELD_OPTION.PO_ONLY
        ) {
          result.purchaseOrders.push(field);
        } else {
          result.purchaseOrders.push(field);
          result.purchaseOrderLineItems.push(field);
        }
        return result;
      },
      {
        purchaseOrderLineItems: [],
        purchaseOrders: [],
      }
    );
  }

  const onUpload = (file) => {
    if (attachments?.length < 5) {
      setAttachments((prevFiles) =>
        prevFiles?.length < 5 ? [...prevFiles, file?.file] : [...prevFiles]
      );
    }
  };

  const removeSelectedAttachment = (record, index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);
    if (record?.isAttachedFile) {
      toBeDeletedAttachments.push(record);
      setToBeDeletedAttachments(toBeDeletedAttachments);
    }
  };

  const setPOTriggerValueField = async () => {
    let mappedEntity = [];
    try {
      mappedEntity = await getERPCategorisation();
    } catch (error) {
      console.error("Failed to fetch ERP categorisation:", error);
      mappedEntity = [];
    }

    const entityId =
      mappedEntity.length > 1 ? null : mappedEntity[0]?.entity_id;

    const res = await getAutomationListAPI("po", entityId);
    let triggerField = "";
    res.forEach((type) => {
      type.options.forEach((option) => {
        if (option.id === mappedAutomation?.field_id) {
          triggerField = option.label;
        }
      });
    });
    setPoTriggerField(triggerField);
  };

  const getAutofillTriggers = async () => {
    try {
      const response = await getAiTriggers({
        module_type: MODULE_TYPES.PO,
      });
      const { data = [] } = response;
      setAutoFillTriggerField(data);
    } catch (error) {
      console.log(error);
    }
  };
  const onHandleTrigger = (key, value, fieldName) => {
    if (key === poTriggerField) {
      setAutomationSelectValue(key);
    }

    const currentFormValues = form.getFieldsValue();

    const fieldValue = currentFormValues[fieldName];

    const finalFieldName =
      GEN_AI_TRIGGER_MAPPING.find(({ mappingKey }) => mappingKey === key)
        ?.value || value;
    if (autoFillTriggerField.includes(finalFieldName)) {
      // not clearing the previous custom values when new trigger field is called MODULR-9306
      // if (previousAutofillFields?.length) {
      //   previousAutofillFields.forEach((pField) => {
      //     form.setFieldValue(pField.custom_field_id, null);
      //   });
      // }
      autofillCustomFieldsByAi({
        fieldValue,
        triggerKey: finalFieldName,
      });
    }
  };

  const autofillCustomFieldsByAi = async (supplierId) => {
    const reqPayload = {
      module_type: MODULE_TYPES.PO,
    };
    reqPayload.trigger_column = supplierId.triggerKey;
    reqPayload.trigger_field_id = supplierId.fieldValue;
    reqPayload.payload = await getPRPayload(form.getFieldsValue(true));
    try {
      let tempDataPopulatedByAI = {
        ...dataPopulatedByAI,
      };
      const data = await customFieldsFromGenAI(reqPayload);
      const customExpenseValues = data[0]?.custom_fields;
      if (customExpenseValues?.length > 0) {
        const poCustomFields = deepClone(customFieldsV2?.default);
        const matchedCustomFields = poCustomFields?.filter((o) =>
          customExpenseValues?.some(
            ({ custom_field_id }) => o.custom_field_id === custom_field_id
          )
        );
        const currentValues = form.getFieldsValue();
        matchedCustomFields &&
          matchedCustomFields?.forEach((field, i) => {
            customExpenseValues?.forEach((item, i) => {
              const optionValues = field.options.map((option) => option.value);
              if (item?.custom_field_id === field?.custom_field_id) {
                field.user_input = optionValues.includes(item?.value)
                  ? item.value
                  : null;
                tempDataPopulatedByAI[field.label] = optionValues.includes(
                  item?.value
                )
                  ? field.label
                  : null;
                form.setFieldValue(
                  item?.custom_field_id,
                  optionValues.includes(item?.value) ? item?.value : null
                );
              }
            });
          });

        poCustomFields?.map((poField) => {
          const isPoFieldGenAISuggestion = autoFillTriggerField.includes(
            poField.id
          );
          if (isPoFieldGenAISuggestion) {
            form.setFieldValue(
              poField.custom_field_id,
              poField.user_input
                ? poField.user_input
                : currentValues[poField.custom_field_id]
            );
          }
        });
        const taxCodeFieldName = GEN_AI_TRIGGER_MAPPING[2].value;
        const isTaxCodeFieldFilledByAI = customExpenseValues?.find(
          ({ custom_field_id }) => custom_field_id === taxCodeFieldName
        );
        if (isTaxCodeFieldFilledByAI) {
          tempDataPopulatedByAI[taxCodeFieldName] =
            isTaxCodeFieldFilledByAI?.value;
          form.setFieldValue(taxCodeFieldName, isTaxCodeFieldFilledByAI?.value);
        }
        autoPopulateChartOfAccounts(customExpenseValues, tempDataPopulatedByAI);
        autoPopulateTrackingCategories(
          customExpenseValues,
          tempDataPopulatedByAI
        );
      }
      setPreviousAutofillFields(customExpenseValues);
      setDataPopulatedByAI(tempDataPopulatedByAI);
    } catch (e) {}
  };

  const handleErpFieldsAutoPopulate = ({ value, options }) => {
    const fieldData = options?.find((data) => data.erp_id === value);
    return fieldData || false;
  };

  function autoPopulateChartOfAccounts(customExpenseValues, tempAiData) {
    const defaultFormValue = {};
    const isAccountFieldAiValueExist = customExpenseValues?.find(
      ({ custom_field_id }) =>
        custom_field_id === IM_CREATE_INVOICE_FORM.chartOfAccounts.name
    );
    if (isAccountFieldAiValueExist) {
      const selectedAccount = handleErpFieldsAutoPopulate({
        value: isAccountFieldAiValueExist?.value,
        options: chartsOfAccountList,
      });
      if (selectedAccount) {
        defaultFormValue[IM_CREATE_INVOICE_FORM.chartOfAccounts.name] =
          selectedAccount?.erp_id || null;
        defaultFormValue[IM_CREATE_INVOICE_FORM.chartOfAccounts.name] &&
          (tempAiData[IM_CREATE_INVOICE_FORM.chartOfAccounts.name] =
            IM_CREATE_INVOICE_FORM.chartOfAccounts.name);
        autoPopulateTaxRate(
          customExpenseValues,
          defaultFormValue,
          tempAiData,
          selectedAccount
        );
      }
    }
  }

  async function autoPopulateTaxRate(
    customExpenseValues,
    defaultFormValue,
    tempAiData,
    selectedAccount
  ) {
    const taxRateResponse = await fetchTaxRate(
      form.getFieldValue("entity_id"),
      selectedAccount
    );
    const isTaxRateAiValueExist = customExpenseValues?.find(
      ({ custom_field_id }) =>
        custom_field_id === IM_CREATE_INVOICE_FORM.taxRate.name
    );
    if (isTaxRateAiValueExist) {
      defaultFormValue[IM_CREATE_INVOICE_FORM.taxRate.name] =
        handleErpFieldsAutoPopulate({
          value: isTaxRateAiValueExist?.value,
          options: taxRateResponse,
        })?.TaxType || null;
      defaultFormValue[IM_CREATE_INVOICE_FORM.taxRate.name] &&
        (tempAiData[IM_CREATE_INVOICE_FORM.taxRate.name] =
          IM_CREATE_INVOICE_FORM.taxRate.name);
    }
    form.setFieldsValue({
      ...defaultFormValue,
    });
    setDataPopulatedByAI({ ...tempAiData });
  }
  function autoPopulateTrackingCategories(customExpenseValues, tempAiData) {
    const defaultFormValue = {};
    const trackingCategorieskeys = Object.keys(form.getFieldsValue()).filter(
      (key) => key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
    );
    const isTrackingCategoriesAiValuesExists = customExpenseValues?.filter(
      (el) => trackingCategorieskeys.find((key) => key === el.custom_field_id)
    );
    if (isTrackingCategoriesAiValuesExists?.length > 0) {
      isTrackingCategoriesAiValuesExists?.forEach((item, i) => {
        defaultFormValue[item.custom_field_id] =
          trackingCategoriesList[item?.custom_field_id.split("_")[1]]?.find(
            (data) => data.value === item?.value
          )?.value || null;
        defaultFormValue[item.custom_field_id] &&
          (tempAiData[item.custom_field_id] = item.custom_field_id);
      });
    }
    form.setFieldsValue({
      ...defaultFormValue,
    });
  }

  const onUpdateClick = (id, payload, payloadJson, isDraft) => {
    let title = "",
      subTitle = "",
      triggerFieldCurrentValue = "",
      triggerFieldInitialValue = "",
      assignmentType = "",
      hasPreviousWorkflow = false,
      hasNewWorkflow = false;

    const isRequiredERPTriggerField =
      STATIC_ERP_TRIGGER_FIELDS.includes(poTriggerField);

    const isRequiredTriggerField =
      STATIC_TRIGGER_FIELDS.includes(poTriggerField);
    // Trigger in required fields like supplier, owner
    if (isRequiredTriggerField) {
      switch (automationSelectValue.toLowerCase()) {
        case "supplier":
          triggerFieldInitialValue = poDetails.po_supplier_id;
          break;
        case "owner":
          triggerFieldInitialValue = poDetails.owner_id;
          break;
        default:
          break;
      }
      triggerFieldCurrentValue = form.getFieldValue(
        automationSelectValue.toLowerCase()
      );
      hasPreviousWorkflow = getTriggerHasWorkflowMapping(poDetails);
      hasNewWorkflow = getTriggerHasWorkflowMapping({
        ...payloadJson,
        owner_id: payloadJson.owner,
      });
    } else if (isRequiredERPTriggerField && isAutomationEnabledForItemsPO()) {
      triggerFieldCurrentValue = getAutomationForItemsId(payloadJson);
      triggerFieldInitialValue =
        getAutomationForItemsIdFromPoDetails(poDetails);
      // Trigger in required fields like supplier, owner
      hasPreviousWorkflow = getTriggerHasWorkflowMappingV2({
        data: triggerFieldInitialValue,
        type: "erp_fields",
        mappedAutomation,
      });
      hasNewWorkflow = getTriggerHasWorkflowMappingV2({
        data: triggerFieldCurrentValue,
        type: "erp_fields",
        mappedAutomation,
      });
    } else {
      // Trigger in Custom fields
      const triggerField = customFieldsV2.default.find(
        (fields) => fields.label === automationSelectValue
      );
      triggerFieldInitialValue = triggerField.user_input;
      triggerFieldCurrentValue = form.getFieldValue(
        triggerField.custom_field_id
      );

      hasPreviousWorkflow = getTriggerHasWorkflowMapping(
        triggerFieldInitialValue,
        "custom_fields"
      );
      hasNewWorkflow = getTriggerHasWorkflowMapping(
        triggerFieldCurrentValue,
        "custom_fields"
      );
    }
    if (activeKey === PURCHASE_ORDER_TABS_KEYS.IN_REVIEW) {
      if (hasNewWorkflow) {
        if (triggerFieldInitialValue || isRequiredTriggerField) {
          assignmentType = ASSIGNMENT_TYPES.REASSIGN;
        } else if (!isRequiredTriggerField && !triggerFieldInitialValue) {
          assignmentType = ASSIGNMENT_TYPES.ASSIGN;
        }
      } else {
        if (hasPreviousWorkflow) {
          assignmentType = ASSIGNMENT_TYPES.UNASSIGN;
        }
      }
    } else {
      if (hasNewWorkflow) {
        assignmentType = ASSIGNMENT_TYPES.ASSIGN;
      }
    }
    title = AUTOMATION_TRIGGER_MODAL.title;
    subTitle = AUTOMATION_TRIGGER_MODAL.get_subtitle({
      triggerField: automationSelectValue,
      type: "purchase request",
      assignmentType,
    });

    const isInReviewTab = activeKey === PURCHASE_ORDER_TABS_KEYS.IN_REVIEW;
    if (
      (!hasPreviousWorkflow && !hasNewWorkflow && isInReviewTab) ||
      (!hasNewWorkflow && !isInReviewTab)
    ) {
      confirmUpdate(payload, isDraft);
    } else {
      updateConfirmModal(title, subTitle, payload, isDraft);
    }
  };

  const onUpdateClickV2 = (payload, isDraft) => {
    let title = "",
      subTitle = "";
    const payloadJson = convertFormDataToJSObject(payload);

    const isRequiredTriggerField =
      STATIC_TRIGGER_FIELDS.includes(poTriggerField);

    const isRequiredERPTriggerField =
      STATIC_ERP_TRIGGER_FIELDS.includes(poTriggerField);

    let { previousWorkflowId, newWorkflowId } = getWorkflowByTrigger({
      isRequiredTriggerField,
      isRequiredERPTriggerField,
      payloadJson,
      poDetails,
      poTriggerField,
      customFieldsV2,
      form,
      mappedAutomation,
    });
    let assignmentType = getModalAssignmentType({
      previousWorkflowId,
      newWorkflowId,
      activeKey,
    });
    title = AUTOMATION_TRIGGER_MODAL.title;
    subTitle = AUTOMATION_TRIGGER_MODAL.get_subtitle({
      triggerField: isRequiredERPTriggerField
        ? poTriggerField
        : automationSelectValue,
      type: "purchase request",
      assignmentType,
    });

    if (assignmentType) {
      updateConfirmModal(title, subTitle, payload, isDraft);
    } else {
      confirmUpdate(payload, isDraft);
    }
  };

  const rejectOrCancel = () => {
    hideModal();
    setFormData();
    setAutomationSelectValue("");
  };

  const setFormData = async () => {
    form.setFieldsValue({
      description: poDetails.description,
      owner: ownerList.find((o) => o.value === poDetails.owner_id)
        ? poDetails.owner_id
        : null,
      deliveryDate: !!poDetails?.delivery_date
        ? getFormattedMoment(poDetails.delivery_date, "DD/MM/YYYY")
        : "",
      startDate: !!poDetails?.start_date
        ? getFormattedMoment(poDetails.start_date, "DD/MM/YYYY")
        : "",
      itemSwitch: !!poDetails?.bill_of_materials?.length,
      currency: poDetails.currencyData.id,
      net_amount: poDetails?.net_amount
        ? poDetails?.net_amount
        : poDetails?.gross_amount,
      tax_code_id: poDetails?.tax_code_id ?? undefined,
      taxSolution: poDetails?.tax_solution_id,
    });
    if (isCustomExpenseVersion2) {
      let customExpWithConditionlFields = poDetails.custom_expense_details;
      let newCustomFieldsData = poDetails?.custom_fields?.filter((data) => {
        return (
          data.custom_field_visibility === VISIBILITY_CONFIG?.default &&
          data?.conditional_fields &&
          data?.conditional_fields.length
        );
      });
      if (newCustomFieldsData?.length) {
        newCustomFieldsData.forEach((customFieldsData) => {
          let element = poDetails.custom_expense_details?.find((data) => {
            return data.id === customFieldsData.custom_field_id;
          });

          let conditionalOptionSelect = element?.options?.find(
            (item) => element.user_input === item.value
          );

          if (conditionalOptionSelect?.conditional_fields?.length) {
            let condiitionalOptionArrayFilter =
              conditionalOptionSelect?.conditional_fields.reduce(
                (acc, item) => {
                  let findConditionalExist =
                    customFieldsData.conditional_fields.findIndex((data) => {
                      return data.custom_field_id === item.id;
                    });
                  if (findConditionalExist >= 0) {
                    acc.push({
                      ...item,
                    });
                  }
                  return acc;
                },
                []
              );
            conditionalOptionSelect.conditional_fields = [
              ...condiitionalOptionArrayFilter,
            ];
          }

          if (
            conditionalOptionSelect &&
            SELECT_TYPE_ATTRIBUTES.includes(element.type)
          ) {
            customExpWithConditionlFields = toggleConditionalFields(
              element,
              conditionalOptionSelect,
              customExpWithConditionlFields
            );
          }
        });
      }

      poDetails.custom_expense_details?.forEach((element) => {
        const findNewIndex = newCustomFieldsData.findIndex((newData) => {
          return newData.custom_field_id === element.id;
        });
        if (element.user_input && findNewIndex < 0) {
          const selectedField = element.options?.find(
            (item) => element.user_input === item.value
          );
          if (SELECT_TYPE_ATTRIBUTES.includes(element.type)) {
            customExpWithConditionlFields = toggleConditionalFields(
              element,
              selectedField,
              customExpWithConditionlFields
            );
          }
        }
      });
      form.setFieldsValue(
        getCustomFieldForFromValues({
          customDetails: customExpWithConditionlFields,
          version,
        })
      );
    } else {
      if (poDetails?.custom_expense_details?.length) {
        form.setFieldsValue(
          getCustomFieldForFromValues({
            customDetails: poDetails?.custom_expense_details,
          })
        );
      }
    }

    if (poDetails.bill_of_materials?.length) {
      const billItems = [];
      poDetails.bill_of_materials.forEach((item) => {
        const billItem = {
          description: item?.item,
          name: item?.item,
          quantity: item.quantity,
          amount: item.total_amount,
          id: item.id,
          storedCustomKeys: [],
          innerCustomFields: [],
          unit_cost: item.unit_price,
          cost: item.unit_price,
          taxRate: item?.erp_tax_rate,
          chartOfAccounts: item?.erp_account,
          erp_item_id: item?.erp_item_id,
          net_amount: item?.net_amount,
          total_amount: item?.total_amount,
          is_ai_generated: item?.is_ai_generated,
        };

        const trackingCategoriesArr = item?.erp_tracking_categories;
        if (trackingCategoriesArr?.length) {
          const erp_tracking_categories = trackingCategoriesArr;
          erp_tracking_categories.forEach((category) => {
            billItem[TRACKING_CATEGORY_KEY_PREFIX + category["type"]] = {
              label: category.label,
              value: category.value,
            };
          });
        }
        if (!poDetails.line_item_data.length) {
          billItems.push(billItem);
          return;
        }

        const getItemData = poDetails.line_item_data.find(
          (itemData) => itemData.id === item.id
        );

        if (getItemData?.inputs) {
          getItemData.inputs.forEach((field) => {
            billItem.storedCustomKeys.push(
              field.custom_fields_has_form_option_id
            );
            const getCustomField = poDetails.line_items.find(
              (item) =>
                item.custom_field_id === field.custom_fields_has_form_option_id
            );
            getCustomField.value = field.user_input;
            if (getCustomField.attribute_type !== "textbox") {
              getCustomField.valueAsText = getCustomField.values.find(
                (option) => option.value === field.user_input
              )?.label;
            }
            billItem[getCustomField.custom_field_id] = getCustomField.value;
            billItem.innerCustomFields.push({
              custom_field_id: getCustomField.custom_field_id,
              value: getCustomField.value,
              valueAsText: getCustomField.valueAsText,
              id: getCustomField.id,
              user_input: getCustomField.user_input,
              label: getCustomField.label,
            });
          });
        }
        billItems.push(billItem);
      });

      setBillOfMaterials(billItems);
      setLineItems(billItems);
      setViewLineItems(true);
    }

    setTotalAmount(
      poDetails.net_amount ? poDetails.net_amount : poDetails.gross_amount
    );
  };

  const onAddDynamic = async (fieldId, result, newValue) => {
    if (isCustomExpenseVersion2) {
      if (mappedAutomation?.field_id === fieldId) {
        setNewCreatedField({ id: fieldId, isSelected: false });
      }
      setFieldOptionsV2(fieldId, result);
    } else {
      if (
        mappedAutomation?.field_id === fieldId &&
        customFields.find(
          (field) => field.type === "dynamic" && field.inputId === fieldId
        )
      ) {
        setNewCreatedField(
          result.find((fields) => {
            if (fields.name === newValue) {
              return { ...fields, isSelected: false };
            }
          })
        );
      }
      setFieldOptions(fieldId, result);
    }
  };

  const removeLineItems = () => {
    if (billOfMaterials?.length) {
      setBillOfMaterials([]);
      setTotalAmount(null);
      deletedLineItemIdArray.current = [];
    }
  };

  const deleteLineItem = (id) => {
    if (deletedLineItemIdArray?.current) {
      deletedLineItemIdArray.current.push(id);
    }
  };

  function updateConfirmModal(title, subTitle, payload, isDraft) {
    showModal(MODAL_TYPES.USER_DELETE_MODAL, {
      title,
      subTitle,
      mandatoryCheckboxConfirmation: true,
      onConfirm: async () => {
        await confirmUpdate(payload, isDraft);
      },
      deleteOnConfirm: () => new Promise((resolve) => resolve()),
      onCancel: () => rejectOrCancel(),
    });
  }

  function getTriggerHasWorkflowMapping(data, type) {
    let hasNewWorkflow = false;
    const triggerField = mappedAutomation?.mapped_fields?.find((field) => {
      if (type === "custom_fields") {
        return field.field_value_id === data;
      } else {
        return (
          field.field_value_id === data.po_supplier_id ||
          field.field_value_id === data.owner_id
        );
      }
    });
    if (triggerField?.workflow_id) {
      hasNewWorkflow = true;
    }
    if (!triggerField) {
      const triggerFoundInRemaining = mappedAutomation?.mapped_fields.find(
        (field) =>
          field.field_value_id === "remaining_values" && field.workflow_id
      );
      if (triggerFoundInRemaining) {
        hasNewWorkflow = true;
      }
    }
    return hasNewWorkflow;
  }

  async function confirmUpdate(payload, isDraft) {
    try {
      switch (activeKey) {
        case PURCHASE_ORDER_TABS_KEYS.REJECTED:
          await resubmitPurchaseOrder(poDetails.id, payload, isDraft);
          break;
        default:
          await updatePurchaseOrder(poDetails.id, payload, isDraft);
      }
      hideModal();
      setAutomationSelectValue("");
      onSuccess({ isDraft });
    } catch (error) {
      onError(error);
    }
  }

  async function taxCodeListHandler() {
    try {
      const response = await getTaxCodeList({
        key: CONFIGURATIONS_KEYS.taxCode,
      });
      setIsTaxRequired(response?.isRequired === 1);
      if (Array.isArray(response?.taxList)) {
        const tempArray = response?.taxList?.map(
          ({ id, field_value, field_name }) => ({
            value: id,
            label: field_name,
            vatRate: field_value,
          })
        );
        setTaxCodeList(tempArray);
      }
    } catch (error) {
    } finally {
    }
  }

  useEffect(() => {
    taxCodeListHandler();
  }, []);

  useEffect(() => {
    onSelectTaxCode(taxCodeIdWatch);
  }, [taxCodeList, taxCodeIdWatch]);

  useEffect(() => {
    let entity_id = null;
    if (getArrayLength(entityList) === 1) {
      setEntityFieldDisabled(true);
      if (poDetails?.entity_id) {
        entity_id = poDetails?.entity_id;
      } else {
        entity_id = entityList[0].value;
      }
    } else if (poDetails?.entity_id) {
      entity_id = poDetails?.entity_id;
    }
    if (checkIfDefaultEntity(poDetails?.entity_id)) {
      if (getArrayLength(entityList) === 1) {
        form.setFieldsValue({
          entity_id: entityList[0].value,
        });
      } else {
        form.setFieldsValue({
          entity_id: null,
        });
      }
    } else {
      form.setFieldsValue({
        entity_id,
      });
    }
    if (entity_id) {
      updateSupplierListOnEntityChange(entity_id);
    }
    setEntityId(entity_id);
  }, [entityList, form, poDetails, defaultEntityList]);

  const checkIfDefaultEntity = useCallback(
    (id) => {
      if (defaultEntityList.length > 0) {
        const fountDefaultEntity = defaultEntityList?.some(
          (item) => item?.id === id
        );
        return fountDefaultEntity;
      } else {
        return false;
      }
    },
    [defaultEntityList]
  );

  function onSelectTaxCode(value) {
    const tempData = taxCodeList?.find((item) => item?.value === value);
    form.setFieldsValue({
      [PURCHASE_ORDER_CREATE_FROM.taxRate.name]: tempData?.vatRate,
    });
  }

  const showGenAITooltipAndIcon = (fieldName) => {
    return {
      suffixIcon: dataPopulatedByAI?.[fieldName] ? "smart_toy" : "",
      suffixIconClassName: "brand-color",
      suffixIconTooltip: PURCHASE_ORDER_POPULATED_USING_AI,
      onChange: () => inputChangedFromAiValue(fieldName),
    };
  };

  const inputChangedFromAiValue = (fieldName) => {
    if (dataPopulatedByAI.hasOwnProperty(fieldName)) {
      delete dataPopulatedByAI[fieldName];
      setDataPopulatedByAI({ ...dataPopulatedByAI });
    }
  };

  const datesRange = (
    <>
      <Column span={12}>
        <CommonDatePicker
          name="startDate"
          label={PURCHASE_ORDER_CREATE_FROM.FORM_LABELS.startDate}
          rules={PO_CREATE_FORM_RULE.START_DATE}
          suffixIcon={<Icon iconName={"calendar_month"} />}
          disabledDate={(current) =>
            disableUpcomingDates(current, completionDate)
          }
          clearIcon={
            <Icon iconName={"close"} className={"kloo-select-close-icon"} />
          }
          allowClear
        />
      </Column>
      <Column span={12}>
        <CommonDatePicker
          name="deliveryDate"
          label={PURCHASE_ORDER_CREATE_FROM.FORM_LABELS.deliveryDate}
          rules={PO_CREATE_FORM_RULE.DELIVERY_DATE}
          suffixIcon={<Icon iconName={"calendar_month"} />}
          disabledDate={(current) => disableOlderDates(current, startDate)}
        />
      </Column>
    </>
  );

  const getCTALabel = () => {
    let buttonLabel = "";
    if (isEditView) {
      switch (activeKey) {
        case PURCHASE_ORDER_TABS_KEYS.IN_REVIEW:
          buttonLabel = PURCHASE_ORDER_CREATE_FROM.UPDATE_PO_LABEL;
          break;
        case PURCHASE_ORDER_TABS_KEYS.REJECTED:
          buttonLabel = PURCHASE_ORDER_CREATE_FROM.RESUBMIT_PO_LABEL;
          break;
        case PURCHASE_ORDER_TABS_KEYS.DRAFT:
          buttonLabel = PURCHASE_ORDER_CREATE_FROM.DRAFT_PO_LABEL;
          break;
        default:
          buttonLabel = BUTTON_LABELS.SAVE;
      }
    } else {
      buttonLabel = PURCHASE_ORDER_CREATE_FROM.FORM_LABELS.createButtonLabel;
    }
    return buttonLabel;
  };

  const handlePODelete = async () => {
    try {
      await deletePurchaseOrder([poDetails.id]);
      onSuccess({ isDelete: true });
    } catch (error) {
      onError(error);
    }
  };
  const drawerHeader = (
    <Box justifyContent="flexStart">
      <Typography
        variant="title"
        text={
          isEditView
            ? PURCHASE_ORDER_CREATE_FROM.EDIT_FORM_TITLE
            : PURCHASE_ORDER_CREATE_FROM.FORM_TITLE
        }
        fontSize={"24"}
        responsiveFontSize={false}
      />
      <When condition={Object.keys(dataPopulatedByAI).length > 0}>
        <CustomAlert
          className="my-2 p-8px m-l-20px"
          message={PURCHASE_ORDER_GENERATED_USING_AI}
          icon={
            <Icon
              showIconOutline={true}
              iconName={"smart_toy"}
              className={"brand-color"}
            />
          }
          type={"success"}
          showIcon
        />
      </When>
    </Box>
  );

  const DrawerFooter = () => {
    return (
      <Box justifyContent="flexEnd">
        <CustomButton
          text={getCTALabel()}
          accent={ACCENTS.PRIMARY}
          variant={BUTTON_VARIANT.BUTTON_FILLED}
          htmlType={"submit"}
          className="w-20"
          onClick={validateAndHandleForm}
          disabled={
            uploadContainerDisabled || isLineItemsMandatory()
              ? shouldSubmitButtonDisabledForLineItemError
              : false
          }
          customSize={"large"}
        />
        <When
          condition={poDetails?.status !== PURCHASE_ORDER_TABS_KEYS.IN_REVIEW}
        >
          <CustomButton
            text={BUTTON_LABELS.DRAFT}
            accent={ACCENTS.SECONDARY}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            onClick={draftHandleForm}
            className="w-20"
            customSize={"large"}
          />
        </When>
        <CustomButton
          text={BUTTON_LABELS.CANCEL}
          accent={ACCENTS.SECONDARY}
          variant={BUTTON_VARIANT.BUTTON_HOLLOW}
          onClick={onClose}
          className="w-20"
          customSize={"large"}
        />
        <When
          condition={
            [PURCHASE_ORDER_TABS_KEYS.REJECTED].includes(activeKey) &&
            isEditView
          }
        >
          <CustomButton
            text={PURCHASE_ORDER_REVIEW_DRAWER.DELETE_PR_BUTTON_LABEL}
            accent={ACCENTS.DESTRUCTIVE}
            variant={BUTTON_VARIANT.BUTTON_HOLLOW}
            onClick={handlePODelete}
            className="w-20"
            showLoader={true}
            customSize={"large"}
          />
        </When>
      </Box>
    );
  };

  const handleEntityChange = (value) => {
    setEntityId(value);
    form.setFieldValue("supplier", null);
    updateSupplierListOnEntityChange(value);
  };

  const handleLineItemsBack = () => {
    setShowLineItems(false);
  };

  const handleLineItemsSave = (data) => {
    setShowLineItems(false);
    setNewNetAmount(data);
    validateTaxSolution();
  };

  const setNewNetAmount = (items) => {
    const netAmount = roundOff(
      items?.reduce((accumulator, item) => accumulator + item.total_amount, 0)
    );
    form.setFieldValue(PURCHASE_ORDER_CREATE_FROM.netAmount.name, netAmount);
  };

  const {
    lineItemContainerClassName,
    lineItemHelperText,
    shouldSubmitButtonDisabledForLineItemError,
  } = checkLineItemsFormItemStatus({
    lineItems,
    isERPMapped,
    connectedErpPlatformName,
    isPO: true,
  });

  const openLineItemsTable = () => {
    setShowLineItems(true);
  };

  const isLineItemsMandatory = () => {
    if (getItem(LOCAL_STORAGE_KEY.ORD_ID) === BC_ORG_ID) {
      return false;
    }
    return true;
  };

  const isSageIntactPlatform =
    connectedErpPlatformName ===
      ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.sageIntacct
        .keyToCheckLogo && isERPMapped;

  const fetchTaxSolutionOption = (selectedValue) => {
    getTaxSolutionOptions(selectedValue).then((res) => {
      setTaxSolutionOptions(res);
    });
  };

  const validateTaxSolution = async () => {
    const taxSolutionValue = form.getFieldValue(INVOICE_FORM.TAX_SOLUTION.name);
    if (!isERPMapped || !isSageIntactPlatform) {
      return true;
    }
    if (!taxSolutionValue || !lineItems?.length) {
      return false;
    }
    try {
      const payload = {
        line_items: lineItemPayload(),
        tax_solution_id: taxSolutionValue,
        currency: currencyValue?.[0]?.currency,
      };
      await isTaxSolutionValid(convertJSObjectToFormData(payload));
      form.setFields([
        {
          name: INVOICE_FORM.TAX_SOLUTION.name,
          errors: [],
        },
      ]);
      return true;
    } catch (error) {
      form.setFields([
        {
          name: INVOICE_FORM.TAX_SOLUTION.name,
          errors: [
            error?.response?.data?.message ||
              INVOICE_FORM.TAX_SOLUTION.errorText,
          ],
        },
      ]);
      return false;
    }
  };

  useEffect(() => {
    if (entityValue && isSageIntactPlatform) {
      fetchTaxSolutionOption(entityValue);
    }
  }, [entityValue, isSageIntactPlatform]);

  useEffect(() => {
    if (poDetails?.tax_solution_id === taxSolutionValue) {
      return;
    }
    if (lineItems?.length) {
      setLineItems(
        lineItems.map((item) => {
          if (item.taxRate) {
            item.taxRate = null;
          }
          return item;
        })
      );
    }
  }, [taxSolutionValue]);

  const onEntitySelection = (value) => {
    form.setFieldValue(INVOICE_FORM.TAX_SOLUTION.name, null);
    onHandleTrigger(
      GEN_AI_TRIGGER_MAPPING[0].mappingKey,
      value,
      GEN_AI_TRIGGER_MAPPING[0].value
    );
    if (lineItems?.length) {
      setLineItems(
        lineItems.map((item) => cleanERPFieldsFromLineItemObject(item))
      );
    }
  };

  return (
    <Drawer
      className={`purchase-order-drawer-v1`}
      open={open}
      onClose={onClose}
      titleReactNode={drawerHeader}
    >
      <Form
        form={form}
        initialValues={initialState}
        layout="vertical"
        scrollToFirstError
        // onFinish={handleForm}
      >
        <Row gutter={24}>
          <When condition={entityFieldEnabled}>
            <Column span={12}>
              <CustomSelect
                {...PURCHASE_ORDER_CREATE_FROM.entity}
                onSelect={onEntitySelection}
                onChange={handleEntityChange}
                options={entityList}
                disabled={entityFieldDisabled}
              />
            </Column>
          </When>
          <Column span={12}>
            <CustomSelect
              name="supplier"
              options={supplierList}
              label={PURCHASE_ORDER_CREATE_FROM.FORM_LABELS.supplier}
              placeholder={PURCHASE_ORDER_CREATE_FROM.placeholder.supplier}
              rules={PO_CREATE_FORM_RULE.SUPPLIER}
              onSelect={(options) =>
                onHandleTrigger(
                  GEN_AI_TRIGGER_MAPPING[1].mappingKey,
                  options,
                  LC_SUPPLIER_PLACEHOLDER
                )
              }
            />
          </Column>

          <Column span={12}>
            <CommonInput
              name="description"
              label={PURCHASE_ORDER_CREATE_FROM.FORM_LABELS.description}
              placeholder={PURCHASE_ORDER_CREATE_FROM.placeholder.description}
              rules={PO_CREATE_FORM_RULE.DESCRIPTION}
            />
          </Column>
          <Column span={12}>
            <CommonSelect
              name="owner"
              options={ownerList}
              label={PURCHASE_ORDER_CREATE_FROM.FORM_LABELS.owner}
              placeholder={PURCHASE_ORDER_CREATE_FROM.placeholder.owner}
              rules={PO_CREATE_FORM_RULE.OWNER}
              onChange={() => onHandleTrigger(OWNER)}
            />
          </Column>

          {datesRange}

          <Column span={12}>
            <Row gutter={24}>
              <Column span={6}>
                <CurrencySelect
                  name="currency"
                  rules={PO_CREATE_FORM_RULE.CURRENCY}
                  onSelectHandle={onSelectHandle}
                  currencies={rawCurrencies}
                  className="currency-selector"
                  label={PURCHASE_ORDER_CREATE_FROM.FORM_LABELS.currency}
                />
              </Column>
              <Column span={18} className="total-gross-amount">
                <CommonInputNumber
                  {...PURCHASE_ORDER_CREATE_FROM.netAmount}
                  formatter={amountFormatterWithoutCurrency}
                  rules={PO_CREATE_FORM_RULE.NET_AMOUNT({
                    currencySymbol: currencyValue[0]?.symbol,
                  })}
                  disabled={viewLineItems || isItemAdded}
                  suffixIconTooltip={
                    isFeat11894Enabled()
                      ? PURCHASE_ORDER_CREATE_FROM.netAmount.suffixIconTooltip
                      : PURCHASE_ORDER_CREATE_FROM.netAmount
                          .suffixIconTooltipOld
                  }
                />
              </Column>
            </Row>
          </Column>
          <Column span={12}>
            <Row gutter={24} className="h-100">
              <When condition={taxCodeFieldEnabled}>
                <Column span={9}>
                  <CommonSelect
                    {...PURCHASE_ORDER_CREATE_FROM.taxCode}
                    rules={
                      isTaxRequired ? [...PO_CREATE_FORM_RULE.TAX_CODE] : []
                    }
                    options={taxCodeList}
                    {...showGenAITooltipAndIcon(
                      PURCHASE_ORDER_CREATE_FROM.taxCode.name
                    )}
                  />
                </Column>
                <Column span={9}>
                  <CommonInputNumber
                    {...PURCHASE_ORDER_CREATE_FROM.taxRate}
                    isRequiredField={isTaxRequired}
                    suffixTooltipList={
                      isFeat11894Enabled()
                        ? PURCHASE_ORDER_CREATE_FROM.taxRate.suffixTooltipList
                        : []
                    }
                  />
                </Column>
              </When>
              <Column span={6}>
                <Box
                  className={"h-100"}
                  direction="column"
                  justifyContent="center"
                  alignContent="flexStart"
                  alignItem="flexStart"
                >
                  <Label
                    text={"Total amount:"}
                    optional={true}
                    suffixTooltipList={
                      isFeat11894Enabled()
                        ? PURCHASE_ORDER_CREATE_FROM.totalAmount
                            .suffixTooltipList
                        : []
                    }
                  />
                  <Typography
                    text={`${moneyFormatter(
                      calculateTotalAmount(netAmount, taxRate),
                      0,
                      currencyValue[0]?.currency
                    )}`}
                    variant={"body"}
                    fontStyle={"semibold"}
                  />
                </Box>
              </Column>
            </Row>
          </Column>
          <When condition={isSageIntactPlatform}>
            <Column span={12}>
              <CommonSelect
                {...INVOICE_FORM.TAX_SOLUTION}
                // disabled={isDisabledOnEdit}
                options={taxSolutionOptions}
                onChange={validateTaxSolution}
              />
            </Column>
          </When>

          <CustomField
            fields={resultObject?.purchaseOrders}
            setFields={onAddDynamic}
            onChange={(field) =>
              onHandleTrigger(field?.inputLabel, field?.id, field?.name)
            }
            version={version}
            toggleConditionalFields={toggleConditionalFields}
            dataPopulatedByAI={dataPopulatedByAI}
            setDataPopulatedByAI={setDataPopulatedByAI}
            columnSpan={12}
          />
        </Row>
        {/* line item */}
        <Box
          className={constructClassName([
            "new-item-list-view p-2",
            isLineItemsMandatory() && lineItemContainerClassName,
          ])}
          justifyContent={"spaceBetween"}
        >
          <Box direction={"column"} alignItem={"flexStart"} rowGap={2}>
            <Label
              text={INVOICE_LINE_ITEMS.label}
              asteriskContainerClassName="asterisk-container"
              optional={!isERPMapped || !isLineItemsMandatory()}
            />
            <Typography
              variant="body"
              text={
                isLineItemsMandatory()
                  ? lineItemHelperText
                  : PURCHASE_ORDER_CREATE_FROM.itemSwitch.info
              }
              fontSize={12}
              textColorType={
                shouldSubmitButtonDisabledForLineItemError &&
                isLineItemsMandatory()
                  ? "destructive"
                  : undefined
              }
            />
          </Box>
          <CustomButton text={"View"} onClick={openLineItemsTable} />
        </Box>
        <When condition={showLineItems}>
          <LineItems
            module={MODULE_TYPES.PO}
            onBackClick={handleLineItemsBack}
            onSave={handleLineItemsSave}
            lineItemsTableData={lineItems}
            setLineItemsTableData={setLineItems}
            entityId={entityId}
            chartsOfAccountList={chartsOfAccountList}
            trackingCategoriesList={trackingCategoriesList}
            integrationName={connectedErpPlatformName}
            deleteLineItem={deleteLineItem}
            disabled={false}
            setLineItemsValuesChanged={setLineItemsValuesChanged}
            currency={currencyValue?.[0]?.symbol || currencyValue}
            totalAmount={totalAmount}
            isDrawerRequired={false}
            customFields={resultObject?.purchaseOrderLineItems}
            setFieldOptions={onAddDynamic}
            version={version}
            showKlooAIButton={false}
            erpFieldsOptional={!isLineItemsMandatory()}
            isSageIntactPlatform={isSageIntactPlatform}
            taxSolution={selectedTaxSolution?.label}
            tab={activeKey}
          />
        </When>

        <div className="m-b-18px">
          <UploadAttachmentForPO
            customRequest={onUpload}
            viewAttachments={viewAttachments}
            setViewAttachments={setViewAttachments}
            attachments={attachments}
            removeSelectedAttachment={removeSelectedAttachment}
            uploadContainerDisabled={uploadContainerDisabled}
          />
        </div>
        <When condition={!showLineItems}>
          <div className="ant-drawer-footer purchase-footer">
            <DrawerFooter />
          </div>
        </When>
      </Form>
    </Drawer>
  );
}

PurchaseOrderForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  width: PropTypes.number,
  isEditView: PropTypes.bool,
  poDetails: PropTypes.object,
  reloadTable: PropTypes.func,
};

export default PurchaseOrderForm;

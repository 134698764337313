import {
  ACCENTS,
  BUTTON_VARIANT,
  LC_SUPPLIER_PLACEHOLDER,
  SIZE,
  SUPPLIER_PLACEHOLDER,
  TAG_VARIANT,
} from "..";
import { isMatchVisibleOnHoldTab } from "../../helper/InvoiceManagement";
import {
  IM_CREATE_INVOICE_FORM_RULE,
  IM_INVOICE_DESC_FIELD,
  IM_INVOICE_NUMBER_FIELD,
  INVOICE_FORM_RULES,
  isPlatformUser,
  PAYMENT_JOURNEY_FORM,
} from "../../utils";
import {
  INVOICE_MATCHING_TABLE_COLUMNS_MATCH_KEY,
  INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY,
} from "../InvoiceMatching";
import {
  PURCHASE_ORDER_CREATE_FROM,
  TRIGGER_SUPPLIER_ID,
} from "../purchaseOrder";

export const GBP_CURRENCY_ID = "eb0e6736-72c1-41cc-9664-e52d79d0b8a7";
export const MULTIPLE_PO_DROPDOWN_OPTION_ID = "multi-PO";
export const DYNAMIC_ATTRIBUTE_TYPE = "dynamic";
export const INVOICES_LABEL = "Invoices";

export const PAID_TAB_CONFIG = {
  DOWNLOAD_BUTTON: {
    text: "Download PDF",
  },
};

export const INVOICE_MANAGEMENT_TAB_KEYS = {
  SUBMITTED: "submitted",
  IN_REVIEW: "in_review",
  TO_PAY: "approved",
  SCHEDULED: "scheduled",
  PAID: "paid",
  REJECTED: "rejected",
  COMPLETED: "completed",
  DELETE: "delete",
  DELETED: "deleted",
  HOLD: "hold",
  CANCELLED: "cancelled",
  DRAFT: "draft",
};

export const IM_FE_BE_TAB_MAPPING = {
  [INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED]: "submitted",
  [INVOICE_MANAGEMENT_TAB_KEYS.REJECTED]: "rejected",
  [INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW]: "in_review",
  [INVOICE_MANAGEMENT_TAB_KEYS.HOLD]: "on-hold",
  [INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY]: "to-pay",
  [INVOICE_MANAGEMENT_TAB_KEYS.SCHEDULED]: "schedule",
  [INVOICE_MANAGEMENT_TAB_KEYS.DRAFT]: "draft",
};

export const TAB_WISE_TYPE_CONFIG = [
  {
    key: "submit",
    tabKey: "submitted",
  },
  {
    key: "in_review",
    tabKey: "in_review",
  },
  {
    key: "paid",
    tabKey: "paid",
  },
];

export const TOOL_TIP_TEXT = {
  approveNowButton:
    "This invoice has not been matched to a purchase order and cannot be approved.",
  workFlowButton:
    "This invoice has not been matched to a purchase order and cannot be assigned to a workflow.",
  netAmount: "The total value before tax",
  taxAmount: "The taxable amount",
  totalAmount: "The sum of the net amount and tax amount",
};

export const BULK_ACTIONS = {
  APPROVE: "approve",
  ASSIGN_TO_WORKFLOW: "assignToWorkflow",
  REJECT: "reject",
  DELETE: "delete",
  PLACE_ON_HOLD: "placeOnHold",
  MARK_AS_PAID: "markAsPaid",
  CANCEL: "cancel",
  REMOVE_FROM_HOLD: "removeFromHold",
  REASSIGN_TO_WORKFLOW: "reassignToWorkflow",
  UNASSIGN_TO_WORKFLOW: "unassignToWorkflow",
  RESEND_APPROVAL_EMAIL: "resendApprovalEmail",
};

export const INVOICE_MANAGEMENT_MATCHING_TEXTS = {
  title: "Purchase orders",
  subTitle:
    "Select the purchase orders you would like to split the invoice across",
  selectionText: "Purchase order to be split across the invoice",
};

export const BULK_ACTION_TYPES_BE = {
  [BULK_ACTIONS.APPROVE]: "bulk-approved",
  [BULK_ACTIONS.REJECT]: "bulk-rejected",
  [BULK_ACTIONS.PLACE_ON_HOLD]: "bulk-hold",
  [BULK_ACTIONS.MARK_AS_PAID]: "bulk-paid",
  [BULK_ACTIONS.REMOVE_FROM_HOLD]: "bulk-progress",
  [BULK_ACTIONS.REASSIGN_TO_WORKFLOW]: "bulk-reassign-workflow",
  [BULK_ACTIONS.UNASSIGN_TO_WORKFLOW]: "bulk-unassign-workflow",
  [BULK_ACTIONS.CANCEL]: "bulk-cancelled",
  [BULK_ACTIONS.DELETE]: "bulk-deleted",
  [BULK_ACTIONS.ASSIGN_TO_WORKFLOW]: "bulk-assign-workflow",
};

export const BULK_ACTION_WARNING_MSG = {
  [BULK_ACTIONS.APPROVE]: {
    getWarningMessage: (list) =>
      list?.length === 1
        ? `The highlighted invoice has not been matched to a purchase order and cannot be approved. To continue, remove this invoice from the listing. `
        : `The highlighted invoices have not been matched to a purchase order and cannot be approved. To continue, remove these invoices from the listing. `,
  },
  [BULK_ACTIONS.ASSIGN_TO_WORKFLOW]: {
    getWarningMessage: (list) =>
      list?.length === 1
        ? `The highlighted invoice has not been matched to a purchase order and cannot be assigned to a workflow. To continue, remove this invoice from the listing.`
        : `The highlighted invoices have not been matched to a purchase order and cannot be assigned to a workflow. To continue, remove these invoices from the listing. `,
  },
};

export const BULK_ACTION_SUCCESS_MSG = {
  [BULK_ACTIONS.APPROVE]: "Invoices approved",
  [BULK_ACTIONS.REJECT]: "Invoices rejected",
  [BULK_ACTIONS.PLACE_ON_HOLD]: "Invoices placed on hold",
  [BULK_ACTIONS.MARK_AS_PAID]: "Invoices marked as paid",
  [BULK_ACTIONS.REMOVE_FROM_HOLD]: "Invoices removed from on-hold",
  [BULK_ACTIONS.REASSIGN_TO_WORKFLOW]:
    "Invoices reassigned to new workflow successfully",
  [BULK_ACTIONS.UNASSIGN_TO_WORKFLOW]:
    "Invoices unassigned from workflow successfully",
  [BULK_ACTIONS.CANCEL]: "Payments cancelled",
  [BULK_ACTIONS.DELETE]: "Invoices deleted",
  [BULK_ACTIONS.ASSIGN_TO_WORKFLOW]: "Invoices assigned to workflow",
};

export const IM_DRAFT_BULK_BUTTONS = [
  {
    id: BULK_ACTIONS.DELETE,
    text: "Delete invoice",
    accent: ACCENTS.DESTRUCTIVE,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
  },
];

export const IM_SUBMITTED_BULK_BUTTONS = [
  {
    id: BULK_ACTIONS.APPROVE,
    text: "Approve now",
    className: "w-100",
    accent: ACCENTS.WHITE,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    enabledFor: [INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED],
  },

  {
    id: BULK_ACTIONS.ASSIGN_TO_WORKFLOW,
    text: "Assign to workflow",
    className: "w-100",
    accent: ACCENTS.WHITE,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    enabledFor: [INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED],
  },
  {
    id: BULK_ACTIONS.REJECT,
    text: "Reject",
    className: "w-100",
    accent: ACCENTS.WHITE,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    enabledFor: [INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED],
  },
  {
    id: BULK_ACTIONS.DELETE,
    text: "Delete",
    className: "w-100",
    accent: ACCENTS.WHITE,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    enabledFor: [
      INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED,
      INVOICE_MANAGEMENT_TAB_KEYS.REJECTED,
      INVOICE_MANAGEMENT_TAB_KEYS.CANCELLED,
    ],
  },
  {
    id: BULK_ACTIONS.PLACE_ON_HOLD,
    text: "Place on hold",
    className: "w-100",
    accent: ACCENTS.WHITE,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    enabledFor: [INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED],
  },
];

export const IM_REJECTED_BULK_BUTTONS = [
  {
    id: BULK_ACTIONS.DELETE,
    text: "Delete invoice",
    accent: ACCENTS.DESTRUCTIVE,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
  },
];

export const IM_IN_REVIEW_BULK_BUTTONS = [
  {
    id: BULK_ACTIONS.APPROVE,
    text: "Approve",
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
  {
    id: BULK_ACTIONS.REJECT,
    text: "Reject",
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
  {
    id: BULK_ACTIONS.REASSIGN_TO_WORKFLOW,
    text: "Reassign",
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
  {
    id: BULK_ACTIONS.UNASSIGN_TO_WORKFLOW,
    text: "Unassign",
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
  {
    id: BULK_ACTIONS.RESEND_APPROVAL_EMAIL,
    text: "Resend approval Email",
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
];

export const IM_TO_PAY_BULK_BUTTONS = [
  {
    id: BULK_ACTIONS.MARK_AS_PAID,
    text: "Mark as paid",
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    enabledFor: [INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY],
  },
  {
    id: BULK_ACTIONS.PLACE_ON_HOLD,
    text: "Place on hold",
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    enabledFor: [INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY],
  },
];

export const IM_ON_HOLD_BULK_BUTTONS = [
  {
    id: BULK_ACTIONS.REMOVE_FROM_HOLD,
    text: "Remove from on hold",
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
];

export const IM_SCHEDULED_BULK_BUTTONS = [
  {
    id: BULK_ACTIONS.CANCEL,
    text: "Cancel payment",
    className: "w-100",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
];

export const INVOICE_POPULATED_USING_AI = "Invoice data populated by Kloo AI";

export const INVOICE_FORM = {
  SUPPLIER_ACCOUNT: {
    label: `${SUPPLIER_PLACEHOLDER} account`,
    placeholder: `Select ${LC_SUPPLIER_PLACEHOLDER} account`,
    name: "supplierAccountId",
    suffixIconClassName: "brand-color",
    suffixIconTooltip: INVOICE_POPULATED_USING_AI,
  },
  TAX_CODE: {
    label: "Tax code",
    placeholder: `Select tax code`,
    name: "taxCode",
    wrapperClass: "w-50",
  },
  TAX_SOLUTION: {
    label: "Tax solution",
    placeholder: `Select tax solution`,
    name: "taxSolution",
    wrapperClass: "w-100",
    rules: INVOICE_FORM_RULES.TAX_SOLUTION,
    errorText:
      "The selected tax solution is incompatible with the account for this invoice.",
  },
  VAT_RATE: {
    name: "vatRate",
    step: "0.01",
    placeholder: "VAT rate",
    prefix: "%",
    controls: false,
    label: "VAT rate",
    className: "flex-row-reverse",
    rules: INVOICE_FORM_RULES.VAT_RATE,
  },
  SUPPLIER: {
    label: SUPPLIER_PLACEHOLDER,
    placeholder: `Select ${LC_SUPPLIER_PLACEHOLDER}`,
    name: "supplierId",
    suffixIconTooltip: INVOICE_POPULATED_USING_AI,
    suffixIconClassName: "brand-color",
    rules: INVOICE_FORM_RULES.SUPPLIER,
  },
  INVOICE_NUMBER: {
    name: "invoiceNumber",
    rules: IM_INVOICE_NUMBER_FIELD,
    placeholder: "Invoice number",
    maxLength: 50,
    label: "Invoice number",
    aiKey: "invoice_number",
  },
  AP_DESCRIPTION: {
    name: "apDescription",
    rules: IM_INVOICE_DESC_FIELD,
    placeholder: "Description",
    className: "paid-data-form-inputtag optional",
    label: "Description",
    avoidScriptTagRule: true,
    aiKey: "description",
  },
  INVOICE_DATE: {
    name: "invoiceDate",
    format: "DD-MM-YYYY",
    label: "Invoice date",
    rules: INVOICE_FORM_RULES.INVOICE_DATE,
    getPopupContainer: (trigger) => {
      return trigger;
    },
    aiKey: "invoice_date",
  },
  DUE_DATE: {
    name: "dueDate",
    format: "DD-MM-YYYY",
    label: "Due date",
    rules: INVOICE_FORM_RULES.DUE_DATE,
    getPopupContainer: (trigger) => {
      return trigger;
    },
    aiKey: "due_date",
  },
  AMOUNT: {
    name: "amount",
    placeholder: "Total amount",
    controls: false,
    label: "Total amount",
    formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
    suffixTooltipList: [
      {
        suffixIconTooltip: TOOL_TIP_TEXT.totalAmount,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
    ],
  },
  VAT_NUMBER: {
    name: "vatNumber",
    placeholder: "VAT number",
    label: "VAT number",
    aiKey: "vat_number",
  },
  PO_NUMBER: {
    name: "poNumber",
    placeholder: "Select PO number",
    label: "PO number",
    suffixIconClassName: "brand-color",
    suffixIconTooltip: INVOICE_POPULATED_USING_AI,
    className: "owner-optional-field ownerSelect",
    allowClear: true,
    validPOMessage: "Kloo AI will mark this invoice as a confirmed match",
  },
  OWNER_FIELD: {
    name: "owner",
    placeholder: "Select owner",
    label: "Owner",
    suffixIconClassName: "brand-color",
    suffixIconTooltip: INVOICE_POPULATED_USING_AI,
    className: "owner-optional-field ownerSelect",
    allowClear: true,
  },
  XERO_CUSTOMER_FIELD: {
    name: "xeroCustomerContactID",
    placeholder: "Xero contact",
    label: "Xero contact",
    allowClear: true,
  },
  XERO_CATEGORY: {
    name: "xeroCategory",
    placeholder: "Category",
    label: "Category",
    allowClear: true,
  },
  TRACKING_CATEGORIES: {
    placeholder: "Tracking Category",
    name: "acc_integr_dataset_tracking_categories_id_",
    label: "Tracking category",
  },
  SAVE_AS_DRAFT_BUTTON: {
    text: "Save as draft",
    className: "w-100",
    customSize: "large",
    accent: ACCENTS.SECONDARY,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
  },
  SUBMIT_INVOICE_BUTTON: {
    text: "Submit Invoice",
    className: "w-100",
    customSize: "large",
    htmlType: "submit",
    accent: ACCENTS.PRIMARY,
    variant: BUTTON_VARIANT.BUTTON_FILLED,
  },
};

export const PAYMENT_JOURNEY_TAB_PAY_WITH_KLOO_KEY = "payWithKloo";
export const PAYMENT_JOURNEY_TAB_PAY_WITH_OPEN_BANKING_KEY =
  "payWithOpenBanking";

export const PAYMENT_JOURNEY_PAY_NOW_KEY = "payNow";
export const PAYMENT_JOURNEY_SCHEDULE_KEY = "schedulePayment";
export const PAYMENT_JOURNEY_RESCHEDULE_KEY = "ReSchedulePayment";

const PAYMENT_JOURNEY_BUTTON_CONFIG = {
  payNow: {
    text: "Pay now",
    className: "payment-button",
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    accent: ACCENTS.PRIMARY,
    htmlType: "submit",
    key: "submit",
  },
  next: {
    text: "Next",
    className: "payment-button",
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    accent: ACCENTS.PRIMARY,
    htmlType: "submit",
    key: "submit",
  },
  schedulePayment: {
    text: "Schedule payment",
    className: "payment-button",
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    accent: ACCENTS.PRIMARY,
    htmlType: "submit",
    key: "submit",
  },
  reSchedulePayment: {
    text: "Reschedule payment",
    className: "payment-button",
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    accent: ACCENTS.PRIMARY,
    htmlType: "submit",
    key: "submit",
  },
  cancel: {
    text: "Cancel",
    className: "payment-button",
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    accent: ACCENTS.SECONDARY,
    key: "cancel",
  },
};

export const PAYMENT_JOURNEY_TAB_CONFIG = [
  {
    label: "Pay with Kloo",
    key: PAYMENT_JOURNEY_TAB_PAY_WITH_KLOO_KEY,
    tabConfig: [
      {
        key: PAYMENT_JOURNEY_PAY_NOW_KEY,
        label: "Pay now",
        buttonConfig: [
          PAYMENT_JOURNEY_BUTTON_CONFIG.payNow,
          PAYMENT_JOURNEY_BUTTON_CONFIG.cancel,
        ],
      },
      {
        key: PAYMENT_JOURNEY_SCHEDULE_KEY,
        label: "Schedule Payment",
        buttonConfig: [
          PAYMENT_JOURNEY_BUTTON_CONFIG.schedulePayment,
          PAYMENT_JOURNEY_BUTTON_CONFIG.cancel,
        ],
        scheduleConfirmationLabel:
          "Once scheduled, payments cannot be placed on hold, however they can be cancelled prior to the payment date.",
      },
      {
        key: PAYMENT_JOURNEY_RESCHEDULE_KEY,
        label: "ReSchedulePayment",
        buttonConfig: [
          PAYMENT_JOURNEY_BUTTON_CONFIG.reSchedulePayment,
          PAYMENT_JOURNEY_BUTTON_CONFIG.cancel,
        ],
      },
    ],
  },
  {
    label: "Pay via Open Banking",
    key: PAYMENT_JOURNEY_TAB_PAY_WITH_OPEN_BANKING_KEY,
    paymentReferenceConfig: {
      text: "Payment reference",
      variant: "body",
      fontStyle: "semibold",
      textColorType: "tertiary",
    },
    paymentDateConfig: {
      text: "Payment date",
      variant: "body",
      fontStyle: "semibold",
      textColorType: "tertiary",
    },
    consentApprovalLabel:
      "We need your permission to securely initiate payment from your bank or building society. We will securely transfer you to your bank to authenticate and make the payment.",
    tabConfig: [
      {
        key: PAYMENT_JOURNEY_PAY_NOW_KEY,
        buttonConfig: [
          PAYMENT_JOURNEY_BUTTON_CONFIG.next,
          PAYMENT_JOURNEY_BUTTON_CONFIG.cancel,
        ],
      },
      {
        key: PAYMENT_JOURNEY_SCHEDULE_KEY,
        buttonConfig: [
          PAYMENT_JOURNEY_BUTTON_CONFIG.next,
          PAYMENT_JOURNEY_BUTTON_CONFIG.cancel,
        ],
        scheduleConfirmationLabel:
          "Once scheduled, Open Banking payments cannot be edited, placed on hold or cancelled within Kloo. To make any changes, please log into your chosen banking platform.",
      },
      {
        key: PAYMENT_JOURNEY_RESCHEDULE_KEY,
        buttonConfig: [
          PAYMENT_JOURNEY_BUTTON_CONFIG.next,
          PAYMENT_JOURNEY_BUTTON_CONFIG.cancel,
        ],
      },
    ],
  },
];

export const PAYMENT_JOURNEY_FORM_CONFIG = {
  title: "Select payment method",
  backButton: {
    iconName: "close",
    className: "cursor-pointer",
  },
  entity: {
    name: "entity_id",
    label: "Entity",
    placeholder: "Select entity",
    disabled: true,
  },
  payee: {
    placeholder: `Select ${LC_SUPPLIER_PLACEHOLDER}`,
    label: `${SUPPLIER_PLACEHOLDER}`,
    name: "payee",
    rules: PAYMENT_JOURNEY_FORM.PAY_NOW.PAYEE,
  },
  suppliersAccount: {
    placeholder: `Select ${LC_SUPPLIER_PLACEHOLDER} account`,
    label: `${SUPPLIER_PLACEHOLDER} account`,
    name: "supplierAccount",
    rules: PAYMENT_JOURNEY_FORM.PAY_NOW.SUPPLIER_ACCOUNT,
  },
  paymentDate: {
    placeholder: "Payment date",
    label: "Payment date",
    name: "paymentDate",
    rules: PAYMENT_JOURNEY_FORM.SCHEDULED.PAYMENT_DATE,
    showToday: false,
  },
  sortCode: {
    placeholder: "Sort code",
    label: "Sort code",
    name: "sortCode",
    rules: PAYMENT_JOURNEY_FORM.PAY_NOW.SORTCODE,
  },
  accountNumber: {
    placeholder: "Account number",
    label: "Account number",
    name: "accountNumber",
    rules: PAYMENT_JOURNEY_FORM.PAY_NOW.ACCOUNT_NUMBER,
  },
  proofOfPayment: {
    text: "Send proof of payment?",
    variant: "body",
    fontStyle: "semibold",
    fontSize: 16,
  },
  proofOfPaymentEmail: {
    name: "emailToSendReceipt",
    placeholder: "Remittance email address",
    rules: PAYMENT_JOURNEY_FORM.PAY_NOW.EMAIL,
  },
};

const COMMON_JOURNEY_DETAILS = [
  {
    text: `${SUPPLIER_PLACEHOLDER}`,
    key: "payee",
  },
  {
    text: `${SUPPLIER_PLACEHOLDER} account`,
    key: "supplierAccountName",
  },
  {
    text: "Sort code",
    key: "sortCode",
  },
  {
    text: "Account number",
    key: "accountNumber",
  },
  {
    text: "Payment reference",
    key: "paymentReference",
  },
];

const SUCCESS_JOURNEY_DETAILS = [
  {
    text: "Transaction ID",
    key: "transactionId",
  },
  {
    text: "Total paid",
    key: "totalPaid",
  },
  ...COMMON_JOURNEY_DETAILS,
];

export const PAYMENT_JOURNEY_SUCCESS_CONFIG = {
  title: {
    variant: "title",
    text: "Thank you!",
  },
  subTitle: {
    variant: "secondary",
    text: "Your payment has been made.",
  },
  icon: {
    iconName: "check_circle",
    className: "open-banking-payment-success",
  },
  paymentDetailsLabelConfig: [
    {
      text: "Payment date",
      key: "paymentDate",
    },
    ...SUCCESS_JOURNEY_DETAILS,
  ],
  doneButton: {
    text: "Done",
    className: "w-100 m-t-16px",
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    accent: ACCENTS.PRIMARY,
  },
};

export const PAYMENT_JOURNEY_SCHEDULED_CONFIG = {
  title: {
    variant: "title",
    text: "Thank you!",
  },
  subTitle: {
    variant: "secondary",
    text: "This payment has been scheduled.",
  },
  icon: {
    iconName: "watch_later",
    className: "open-banking-payment-scheduled",
  },
  paymentDetailsLabelConfig: [
    {
      text: "Schedule payment date",
      key: "scheduledPaymentDate",
    },
    ...SUCCESS_JOURNEY_DETAILS,
  ],
  doneButton: {
    text: "Done",
    className: "w-100 m-t-16px",
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    accent: ACCENTS.PRIMARY,
  },
};

export const PAYMENT_JOURNEY_FAILED_CONFIG = {
  title: {
    variant: "title",
    text: "Payment failed",
  },
  subTitle: {
    variant: "secondary",
    text: "Sorry, your payment was unsuccessful. Please try again.",
  },
  icon: {
    iconName: "cancel",
    className: "open-banking-payment-failure",
  },
  paymentDetailsLabelConfig: [...COMMON_JOURNEY_DETAILS],
  doneButton: {
    text: "Retry",
    className: "w-100",
    icon: "refresh",
    variant: BUTTON_VARIANT.BUTTON_FILLED,
    accent: ACCENTS.PRIMARY,
  },
  cancelButton: {
    text: "Cancel",
    className: "w-100",
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    accent: ACCENTS.SECONDARY,
  },
};

export const YAPILY_PIS_JOURNEY_CONFIG = [
  {
    key: "step_0",
    title: "Select your bank",
    subTitle: () =>
      `Choose your bank or building society from the list of supported institutions below.`,
    searchBoxPlaceHolder: "Search bank name",
    stepKey: 0,
  },
  {
    key: "step_1",
    title: "Authentication",
    subTitle: (bankName) =>
      `Scan the QR and authenticate with your ${bankName} app.`,
    buttonText: "Use desktop login",
    dividerText: "OR",
    footerButtonText: "Back",
    needHelpSection: {
      triggeredText: "Need help?",
      title: "Let us help you!",
    },
    loaderText: "Connecting you to your chosen bank or building society...",
    stepKey: 1,
  },
  {
    key: "step_2",
    title: "Authentication",
    subTitle: (bankName) =>
      `We are waiting to hear back from ${bankName}. Please wait a second.`,
    buttonText: "Relaunch your bank login",
    footerButtonText: "Back",
    loaderText: "Confirming Payment...",
    stepKey: 2,
  },
];

export const PAYMENT_TYPE_CONFIG = [
  {
    icon: "payments",
    label: "Paid with Kloo",
    key: "Paid with Kloo",
  },
  {
    icon: "auto_fix_high",
    label: "Paid via Open Banking",
    key: "Paid via Open Banking",
  },
  {
    icon: "download_for_offline",
    label: "Non-Kloo payment",
    key: "Non-Kloo payment",
  },
];

export const REJECTED_STATUS = "rejected";

export const FAILED_STATUS = "failed";

export const CANCEL_LABEL = "Cancel";

export const RESUBMIT_LABEL = "Resubmit";

export const CONFIRM_LABEL = "Confirm";

export const EDIT_LABEL = "Edit";

export const RESUBMIT_INVOICE_PAYLOAD_PROPERTIES = {
  supplier_id: "supplierId",
  acc_integr_dataset_tracking_categories_id_first:
    "acc_integr_dataset_tracking_categories_id_first",
  acc_integr_dataset_tracking_categories_id_second:
    "acc_integr_dataset_tracking_categories_id_second",
  account_integration_dataset_contact_id: "xeroCustomerContactID",
  kloo_category_id: "xeroCategory",
  service_commencement_date: "serviceComDate",
  service_duration: "serviceDuration",
  service_duration_unit: "serviceDurationUnit",
  description: "apDescription",
  vat_rate: "vatRate",
  vat_number: "vatNumber",
};
export const OPTIONAL_PAYLOAD_KEY_FOR_PAYMENT_JOURNEY = [
  "emailToSendReceipt",
  "paymentDateTime",
];

export const RESUBMIT_INVOICE_SUCCESSFULLY = "Invoice resubmitted successfully";

export const IM_CONFIRM_MODAL_LABELS = {
  YES: "Yes",
  NO: "No",
};

export const INVOICE_MANAGEMENT_TABLE_COLUMNS_STATUS_KEY = "status";
export const INVOICE_MANAGEMENT_TABLE_COLUMNS_PREVIOUS_STATUS_KEY =
  "previous_status";
export const INVOICE_MANAGEMENT_TABLE_COLUMNS_PAYMENT_TYPE_KEY = "paymentType";
export const INVOICE_MANAGEMENT_TABLE_COLUMNS_TOTAL_AMOUNT_KEY = "totalAmount";
export const INVOICE_MANAGEMENT_TABLE_COLUMNS_REMITTANCE_KEY = "remittance";
export const INVOICE_MANAGEMENT_TABLE_COLUMNS_PAYMENT_RUN_NAME_KEY =
  "paymentrun_name";
export const INVOICE_ALLOCATED_AMOUNT_CREDIT_NOTE_KEY = "applied_amount";
export const INVOICE_MATCHING_TABLE_ERP_STATUS_KEY =
  "send_to_accounting_portal";

export const INVOICE_MANAGEMENT_DEFAULT_ROW_HEIGHT = 48;
export const INVOICE_MANAGEMENT_DEFAULT_PAGE_SIZE = 10;

export const getStatusLabel = (value) => {
  return value === "submitted"
    ? "Awaiting assignment"
    : value === "hold"
    ? "On hold"
    : value;
};

export const PAGE_HEADER_INFO_INVOICE_MANAGEMENT = {
  desc: "Submit invoices for approval and schedule invoices to be paid in the future.",
  header: "Invoice management",
  buttonIcon: "add_circle",
  buttonLabel: "Upload invoice",
  buttonLabelMobile: "Upload invoice",
};

export const INVOICE_MANAGEMENT_SELECTED_TEXT = {
  SELECTED_TEXT: (invoiceSelected) =>
    `${invoiceSelected} ${
      invoiceSelected === 1 ? "invoice" : "invoices"
    } selected`,
  buttonIcon: "add_circle",
};

export const INVOICE_MANAGEMENT_TAG_CLASSNAMES = {
  approved: TAG_VARIANT.ACTIVE,
  rejected: TAG_VARIANT.REJECTED,
  cancelled: TAG_VARIANT.REJECTED,
  failed: TAG_VARIANT.REJECTED,
  "Payment failed": TAG_VARIANT.REJECTED,
  submitted: TAG_VARIANT.AWAITING_APPROVAL,
  hold: TAG_VARIANT.ON_HOLD,
};

export const IM_WORKFLOW_KEY = "workflow_name";

export const INVOICE_MANAGEMENT_TABLE_COLUMNS = {
  status: {
    label: "Status",
    key: INVOICE_MANAGEMENT_TABLE_COLUMNS_STATUS_KEY,
    align: "center",
    ellipsis: false,
  },
  invoiceNumber: {
    label: "Invoice number",
    key: "invoice_number",
    align: "left",
  },
  creationDate: {
    label: "Creation date",
    key: "creationDate",
    align: "left",
  },
  dueDate: {
    label: "Due date",
    key: "formattedDueDate",
    align: "left",
  },
  payee: {
    label: SUPPLIER_PLACEHOLDER,
    key: "payee",
    align: "left", // altered in API
  },
  description: {
    label: "Description",
    key: "description",
    align: "left",
  },
  entity: {
    label: "Entity",
    key: "entity_name",
    align: "left",
  },
  amount: {
    label: "Total amount",
    key: INVOICE_MANAGEMENT_TABLE_COLUMNS_TOTAL_AMOUNT_KEY,
    align: "right", // altered in API
  },
  nextApprover: {
    label: "Next approver",
    key: "step_name",
    align: "left",
  },
  workflow: {
    label: "Workflow",
    key: IM_WORKFLOW_KEY,
    align: "left",
  },
  approvedBy: {
    label: "Approved by",
    key: "approved_by",
    align: "left",
  },
  raisedBy: {
    label: "Raised by",
    key: "raised_by",
    align: "left",
  },
  scheduledDate: {
    label: "Scheduled date",
    key: "scheduledDate",
    align: "left",
  },
  paymentDate: {
    label: "Payment date",
    key: "invoice_payment_date",
    align: "left",
  },
  paidBy: {
    label: "Paid by",
    key: "paid_by",
    align: "left",
  },
  paymentRunName: {
    label: "Payment run",
    key: "paymentrun_name",
    align: "left",
  },
  paymentType: {
    label: "Payment type",
    key: INVOICE_MANAGEMENT_TABLE_COLUMNS_PAYMENT_TYPE_KEY,
    align: "left",
  },
  remittance: {
    label: "Remittance",
    key: INVOICE_MANAGEMENT_TABLE_COLUMNS_REMITTANCE_KEY,
    align: "center",
  },
  erpStatus: {
    label: "ERP status",
    key: INVOICE_MATCHING_TABLE_ERP_STATUS_KEY,
    align: "center",
  },
  matchType: {
    label: "Match type",
    key: INVOICE_MATCHING_TABLE_COLUMNS_MATCH_TYPE_KEY,
    align: "center",
  },
  match: {
    label: "Match",
    key: INVOICE_MATCHING_TABLE_COLUMNS_MATCH_KEY,
    align: "left",
  },
  previousState: {
    label: "Previous status",
    key: INVOICE_MANAGEMENT_TABLE_COLUMNS_PREVIOUS_STATUS_KEY,
    align: "center",
    ellipsis: false,
  },
  finalApprover: {
    label: "Final approver",
    key: "approved_by",
    align: "left",
  },
};

export const INVOICE_MANAGEMENT_TABLE_COLUMNS_DRAFT = ({
  userType,
  isDev,
  isInvoiceMatchingVisible,
  isStage,
  isProd,
}) => {
  return [
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber,
      width: 20,
    },
    { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.creationDate, width: 15 },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee,
      width: 15,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.description,
      width: 20,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.raisedBy,
      width: 15,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount,
      width: 15,
    },
  ];
};

export const INVOICE_MANAGEMENT_TABLE_COLUMNS_SUBMITTED = ({
  userType,
  isDev,
  isInvoiceMatchingVisible,
  isStage,
  isProd,
}) => {
  const showRaisedByColumn = !isPlatformUser(userType);
  const showInvoiceMatchingColumns = isInvoiceMatchingVisible;
  return [
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.status,
      width: showInvoiceMatchingColumns || !showRaisedByColumn ? 10 : 20,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber,
      width: showInvoiceMatchingColumns ? (!showRaisedByColumn ? 10 : 8) : 15,
    },
    { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.dueDate, width: 11 },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee,
      width: showInvoiceMatchingColumns ? 10 : 12,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.description,
      width: !showRaisedByColumn
        ? showInvoiceMatchingColumns
          ? 15
          : 20
        : showInvoiceMatchingColumns
        ? 15
        : 20,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.entity,
      width: showInvoiceMatchingColumns ? 10 : 12,
      visible: true,
      align: !showRaisedByColumn ? "center" : "left",
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount,
      width: !showRaisedByColumn ? 8 : 10,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.matchType,
      width: showInvoiceMatchingColumns ? 13 : 11,
      visible: showInvoiceMatchingColumns,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.match,
      width: 5,
      visible: showInvoiceMatchingColumns,
    },
  ];
};

export const INVOICE_MANAGEMENT_TABLE_COLUMNS_REJECTED = ({
  userType,
  isInvoiceMatchingVisible,
}) => {
  const showRaisedByColumn = !isPlatformUser(userType);
  const showInvoiceMatchingColumns = isInvoiceMatchingVisible;
  return [
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber,
      width: showInvoiceMatchingColumns ? (!showRaisedByColumn ? 10 : 8) : 15,
    },
    { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.dueDate, width: 11 },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee,
      width: showInvoiceMatchingColumns ? 10 : 12,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.description,
      width: !showRaisedByColumn
        ? showInvoiceMatchingColumns
          ? 15
          : 20
        : showInvoiceMatchingColumns
        ? 15
        : 20,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.entity,
      width: showInvoiceMatchingColumns ? 10 : 12,
      visible: true,
      align: !showRaisedByColumn ? "center" : "left",
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount,
      width: !showRaisedByColumn ? 8 : 10,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.matchType,
      width: showInvoiceMatchingColumns ? 13 : 11,
      visible: showInvoiceMatchingColumns,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.match,
      width: 5,
      visible: showInvoiceMatchingColumns,
    },
  ];
};

export const INVOICE_MANAGEMENT_TABLE_COLUMNS_IN_REVIEW = ({
  isDev,
  isInvoiceMatchingVisible,
  isStage,
  isProd,
}) => {
  const showInvoiceMatchingColumns = isInvoiceMatchingVisible;
  return [
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber,
      width: showInvoiceMatchingColumns ? 10 : 15,
    },
    { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.dueDate, width: 11 },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee,
      width: showInvoiceMatchingColumns ? 9 : 12,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.description,
      width: showInvoiceMatchingColumns ? 10 : 13,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.entity,
      width: showInvoiceMatchingColumns ? 10 : 12,
      visible: true,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.nextApprover,
      width: showInvoiceMatchingColumns ? 10 : 13,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.workflow,
      width: showInvoiceMatchingColumns ? 10 : 12,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount,
      width: 12,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.matchType,
      visible: showInvoiceMatchingColumns,
      width: showInvoiceMatchingColumns ? 13 : 11,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.match,
      visible: showInvoiceMatchingColumns,
      width: 5,
    },
  ];
};

export const INVOICE_MANAGEMENT_TABLE_COLUMNS_HOLD = ({
  isDev,
  isInvoiceMatchingVisible,
  isStage,
  isProd,
}) => {
  const showInvoiceMatchingColumns = isInvoiceMatchingVisible;
  return isMatchVisibleOnHoldTab()
    ? [
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.previousState,
          width: showInvoiceMatchingColumns ? 17 : 20,
        },
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber,
          width: showInvoiceMatchingColumns ? 14 : 16,
        },
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.dueDate,
          width: showInvoiceMatchingColumns ? 14 : 16,
        },
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee,
          width: showInvoiceMatchingColumns ? 14 : 16,
        },
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.entity,
          width: showInvoiceMatchingColumns ? 14 : 16,
          align: "center",
        },
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount,
          width: showInvoiceMatchingColumns ? 14 : 16,
        },
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.matchType,
          visible: showInvoiceMatchingColumns,
          width: showInvoiceMatchingColumns ? 13 : 0,
        },
      ]
    : [
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.previousState,
          width: 20,
        },
        { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber, width: 16 },
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.dueDate,
          width: 16,
        },
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee,
          width: 16,
        },
        {
          ...INVOICE_MANAGEMENT_TABLE_COLUMNS.entity,
          width: 16,
          align: "center",
        },
        { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount, width: 16 },
      ];
};

export const INVOICE_MANAGEMENT_TABLE_COLUMNS_TO_PAY = ({
  isInvoiceMatchingVisible,
  isPaymentRunVisible,
  isDev,
  isStage,
  isProd,
}) => {
  const showInvoiceMatchingColumns = isInvoiceMatchingVisible;
  return [
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.status,
      width: 9,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber,
      width: showInvoiceMatchingColumns ? 10 : 14,
    },
    { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.dueDate, width: 10 },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee,
      width: showInvoiceMatchingColumns ? 9 : 11,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.description,
      width: showInvoiceMatchingColumns ? 9 : 14,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.entity,
      width: showInvoiceMatchingColumns ? 9 : 11,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.paymentRunName,
      visible: isPaymentRunVisible,
      width: showInvoiceMatchingColumns ? 9 : 13,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount,
      width: showInvoiceMatchingColumns ? 10 : 12,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.matchType,
      visible: showInvoiceMatchingColumns,
      width: showInvoiceMatchingColumns ? 13 : 11,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.erpStatus,
      visible: true,
      width: 8,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.match,
      visible: showInvoiceMatchingColumns,
      width: 4,
    },
  ];
};

export const INVOICE_MANAGEMENT_TABLE_COLUMNS_SCHEDULED = ({
  isDev,
  isInvoiceMatchingVisible,
  isPaymentRunVisible,
  isStage,
  isProd,
}) => {
  const showInvoiceMatchingColumns = isInvoiceMatchingVisible;
  return [
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber,
      width: showInvoiceMatchingColumns ? 15 : 18,
    },
    { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.dueDate, width: 11 },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee,
      width: showInvoiceMatchingColumns ? 10 : 15,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.entity,
      width: showInvoiceMatchingColumns ? 10 : 16,
      visible: true,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.scheduledDate,
      width: 11,
    },

    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.paymentRunName,
      visible: isPaymentRunVisible,
      width: showInvoiceMatchingColumns ? 10 : 14,
    },

    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount,
      width: showInvoiceMatchingColumns ? 13 : 15,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.matchType,
      visible: showInvoiceMatchingColumns,
      width: showInvoiceMatchingColumns ? 15 : 13,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.match,
      visible: showInvoiceMatchingColumns,
      width: 5,
    },
  ];
};

export const INVOICE_MANAGEMENT_TABLE_COLUMNS_PAID = ({
  isDev,
  isInvoiceMatchingVisible,
  isPaymentRunVisible,
  isStage,
  isProd,
}) => {
  const showInvoiceMatchingColumns = isInvoiceMatchingVisible;
  return [
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber,
      width: showInvoiceMatchingColumns ? 8 : 12,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.paymentDate,
      width: showInvoiceMatchingColumns ? 10 : 11,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee,
      width: showInvoiceMatchingColumns ? 8 : 10,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.description,
      width: showInvoiceMatchingColumns ? 8 : 10,
    },
    { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.entity, width: 8 },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.paymentType,
      width: showInvoiceMatchingColumns ? 14 : 18,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.paymentRunName,
      visible: isPaymentRunVisible,
      width: 8,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount,
      width: showInvoiceMatchingColumns ? 8 : 13,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.remittance,
      width: 10,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.matchType,
      visible: showInvoiceMatchingColumns,
      width: showInvoiceMatchingColumns ? 13 : 11,
    },
    {
      ...INVOICE_MANAGEMENT_TABLE_COLUMNS.match,
      visible: showInvoiceMatchingColumns,
      width: 5,
    },
  ];
};

export const IM_BULK_ACTION_KEY = "action";
export const IM_BULK_ACTION_COLUMN = [
  { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.invoiceNumber, width: 20 },
  { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.payee, width: 25 },
  { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.description, width: 25 },
  { ...INVOICE_MANAGEMENT_TABLE_COLUMNS.amount, width: 20 },
  {
    key: IM_BULK_ACTION_KEY,
    width: 10,
  },
];

export const UNMATCH_INFO_COLUMN = "infoColumn";

export const UNMATCH_CLOSED_PO_WARNING =
  "You cannot unmatch this invoice from a closed PO.";

export const IM_UN_MATCH_COLUMN = [
  {
    label: "PO number",
    key: "order_number",
    align: "left",
    width: 15,
  },
  {
    label: "Description",
    key: "description",
    align: "left",
    width: 15,
  },
  {
    label: "Total GRNI amount",
    key: "total_grni_amount",
    align: "right",
    width: 15,
  },
  {
    label: "Amount currently remaining",
    key: "remaining_amount",
    align: "right",
    width: 19,
  },
  {
    label: "Amount to be restored",
    key: "restored_amount",
    align: "right",
    width: 18,
  },
  {
    label: "Amount after restoration",
    key: "afterRestoration",
    align: "right",
    width: 18,
  },
];
export const IM_UN_MATCH_NEW_COLUMN = [
  {
    label: "PO number",
    key: "order_number",
    align: "left",
    width: 14,
  },
  {
    label: "Description",
    key: "description",
    align: "left",
    width: 14,
  },
  {
    label: "Total GRNI amount",
    key: "total_grni_amount",
    align: "right",
    width: 14,
  },
  {
    label: "Amount currently remaining",
    key: "remaining_amount",
    align: "right",
    width: 18,
  },
  {
    label: "Amount to be restored",
    key: "restored_amount",
    align: "right",
    width: 17,
  },
  {
    label: "Amount after restoration",
    key: "afterRestoration",
    align: "right",
    width: 18,
  },
  {
    key: UNMATCH_INFO_COLUMN,
    align: "left",
    width: 5,
    ellipsis: false,
  },
];

export const INVOICE_MANAGEMENT_TABS = [
  {
    key: INVOICE_MANAGEMENT_TAB_KEYS.DRAFT,
    label: "Draft",
    columns: ({ userType, isDev, isInvoiceMatchingVisible, isStage, isProd }) =>
      INVOICE_MANAGEMENT_TABLE_COLUMNS_DRAFT({
        userType,
        isDev,
        isInvoiceMatchingVisible,
        isStage,
        isProd,
      }),
    bulkButtons: IM_SUBMITTED_BULK_BUTTONS,
  },
  {
    key: INVOICE_MANAGEMENT_TAB_KEYS.SUBMITTED,
    label: "Submitted",
    columns: ({ userType, isDev, isInvoiceMatchingVisible, isStage, isProd }) =>
      INVOICE_MANAGEMENT_TABLE_COLUMNS_SUBMITTED({
        userType,
        isDev,
        isInvoiceMatchingVisible,
        isStage,
        isProd,
      }),
    bulkButtons: IM_SUBMITTED_BULK_BUTTONS,
  },
  {
    key: INVOICE_MANAGEMENT_TAB_KEYS.IN_REVIEW,
    label: "In review",
    columns: ({ userType, isDev, isInvoiceMatchingVisible, isStage, isProd }) =>
      INVOICE_MANAGEMENT_TABLE_COLUMNS_IN_REVIEW({
        userType,
        isDev,
        isInvoiceMatchingVisible,
        isStage,
        isProd,
      }),
    bulkButtons: IM_IN_REVIEW_BULK_BUTTONS,
  },
  {
    key: INVOICE_MANAGEMENT_TAB_KEYS.REJECTED,
    label: "Rejected",
    columns: ({ userType, isDev, isInvoiceMatchingVisible, isStage, isProd }) =>
      INVOICE_MANAGEMENT_TABLE_COLUMNS_REJECTED({
        userType,
        isDev,
        isInvoiceMatchingVisible,
        isStage,
        isProd,
      }),
    bulkButtons: IM_REJECTED_BULK_BUTTONS,
  },
  {
    key: INVOICE_MANAGEMENT_TAB_KEYS.HOLD,
    label: "On hold",
    columns: ({ userType, isDev, isInvoiceMatchingVisible, isStage, isProd }) =>
      INVOICE_MANAGEMENT_TABLE_COLUMNS_HOLD({
        userType,
        isDev,
        isInvoiceMatchingVisible,
        isStage,
        isProd,
      }),
  },
  {
    key: INVOICE_MANAGEMENT_TAB_KEYS.TO_PAY,
    label: "To pay",
    columns: ({
      isDev,
      isInvoiceMatchingVisible,
      isPaymentRunVisible,
      isStage,
      isProd,
    }) =>
      INVOICE_MANAGEMENT_TABLE_COLUMNS_TO_PAY({
        isDev,
        isInvoiceMatchingVisible,
        isPaymentRunVisible,
        isStage,
        isProd,
      }),
    bulkButtons: IM_TO_PAY_BULK_BUTTONS,
  },
  {
    key: INVOICE_MANAGEMENT_TAB_KEYS.SCHEDULED,
    label: "Scheduled",
    columns: ({
      userType,
      isDev,
      isInvoiceMatchingVisible,
      isPaymentRunVisible,
      isStage,
      isProd,
    }) =>
      INVOICE_MANAGEMENT_TABLE_COLUMNS_SCHEDULED({
        userType,
        isDev,
        isInvoiceMatchingVisible,
        isPaymentRunVisible,
        isStage,
        isProd,
      }),
    bulkButtons: IM_SCHEDULED_BULK_BUTTONS,
  },
  {
    key: INVOICE_MANAGEMENT_TAB_KEYS.PAID,
    label: "Paid",
    columns: ({
      isDev,
      isInvoiceMatchingVisible,
      isPaymentRunVisible,
      isStage,
      isProd,
    }) =>
      INVOICE_MANAGEMENT_TABLE_COLUMNS_PAID({
        isDev,
        isInvoiceMatchingVisible,
        isPaymentRunVisible,
        isStage,
        isProd,
      }),
  },
];

export const DELETE_INVOICE_CONFIG = {
  BUTTON_CONFIG: {
    text: "Delete invoice",
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    accent: ACCENTS.DESTRUCTIVE,
  },
  SUBTITLE:
    "You are about to delete this invoice. Are you sure you would like to remove this invoice from your platform?",
  SUCCESS_MESSAGE: "Invoice deleted successfully",
};

export const CHANGE_INVOICE_STATUS_CONFIG = {
  HOLD_PLACEHOLDER: "Place on hold",
  PROGRESS_PLACEHOLDER: "Remove from on-hold",
  SUBTITLE:
    "You are about to place this invoice on hold which will prevent this invoice from being assigned to a workflow.",
  TITLE: "Place on hold",
  TO_PAY_SUBTITLE: (paymentRun) =>
    `This has been included in Payment run ${paymentRun}. Placing this invoice on hold will remove this invoice from this payment run.`,
  HOLD_SUCCESS_MESSAGE: "Invoice placed on hold",
  PROGRESS_SUCCESS_MESSAGE: "Invoice removed from on-hold",
  HOLD: "hold",
  PROGRESS: "progress",
};

export const INVOICE_REJECTION_REASON = {
  REJECTION_REASON_LABEL: "Rejection reason",
  REJECTION_REASON_PLACEHOLDER: "Enter rejection reason",
  REJECT_INVOICE_MODAL_TITLE: "Reject invoice",
  REJECT_INVOICE_MODAL_SUBTITLE:
    "Are you sure that you want to reject this request?",
  REJECT_BUTTON_LABEL: "Reject",
};

export const DRAFT_CONFIRMATION_MODAL = {
  title: "Save as draft",
  subTitle:
    "By saving this invoice as a draft, the selected purchase order will be removed from this invoice and the invoice will have no match until it has been submitted. Would you like to continue?",
  confirmButtonLabel: "Save as draft",
  cancelButtonLabel: "Cancel",
};

export const INVOICE_UPLOADING_STATUS = "uploading";

export const CREATE_INVOICE_BTN_DISABLED_TOOLTIP =
  "Please upload a copy of the invoice to proceed";

export const INVOICE_STATUS = {
  HOLD: "hold",
  SUBMITTED: "submitted",
  CANCELLED: "cancelled",
  APPROVED: "approved",
  REJECTED: "rejected",
  PAYMENT_FAILED: "Payment failed",
};

export const REASSIGN_WORKFLOW_JOURNEY = {
  REASSIGN_BUTTON: "Reassign to new workflow",
  UNASSIGN_TITLE: "Unassign from workflow",
  UNASSIGN_CONFIRMATION_SUBTITLE: (workflowName) =>
    `Are you sure you want to unassign this invoice from the ${workflowName} workflow? Once unassigned, this invoice will move back to the submitted tab.`,
  CONFIRM_BTN_LABEL: "Confirm",
  REASSIGN_CONFIRMATION_SUBTITLE: (previousWorkflow, CurrentWorkflow) =>
    `You are about to reassign this invoice from the ${previousWorkflow} workflow to the ${CurrentWorkflow} workflow.`,
  UNASSIGN_WORKFLOW_SUCCESS_MSG: "Workflow unassigned successfully",
  REASSIGN_TITLE: "Reassign to workflow",
  REASSIGN_SUBTITLE:
    "Which workflow would you like to reassign this invoice to?",
};

export const INVOICE_LINE_ITEMS = {
  label: "Item list",
  info: "Do you want to add an item list to this invoice?",
  info2: (isKlooAI, isPO) =>
    `Line items have been generated for this ${
      isPO ? "purchase request" : "invoice"
    }${isKlooAI ? " by Kloo AI" : ""}`,
  errorInfo: "Please complete the line items for this invoice",
  poInfo: "Please complete the line items for this  purchase request",
};

export const LINE_ITEMS_ERRORS = {
  name: "Please enter the name for each unit of this line item",
  cost: "Please enter the cost for each unit of this line item",
  quantity: "Please enter the quantity for each unit of this line item",
  amount: "Please enter the amount for each unit of this line item",
  item: "Please enter the item list",
};

export const IM_AUDIT_LOG_MODAL = {
  TITLE: "Invoice audit log",
  TABLE_COLUMN: [
    { label: "Date and time", key: "date", width: 15 },
    { label: "Actioning user", key: "user", width: 20 },
    { label: "Email", key: "email", width: 25 },
    { label: "Before", key: "before", width: 20 },
    { label: "After", key: "after", width: 20 },
  ],
};

export const NO_WORKFLOW_ID = "no_workflow";

export const ROLE_ATTR_ARRAY = ["role", "attribute"];

export const INVOICE_SUCCESS_MSG_CREATE = `Invoice uploaded`;
export const DRAFT_INVOICE_SUCCESS_MSG_CREATE = `Draft invoice created`;
export const DRAFT_INVOICE_SUCCESS_MSG_UPDATE = `Draft invoice updated`;

export const IM_BULK_ACTION_CONTENT = (actionType) => {
  const contentByType = {
    [BULK_ACTIONS.APPROVE]: {
      title: "Approve invoices",
      subTitle: (invoices) =>
        invoices > 1
          ? `You are about to approve ${invoices} invoices. Once approved, these invoices will be moved to the to pay tab, ready for payment.`
          : "You are about to approve this invoice. Once approved, this invoice will be moved to the to pay tab, ready for payment.",
      primaryBTN: {
        text: "Approve now",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
      },
    },
    [BULK_ACTIONS.REJECT]: {
      title: "Reject invoices",
      subTitle: (invoices) =>
        invoices > 1
          ? `You are about to reject ${invoices} invoices. Once these have been rejected, these invoices will be moved to the rejected tab.`
          : "You are about to reject this invoice. Once this has been rejected, this invoice will be moved to the rejected tab.",
      primaryBTN: {
        text: "Reject",
        accent: ACCENTS.DESTRUCTIVE,
        variant: BUTTON_VARIANT.BUTTON_HOLLOW,
      },
    },
    [BULK_ACTIONS.DELETE]: {
      title: "Delete invoices",
      subTitle: (invoices) =>
        invoices > 1
          ? `You are about to delete ${invoices} invoices. Please note that deleted invoices can be re-submitted`
          : "You are about to delete this invoice. Please note that deleted invoices can be re-submitted.",
      primaryBTN: {
        text: "Confirm",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
      },
    },
    [BULK_ACTIONS.PLACE_ON_HOLD]: {
      title: "Place invoices on hold",
      subTitle: (invoices, isToPayTab) =>
        invoices > 1
          ? `You are about to place ${invoices} invoices on hold which will prevent these invoices from being ${
              isToPayTab ? "processed for payment." : "assigned to a workflow."
            }`
          : `You are about to place this invoice on hold which will prevent this invoice from being ${
              isToPayTab ? "processed for payment." : "assigned to a workflow."
            }`,
      primaryBTN: {
        text: "Place on hold",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
      },
    },
    [BULK_ACTIONS.MARK_AS_PAID]: {
      title: "Mark as paid",
      subTitle: (invoices) =>
        invoices > 1
          ? `You are about to mark ${invoices} invoices as paid suggesting that your organisation has paid this invoice off-platform.`
          : "You are about to mark this invoice as paid suggesting that your organisation has paid this invoice off-platform.",
      primaryBTN: {
        text: "Mark as paid",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
      },
    },
    [BULK_ACTIONS.CANCEL]: {
      title: "Cancel payments",
      subTitle: (invoices) =>
        invoices > 1
          ? ` You are about to cancel the payment of ${invoices} invoices. Are you sure you would like to continue?`
          : "You are about to cancel the payment of this invoice. Are you sure you would like to continue?",
      primaryBTN: {
        text: "Confirm",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
      },
    },
    [BULK_ACTIONS.REMOVE_FROM_HOLD]: {
      title: "Remove from on hold",
      subTitle: (invoices) =>
        invoices > 1
          ? `You are about to remove ${invoices} invoices from on hold. Removing these invoices will return them back to their previous status.`
          : "You are about to remove this invoice from on hold. Removing this invoice will return it back to its previous status.",
      primaryBTN: {
        text: "Confirm",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
      },
    },
    [BULK_ACTIONS.ASSIGN_TO_WORKFLOW]: {
      title: "Assign to workflow",
      subTitle: (invoices) =>
        `Which workflow would you like to assign ${
          invoices > 1 ? "these" : "this"
        } invoice${invoices > 1 ? "s" : ""} to?`,
      primaryBTN: {
        text: "Confirm",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
      },
    },
    [BULK_ACTIONS.REASSIGN_TO_WORKFLOW]: {
      title: "Reassign to workflow",
      subTitle: (invoices) =>
        `You are about to reassign ${invoices > 1 ? "these" : "this"} invoice${
          invoices > 1 ? "s" : ""
        } to another workflow. Which workflow would you like to reassign ${
          invoices > 1 ? "these" : "this"
        } invoice${invoices > 1 ? "s" : ""} to?`,
      primaryBTN: {
        text: "Confirm",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
      },
    },
    [BULK_ACTIONS.UNASSIGN_TO_WORKFLOW]: {
      title: "Unassign to workflow",
      subTitle: (invoices) =>
        `You are about to unassign the following invoice${
          invoices > 1 ? "s" : ""
        } from their workflows. Once unassigned, ${
          invoices > 1 ? "these" : "this"
        } invoice${
          invoices > 1 ? "s" : ""
        } will move back to the submitted tabs.`,
      primaryBTN: {
        text: "Confirm",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
      },
    },
  };

  return contentByType[actionType];
};

export const INVOICE_GENERATED_USING_AI =
  "This invoice has been generated by Kloo AI";

export const ai_alert = (isLoading, isTitle) => {
  const title = isLoading
    ? "Kloo AI is generating this invoice..."
    : "This invoice has been generated by Kloo AI";
  const subTitle = isLoading
    ? "Please wait whilst Kloo AI generates this invoice from the document that has been uploaded."
    : "Kloo AI continuously learns to create accurate invoices. Please double check important details before payment.";
  return isTitle ? title : subTitle;
};

export const ITEM_TABLE_COLUMN = [
  { label: "Item name", key: "name", width: 40 },
  { label: "Quantity", key: "quantity", width: 15 },
  { label: "Unit cost", key: "cost", width: 20, align: "right" },
  { label: "Amount", key: "amount", width: 25, align: "right" },
];

export const TOOLTIP_BG_COLOR = "#33495F";
export const CUSTOM_FIELD_BY_AI_RESP = {
  result: [
    {
      custom_fields: [
        {
          id: "298bb8d9-ca4d-4b6c-b7f9-af32f67d9651",
          custom_field_id: "298bb8d9-ca4d-4b6c-b7f9-af32f67d9651",
          value: "aba5fc2e-ed0c-48aa-98db-c0f355c69c07",
        },
        {
          id: "7676454e-4186-4a09-8bb4-da4dbfc88675",
          custom_field_id: "678bb8d9-ca4d-4b6c-b7f9-af32f67d9662",
          value: "hgn5eb84-8451-4a79-9e60-2aad6a950j89",
        },
      ],
      custom_category: [
        {
          id: "8786754e-4186-4a09-8bb4-da4dbfc8yu67",
          value: "hgn5eb84-8451-4a79-9e60-2aad6a950j89",
        },
      ],
    },
  ],
};

export const NO_WORKFLOW_OPTION = {
  id: "no_workflow",
  workflow_name: "No workflow",
};

export const BULK_FAILED_INFO =
  "Due to an error, the remaining invoices have not been actioned. Please try again.";

export const KLOO_INSIGHT = "Kloo insight";

export const IN_LINE_ITEM_SPLIT_VIEW_COLUMN = [
  { label: "Item name", key: "name", width: 45 },
  { label: "Amount", key: "amount", width: 45, align: "right" },
];

export const MULTIPLE_PO_DROPDOWN_OPTION = {
  label: "Multiple purchase orders",
  value: MULTIPLE_PO_DROPDOWN_OPTION_ID,
};

export const WARNING_MESSAGES = {
  multiPOCreation:
    "By selecting this option, you are suggesting that this invoice will be split across multiple purchase orders. After creating the invoice, you will need to manually distribute it among these purchase orders",
  multiPOEdit:
    "Choosing this option will mark the invoice as 'No match' until it's split among multiple purchase orders.",
  amountEdit:
    "Since this invoice has been matched with one or more purchase orders, changing the net amount will result in this invoice being unmatched from these purchase orders.",
  nonKlooPO: "Invoice cannot be submitted with the non-Kloo PO number.",
  entityChange:
    "By changing the entity of this invoice, it will be unmatched from its current purchase order and will require rematching.",
};

export const PO_ERROR_MSG = {
  nonKlooPO: "This PO number does not match a Kloo PO",
  closedPO:
    "The selected PO is closed and therefore cannot be matched to this invoice",
  inSufficientAmountForDetected:
    "A Kloo PO number was detected, but does not have a sufficient remaining amount to complete this match",
  inSufficientAmount:
    "The selected PO does not have a sufficient remaining amount to complete this match",
};

export const IM_CREATE_INVOICE_FORM = {
  currency: {
    name: "currency",
    label: "Currency",
    rules: IM_CREATE_INVOICE_FORM_RULE.CURRENCY,
  },
  netAmount: {
    name: "netAmount",
    label: "Net amount",
    placeholder: "Net amount",
    min: 0,
    precision: 2,
    controls: false,
    rules: IM_CREATE_INVOICE_FORM_RULE.NET_AMOUNT,
    aiKey: "net_amount",
    suffixTooltipList: [
      {
        suffixIconTooltip: TOOL_TIP_TEXT.netAmount,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
    ],
  },
  taxAmount: {
    name: "taxAmount",
    label: "Tax amount",
    placeholder: "Tax amount",
    min: 0,
    precision: 2,
    controls: false,
    aiKey: "tax_amount",
    rules: IM_CREATE_INVOICE_FORM_RULE.TAX_AMOUNT,
    suffixTooltipList: [
      {
        suffixIconTooltip: TOOL_TIP_TEXT.taxAmount,
        suffixIconClassName: "",
        suffixIcon: "info",
      },
    ],
  },
  chartOfAccounts: {
    newSelect: true,
    name: "chartOfAccounts",
    placeholder: "Account",
    labelText: "Chart of accounts",
    // rules: IM_CREATE_INVOICE_FORM_RULE.CHART_OF_ACCOUNTS,
  },
  taxRate: {
    newSelect: true,
    name: "taxRate",
    placeholder: "Tax rate",
    labelText: "Tax rate",
    // rules: IM_CREATE_INVOICE_FORM_RULE.TAX_RATE,
  },
  itemList: {
    newSelect: true,
    name: "itemList",
    placeholder: "Item",
    labelText: "Item",
  },
  trackingCategories: {
    newSelect: true,
    name: "trackingCategories",
    placeholder: "Tracking categories",
    labelText: "Tracking categories",
    isMultiSelect: true,
    mode: "multiple",
  },
};

export const SUPPLIER_FORM_NAME = "supplierId";

export const GEN_AI_IM_TRIGGER_MAPPING = [
  {
    mappingKey: PURCHASE_ORDER_CREATE_FROM.entity.label,
    value: PURCHASE_ORDER_CREATE_FROM.entity.name,
    aiMappingKey: PURCHASE_ORDER_CREATE_FROM.entity.name,
  },
  {
    mappingKey: PURCHASE_ORDER_CREATE_FROM.FORM_LABELS.supplier,
    value: SUPPLIER_FORM_NAME,
    aiMappingKey: TRIGGER_SUPPLIER_ID,
  },
  {
    mappingKey: INVOICE_FORM.TAX_CODE.label,
    value: INVOICE_FORM.TAX_CODE.name,
    aiMappingKey: "tax_code_id",
  },
];

export const CREDIT_NOTE_IM_CONFIG = {
  LISTING_TOOLTIP: {
    TITLE: ({ hasMultipleCreditNote }) => {
      return {
        text: `${
          hasMultipleCreditNote
            ? "Credit notes have been applied"
            : "A credit note has been applied"
        }`,
        variant: "body",
        textColorType: "white",
        fontSize: 16,
      };
    },
  },
  TITLE: ({ hasMultipleCreditNote }) => {
    return {
      text: `Credit note${hasMultipleCreditNote ? "s" : ""}`,
      variant: "secondary",
      fontStyle: "semibold",
      fontSize: 16,
    };
  },
  APPLIED_SUBTITLE: ({ hasMultipleCreditNote }) => {
    return {
      text: `The following credit note${
        hasMultipleCreditNote ? "s have" : " has"
      } been applied to this invoice`,
      variant: "body",
      fontSize: 12,
      className: "px-3 p-b-12px",
    };
  },
  TOTAL_CREDIT_NOTE_AMOUNT: ({ amount }) => {
    return {
      helperText: "Total credit note amount:",
      value: amount,
    };
  },
  SUMMARY: {
    TITLE: ({ hasMultipleCreditNote }) => {
      return {
        text: `After deducting the credit note${
          hasMultipleCreditNote ? "s" : ""
        } mentioned above, the total amount due is calculated as follows:`,
        variant: "body",
        fontSize: 12,
        className: "m-3",
      };
    },
    HELPER_TEXT_ARR: [
      {
        helperText: "Original invoice amount due",
        key: "actual_amount",
      },
      {
        helperText: "Total allocated credit amount",
        key: "total_applied_amount",
        valueFontType: "destructive",
      },
      {
        helperText: "Amount due",
        key: "invoice_amount",
      },
    ],
  },
};

export const CREDIT_NOTE_TABLE_COLUMN = [
  {
    label: "Credit note number",
    key: "credit_note_number",
    width: 40,
  },
  { label: "Credit note date", key: "credit_note_date", width: 25 },
  {
    label: "Amount",
    key: "applied_amount",
    align: "right",
    width: 35,
  },
];
export const INVOICE_SPLIT_CONSTANTS = {
  title: "Invoice splitting",
  poTableTitle:
    "1. Choose purchase orders to split the selected invoice across",
  selectedPOTableTitle:
    "2. Determine invoice split across selected purchase orders",
  closedPOTooltip:
    "You cannot unmatch this PO or change the allocated amount as this PO is closed.",
  closedStatusPO: "closed",
};

export const INVOICE_SINGLE_SPLIT_CONSTANTS = {
  pageTitle: "Invoice matching",
  klooAITitle: "KlooAI :",
  suggestedPOTitle:
    "The following purchase order has been suggested as a match",
  manualPOWhenSuggestedPresentTitle:
    "Alternatively, select a purchase order to match this invoice with",
  manualPOWhenSuggestedAbsentTitle:
    "Select a purchase order to match this invoice with",
};

export const INVOICE_MANAGEMENT_EMAIL_UPLOAD = {
  BUTTON: {
    accent: ACCENTS.SECONDARY,
    variant: BUTTON_VARIANT.BUTTON_HOLLOW,
    customSize: SIZE.LARGE,
    text: "Email upload with Kloo AI",
  },
  MODAL: {
    title: "Email upload with Kloo AI",
    subTitle:
      "Upload invoices directly from your mailbox with Kloo AI. Simply forward invoices to the email address below for automated upload to Kloo.",
    mandatoryCheckboxConfirmation: false,
    showButtons: false,
    showCloseIcon: true,
  },
  SEARCh_FIELD: {
    placeholder: "Search invoices",
    className: "historySearch",
    customSize: "large",
    id: "searchInvoices",
  },
};

export const INVOICE_UPDATE_MODAL_WARNING = {
  title: "Update invoice",
  bodyText:
    "As this invoice is being reviewed, making these edits will require re-approval from users who may have already actioned it. Please confirm to continue with the changes.",
  CONFIRM_BTN_LABEL: "Continue",
};

export const disabledMatchingBtnToolTip = (po, isMulti) =>
  `You cannot rematch or unmatch this invoice as it is matched to ${po} which ${
    isMulti ? "are" : "is"
  } closed.`;

export const disabledUnMatchBtnToolTip = (po, isMulti) =>
  `You cannot unmatch this invoice as it matched to ${po} which ${
    isMulti ? "are" : "is"
  } closed`;

export const disabledPONumberForEntity =
  "Please select an entity before selecting a purchase order number";

export const TRACKING_CATEGORY_KEY_PREFIX = "trackingCategory_";

export const IM_AMOUNT_MSG_FOR_CLOSED_PO =
  "You cannot decrease the invoice amount as it is matched with a closed PO.";

export const OCR_LINE_ITEMS = {
  GENERATE: "Generate",
  REGENERATE: "Regenerate",
  toolTipTitle: (type = "Generate") => `${type} line items with Kloo AI`,
  loaderText: "your line items",
  SUCCES_MSG: "Line items generated by Kloo AI",
  ERROR_MSG:
    "Line item generation failed. Please try again or manually enter data.",
};

export const BUTTON_CONFIG_TYPE = {
  displayMode: "displayMode",
  editMode: "editMode",
};

export const ERP_STATUS = {
  pending: "pending",
  success: "success",
  failed: "failed",
};

export const ERP_STATUS_TOOLTIP = {
  pending: "ERP sync in progress",
  success: "ERP sync completed",
  failed: "Error with sync to ERP",
};

export const ERP_STATUS_ICON = {
  pending: "cloud_sync",
  success: "cloud_done",
  failed: "sync_problem",
};

export const ENTITY_CLOSED_PO_TP_MSG =
  "The entity cannot be edited because this invoice has been matched to a closed purchase order.";

export const TAX_RATE_STATUS = {
  active: "active",
};

export const RESEND_EMAIL_APPROVAL_SUCCESS_AP =
  "Invoice approval emails resent";

export const TAX_SOLUTION_NO_TAX_LABEL = "No Tax";

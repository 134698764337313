import { mapCategoryPageWise } from "../../helper";
import { NOTIFICATION_TYPE, showNotification } from "../../utils/notifications";
import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import {
  CUSTOM_GET_EXPENSES,
  DELETE_CUSTOM_FIELD_OPTION,
  Edit_CUSTOM_FIELD_NAME,
  GET_ACCOUNT_CUSTOM,
  GET_PREDEFINE_LIST,
  TRACKING_CATEGORIES,
  TRACKING_CATEGORIES_MAP,
  UPDATE_CUSTOM_FIELD,
} from "../service/types";
import { loadingStart, loadingStop } from "./loader";

export const trackingcategories = (avoidLoader) => (dispatch) => {
  if (!avoidLoader) {
    dispatch(loadingStart());
  }
  return new Promise((resolve, reject) => {
    apiClient({
      url: PATH.expense_field.getListTraking,
      method: "GET",
    })
      .then((res) => {
        resolve(res);
        dispatch({
          type: TRACKING_CATEGORIES,
          payload: res?.data?.result,
        });
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const getValidationList = (page_name, avoidLoader) => (dispatch) => {
  if (!avoidLoader) {
    dispatch(loadingStart());
  }
  return new Promise((resolve, reject) => {
    apiClient({
      url: PATH.expense_field.getValidationList(page_name),
      method: "GET",
    })
      .then((res) => {
        resolve(res);
        dispatch({
          type: TRACKING_CATEGORIES_MAP,
          payload: res?.data?.result,
        });
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const saveTracking =
  (data, showToast = true) =>
  (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        url: PATH.expense_field.saveTrackingList(),
        method: "POST",
        data,
      })
        .then((res) => {
          resolve(res);
          if (showToast) {
            showNotification(
              NOTIFICATION_TYPE.success,
              "Record saved successfully"
            );
          }
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };

//-------   custom Expenses Field    ----------------///

export const createExpensesFileld = (data, page_name) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      url: PATH.custom_expenses.createExpensesFileld(page_name),
      method: "POST",
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const updateExpensesFileld =
  (data, pageType, change_col_status) => (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        url: PATH.custom_expenses.updateCustomValidation(
          pageType,
          change_col_status
        ),
        method: "POST",
        data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };

export const customExpenseGetList =
  (categoryType, pageType = "", avoidLoader = false) =>
  (dispatch) => {
    if (!avoidLoader) {
      dispatch(loadingStart());
    }
    return new Promise((resolve, reject) => {
      apiClient({
        url: PATH.custom_expenses.customExpenseGetList(categoryType, pageType),
        method: "GET",
      })
        .then((res) => {
          // const filterResp = mapCategoryPageWise(res, pageType);
          resolve(res);
          dispatch({
            type: CUSTOM_GET_EXPENSES,
            payload: res?.data?.result || [],
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: CUSTOM_GET_EXPENSES,
            payload: [],
          });
        })
        .finally(() => {
          if (!avoidLoader) dispatch(loadingStop());
        });
    });
  };
export const getCustomFieldValuesList =
  (id, type, key, data, isDelete, avoidLoader, newAction) => (dispatch) => {
    if (!avoidLoader) {
      dispatch(loadingStart());
    }
    return new Promise((resolve, reject) => {
      apiClient({
        url: PATH.custom_expenses.getCustomFieldValues(id, type),
        method: "GET",
      })
        .then((res) => {
          resolve(res);
          if (newAction) {
            dispatch(
              updateCustomSelectFields({
                type,
                name: key,
                data: res?.data?.result,
              })
            );
            return;
          }
          let CustomPayload = {};
          if (data) {
            if (Object.keys(data).includes(type)) {
              CustomPayload = {
                ...data,
                [type]: { ...data[type], [key]: res?.data?.result },
              };
            } else {
              CustomPayload = { ...data, [type]: { [key]: res?.data?.result } };
            }
          } else {
            CustomPayload = { ...data, [type]: { [key]: res?.data?.result } };
          }
          dispatch({
            type: GET_PREDEFINE_LIST,
            payload: CustomPayload || {},
          });
        })
        .catch((err) => {
          reject(err);
          let CustomPayload = {};
          if (isDelete && data[type] && data[type][key]?.length === 1) {
            CustomPayload = { ...data, [type]: { [key]: {} } };
            dispatch({
              type: GET_PREDEFINE_LIST,
              payload: CustomPayload,
            });
          }
          if (!err?.response?.data?.result?.length) {
            dispatch(
              deleteCustomSelectField({
                type,
                name: key,
              })
            );
          }
        })
        .finally(() => {
          if (!avoidLoader) dispatch(loadingStop());
        });
    });
  };

//---------- delete field value first page-----------------------//
export const deleteExpenseField = (userid) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      url: PATH.custom_expenses.deleteExpenseField(userid),
      method: "PATCH",
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

//-----------create Predefinelist ----------///
export const createManageCustomField = (id, createUrl, data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      url: PATH.custom_expenses.createManageCustom(id, createUrl),
      method: "POST",
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

//  update data predifinelist ////
export const UpadetePredefinePerent = (userid, data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      url: PATH.custom_expenses.UpadetePredefinePerent(userid),
      method: "PUT",
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

// -------------- create child list ---------- //
export const createChildlist = (userId, parentId, type, data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      url: PATH.custom_expenses.createChildlist(userId, parentId, type),
      method: "POST",
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

// --------   delete perent child list --- ////
export const deleteCustomList = (id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      url: PATH.custom_expenses.deleteCustomFiled(id),
      method: "DELETE",
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

//----------------   data list custom account payable -------------------------///
export const AllGetDAtaAccountCustom = (type) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      url: PATH.custom_expenses.AllGetDAtaAccountCustom(type),
      method: "GET",
    })
      .then((res) => {
        resolve(res);
        dispatch({
          type: GET_ACCOUNT_CUSTOM,
          payload: res?.data?.result || [],
        });
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch(loadingStop()));
  });
};

export const updateCustomSelectFields = (payload) => ({
  type: UPDATE_CUSTOM_FIELD,
  payload,
});

export const deleteCustomSelectField = (payload) => ({
  type: DELETE_CUSTOM_FIELD_OPTION,
  payload,
});

export const editCustomFieldName = (payload) => ({
  type: Edit_CUSTOM_FIELD_NAME,
  payload,
});

import { Dropdown } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { CustomButton, TextField, Checkbox, Typography, Box, When } from "..";
import {
  ACCENTS,
  BUTTON_VARIANT,
  AUTOMATION_CONSTANT,
  REMAINING_OPTION,
} from "../../../constants";
import "./add-mapping-dropdown.scss";

const AddMappingDropdown = ({
  items = [],
  selectedOption,
  setSelectedOptions,
  onAdd,
  savedSelectedOption,
  setSavedSelectedOptions,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState();
  const [selectAllMapping, setSelectAllMapping] = useState(false);
  const [OldSelectMapping, setOldSelectMapping] = useState([]);

  const handleOpenChange = (flag) => {
    setOpen(flag);
    if (flag && selectedOption.length) {
      setSelectedOptions(savedSelectedOption);
    }
  };

  const handleSelection = (item) => {
    if (selectedOption.includes(item)) {
      const checkOption = selectedOption.filter((option) => option !== item);
      setSelectedOptions([...checkOption]);
      setSavedSelectedOptions([...checkOption]);
      if (selectedOptionApi(checkOption) !== items.length) {
        setSelectAllMapping(false);
      }
    } else {
      const checkOption = [...selectedOption, item];
      if (selectedOptionApi(checkOption) == items.length) {
        setSelectAllMapping(true);
      }
      setSelectedOptions([...selectedOption, item]);
      setSavedSelectedOptions([...selectedOption, item]);
    }
    handleAdd();
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedOptions(savedSelectedOption);
  };

  useEffect(() => {
    setSelectAllMapping(false);
  }, [items]);

  useEffect(() => {
    if (selectedOptionApi(selectedOption) === items?.length) {
      setSelectAllMapping(true);
    }
    setOldSelectMapping([...selectedOption]);
  }, []);
  useEffect(() => {
    if (!search?.length && items.length !== selectedOptionApi(selectedOption)) {
      setSelectAllMapping(false);
    }
  }, [search]);
  const withFilterOptions = useMemo(() => {
    if (search?.length) {
      return items.filter((item) =>
        item.label.toLowerCase().includes(search.toLowerCase())
      );
    }
    return items;
  }, [search, items]);

  const selectedOptionApi = (array) => {
    const addRemaining =
      array &&
      array.length > 0 &&
      array.some((field) => field == REMAINING_OPTION?.value);
    if (addRemaining) {
      return array?.length - 1;
    } else {
      return array?.length;
    }
  };
  const handleSelectAll = () => {
    if (search?.length) {
      handleTestMapping(
        selectedOptionApi(selectedOption) -
          selectedOptionApi(OldSelectMapping) !==
          withFilterOptions?.length && !selectAllMapping,
        selectedOption
      );
    } else {
      handleTestMapping(
        items?.length !== selectedOptionApi(selectedOption),
        OldSelectMapping
      );
    }
    handleAdd();
  };

  const handleTestMapping = (condition, array) => {
    if (condition) {
      setSelectAllMapping(true);
      const selectAllOption = [];
      withFilterOptions &&
        withFilterOptions?.forEach((item) => {
          if (!OldSelectMapping.includes(item.value)) {
            selectAllOption.push(item.value);
          }
        });
      setSelectedOptions([...array, ...selectAllOption]);
      setSavedSelectedOptions([...array, ...selectAllOption]);
    } else {
      setSelectAllMapping(false);
      setSelectedOptions([...OldSelectMapping]);
      setSavedSelectedOptions([...OldSelectMapping]);
    }
  };
  let menuItems = useMemo(() => {
    const menus = [];
    withFilterOptions.forEach((item) => {
      if (item.value !== REMAINING_OPTION.value) {
        menus.push({
          key: item.value,
          label: (
            <Checkbox
              onClick={() => handleSelection(item.value)}
              checked={selectedOption?.includes(item.value)}
              disabled={OldSelectMapping?.includes(item.value)}
            >
              <Typography
                variant="body"
                text={item?.label}
                fontStyle={item?.value === "remaining_values" ? "semibold" : ""}
                disabled={OldSelectMapping?.includes(item.value)}
              />
            </Checkbox>
          ),
          disabled: false,
        });
      }
    });
    return menus;
  }, [withFilterOptions, selectedOption, OldSelectMapping]);

  const handleAdd = () => {
    onAdd();
  };

  const checkboxChange = (selectedOption) => {
    if (0 !== selectedOptionApi(selectedOption)) {
      return selectedOptionApi(selectedOption) !== items?.length
        ? "checked-inner-change"
        : "";
    }
    return "";
  };
  return (
    <Dropdown
      open={open}
      trigger={["click"]}
      menu={{
        items: menuItems,
      }}
      onOpenChange={handleOpenChange}
      getPopupContainer={(trigger) => trigger.parentNode}
      dropdownRender={(menu) => (
        <div className="add-mapping-container">
          <TextField className="mapping-search" onChange={setSearch} />
          <Box justifyContent="flexStart" className="m-l-12px mb-1 mt-2  ">
            <When condition={withFilterOptions?.length}>
              <Checkbox
                onClick={() => handleSelectAll()}
                checked={selectAllMapping}
                disabled={selectedOptionApi(OldSelectMapping) === items?.length}
              >
                <Typography
                  variant="body"
                  text={`Selected : ${selectedOptionApi(selectedOption)}`}
                  fontStyle="semibold"
                />
              </Checkbox>
            </When>
          </Box>
          {React.cloneElement(menu)}
        </div>
      )}
    >
      <CustomButton
        text={AUTOMATION_CONSTANT.BTN_LABELS.ADD_MAPPING}
        accent={ACCENTS.PRIMARY}
        variant={BUTTON_VARIANT.BUTTON_FILLED}
        className="drop-down-button"
      />
    </Dropdown>
  );
};

export default AddMappingDropdown;

import {
  AGILISYS_ORG_IDS_WITH_DEV,
  ASSIGNMENT_TYPES,
  PURCHASE_ORDER_TABS_KEYS,
  featureFlags,
} from "../../constants";
import {
  isDevelopmentEnvironment,
  isStageEnvironment,
  isPlatformEnvironment,
  getItem,
  moneyFormatter,
} from "../../utils";
import { PAYMENT_RUNS } from "../../constants/PaymentRuns";
import { getPurchaseOrderCreatorsList } from "../../api";

export function getPONumberFilterURL({ poNumberArray }) {
  return `&filter=whereIn:order_number~${poNumberArray.join("|")}`;
}

export function transformSplitListingDataToMatchListingData({ response }) {
  response.list = response.list.map(
    ({
      id,
      description,
      order_number,
      supplier_name,
      currency,
      total_grni_amount,
      net_amount,
      formattedRemainingAmount,
      formattedGrossAmount,
      status,
    }) => {
      return {
        description,
        id,
        order_number,
        supplier_name,
        currency: currency.currency,
        currency_id: currency.id,
        symbol: currency.symbol,
        total_grni_amount,
        remaining_amount: formattedRemainingAmount,
        net_amount: moneyFormatter(net_amount, 2, currency.currency),
        total_amount: formattedGrossAmount,
        status,
      };
    }
  );
  return response;
}

export const checkIfUserCanCreatePO = async () => {
  const loggedInUserOrgId = localStorage.getItem("user_org_id");
  let moduleName = PAYMENT_RUNS.PR_CREATOR_MODULE_NAME;
  try {
    const response = await getPurchaseOrderCreatorsList(moduleName);
    if (response?.result?.length) {
      if (
        response?.result?.find((user) => user?.user_id === loggedInUserOrgId)
      ) {
        return false;
      } else {
        return true;
      }
    }
  } catch (error) {}
};

export const allItemsSameERPItemId = (billOfMaterials = []) => {
  if (billOfMaterials.length === 0) {
    return null;
  }
  const firstItemId = billOfMaterials[0].erp_item_id;
  for (let item of billOfMaterials) {
    if (item.erp_item_id !== firstItemId) {
      return null;
    }
  }
  return firstItemId;
};

export const getAutomationForItemsId = (payload) => {
  payload = {
    ...payload,
    bill_of_materials: JSON.parse(payload.bill_of_materials),
  };
  return allItemsSameERPItemId(payload?.bill_of_materials);
};

export const getAutomationForItemsIdFromPoDetails = (payload) => {
  return allItemsSameERPItemId(payload?.bill_of_materials);
};

export function getWorkflowByTrigger({
  isRequiredTriggerField,
  isRequiredERPTriggerField,
  payloadJson,
  poDetails,
  poTriggerField,
  customFieldsV2,
  form,
  mappedAutomation,
}) {
  let previousWorkflowId = null,
    newWorkflowId = null;
  if (isRequiredTriggerField) {
    // Trigger in required fields like supplier, owner
    previousWorkflowId = getTriggerHasWorkflowMappingV2({
      data: poDetails,
      type: "default",
      mappedAutomation,
    });
    newWorkflowId = getTriggerHasWorkflowMappingV2({
      data: {
        ...payloadJson,
        owner_id: payloadJson.owner,
      },
      type: "default",
      mappedAutomation,
    });
  } else if (isRequiredERPTriggerField && isAutomationEnabledForItemsPO()) {
    const erp_line_item_automation_current =
      getAutomationForItemsId(payloadJson);
    const erp_line_item_automation_previous =
      getAutomationForItemsIdFromPoDetails(poDetails);
    // Trigger in required fields like supplier, owner
    previousWorkflowId = getTriggerHasWorkflowMappingV2({
      data: erp_line_item_automation_previous,
      type: "erp_fields",
      mappedAutomation,
    });
    newWorkflowId = getTriggerHasWorkflowMappingV2({
      data: erp_line_item_automation_current,
      type: "erp_fields",
      mappedAutomation,
    });
  } else {
    // Trigger in Custom fields
    const triggerField = customFieldsV2?.default.find(
      (fields) => fields.label === poTriggerField
    );
    if (triggerField) {
      previousWorkflowId = getTriggerHasWorkflowMappingV2({
        data: triggerField.user_input,
        type: "custom_fields",
        mappedAutomation,
      });
      newWorkflowId = getTriggerHasWorkflowMappingV2({
        data: form.getFieldValue(triggerField.custom_field_id),
        type: "custom_fields",
        mappedAutomation,
      });
    }
  }
  return {
    previousWorkflowId,
    newWorkflowId,
  };
}

export function getTriggerHasWorkflowMappingV2({
  data,
  type,
  mappedAutomation,
}) {
  let workflowId = null;
  const triggerField = mappedAutomation.mapped_fields.find((field) => {
    if (type === "custom_fields" || type === "erp_fields") {
      return field.field_value_id === data;
    } else {
      return (
        field.field_value_id === data.po_supplier_id ||
        field.field_value_id === data.owner_id
      );
    }
  });
  if (triggerField?.workflow_id) {
    workflowId = triggerField?.workflow_id;
  }
  if (!triggerField) {
    const triggerFoundInRemaining = mappedAutomation.mapped_fields.find(
      (field) =>
        field.field_value_id === "remaining_values" && field.workflow_id
    );
    if (triggerFoundInRemaining) {
      workflowId = triggerFoundInRemaining?.workflow_id;
    }
  }
  return workflowId;
}

export function getModalAssignmentType({
  previousWorkflowId,
  newWorkflowId,
  activeKey,
}) {
  if (activeKey === PURCHASE_ORDER_TABS_KEYS.IN_REVIEW) {
    if (previousWorkflowId === newWorkflowId) {
      return ASSIGNMENT_TYPES.RESET;
    } else if (newWorkflowId) {
      return previousWorkflowId
        ? ASSIGNMENT_TYPES.REASSIGN
        : ASSIGNMENT_TYPES.ASSIGN;
    } else {
      return previousWorkflowId ? ASSIGNMENT_TYPES.UNASSIGN : "";
    }
  } else {
    return newWorkflowId ? ASSIGNMENT_TYPES.ASSIGN : "";
  }
}

export function grniAvailableMatchingValue({
  props: { currencyData, totalAmountRef },
}) {
  let totalGrniAvailable = 0;
  const total =
    totalAmountRef?.valueGoodsReceived -
    totalAmountRef?.totalMatchedInvoiceAmount;
  totalGrniAvailable = moneyFormatter(
    total < 0 ? 0 : total,
    2,
    currencyData?.currency
  );
  return totalGrniAvailable;
}

export function isAgilisysOrgForPO() {
  const ordId = getItem("org_id");
  return AGILISYS_ORG_IDS_WITH_DEV.includes(ordId);
}

export const isLessThanFivePercent = (amount, totalAmount) => {
  const fivePercentOfTotal = totalAmount * 0.05;
  return amount < fivePercentOfTotal;
};

export function isAIEmailModalEnabled() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.purchaseOrder.aiEmailModal.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.purchaseOrder.aiEmailModal.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.purchaseOrder.aiEmailModal.enableForProd)
  );
}

export function resetWorkflowAutomation() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.purchaseOrder.resetWorkflow_9890.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.purchaseOrder.resetWorkflow_9890.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.purchaseOrder.resetWorkflow_9890.enableForProd)
  );
}

export function isAutomationEnabledForItemsPO() {
  return (
    (isDevelopmentEnvironment() &&
      featureFlags.purchaseOrder.itemsAutomationEnabled_11922.enableForDev) ||
    (isStageEnvironment() &&
      featureFlags.purchaseOrder.itemsAutomationEnabled_11922.enableForStage) ||
    (isPlatformEnvironment() &&
      featureFlags.purchaseOrder.itemsAutomationEnabled_11922.enableForProd)
  );
}

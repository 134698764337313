import {
  INVOICE_LINE_ITEMS,
  TRACKING_CATEGORY_KEY_PREFIX,
} from "../../constants";
import {
  ERP_INTEGRATIONS_PAGE_CONFIG,
  ERP_LINE_ITEM_ORGS,
} from "../../constants/Integration/OpenBanking";
import { LINE_ITEM_TABLE_COLUMNS_KEY } from "../../constants/LineItems";
import {
  LOCAL_STORAGE_KEY,
  getItem,
  isPlatformEnvironment,
  moneyFormatter,
} from "../../utils";

export const calculateNetAmount = (
  unit_cost = 0,
  quantity = 0,
  tax_rate = 0,
  withoutTax = false
) => {
  const amountWithOutTax = Number(unit_cost) * Number(quantity);
  if (withoutTax) {
    return Number(amountWithOutTax.toFixed(2));
  }
  const net_amount =
    amountWithOutTax + Number((amountWithOutTax * tax_rate) / 100);
  return Number(net_amount.toFixed(2));
};

export const getTaxRateFromName = (list, taxName) => {
  return list?.find((tax) => tax.TaxType === taxName)?.total_tax_rate;
};

/**
 * A function that extracts tracking categories from a line item object.
 *
 * @param {Object} lineItem - The line item object to extract tracking categories from.
 * @return {Array} An array of tracking categories with type and value properties.
 */
export function getTrackingCategoryForPayload(lineItem) {
  let erp_tracking_category = [];
  const trackingCategorieskeys = Object.keys(lineItem).filter((key) =>
    key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
  );
  trackingCategorieskeys?.forEach((key) => {
    const categoryValue = lineItem[key];
    if (categoryValue) {
      erp_tracking_category.push({
        type: key.replace(TRACKING_CATEGORY_KEY_PREFIX, ""),
        value: categoryValue?.value,
      });
    }
  });
  return erp_tracking_category;
}

export function generateLineItemsPayload(lineItems, isERPMapped) {
  const payload = lineItems.map((lineItem) => {
    let data = {
      id: lineItem.id,
      item: lineItem[LINE_ITEM_TABLE_COLUMNS_KEY.DESCRIPTION],
      unit_price: lineItem[LINE_ITEM_TABLE_COLUMNS_KEY.UNIT_COST],
      quantity: lineItem[LINE_ITEM_TABLE_COLUMNS_KEY.QUANTITY],
      total_amount: getNetAmount(lineItem, true),
      is_ai_generated: lineItem[LINE_ITEM_TABLE_COLUMNS_KEY.IS_AI_GENERATED],
    };
    if (isERPMapped) {
      data = {
        ...data,
        erp_account: lineItem[LINE_ITEM_TABLE_COLUMNS_KEY.ACCOUNT] || undefined,
        erp_item_id:
          lineItem[LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST] || undefined,
        erp_tax_rate:
          lineItem[LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE] || undefined,
        erp_tracking_categories: getTrackingCategoryForPayload(lineItem),
      };
    }
    return data;
  });
  return payload;
}

export function getNetAmount(record, withoutTax) {
  return calculateNetAmount(
    Number(record[LINE_ITEM_TABLE_COLUMNS_KEY.UNIT_COST]),
    Number(record[LINE_ITEM_TABLE_COLUMNS_KEY.QUANTITY]),
    getTaxRateFromName(
      record.accountTaxRates,
      record[LINE_ITEM_TABLE_COLUMNS_KEY.TAX_RATE]
    ),
    withoutTax
  );
}

export function getLineItemWrapper(line_items) {
  if (line_items.length) {
    const lineItemWrapper = line_items.map((lineItem) => {
      let categoryKeys = [];
      const trackingCategoriesArr =
        lineItem?.user_input?.erp_tracking_categories;
      if (trackingCategoriesArr?.length) {
        const erp_tracking_categories = trackingCategoriesArr;
        erp_tracking_categories.forEach((category) => {
          categoryKeys.push({
            [TRACKING_CATEGORY_KEY_PREFIX + category["type"]]: {
              label: category.label,
              value: category.value,
            },
          });
        });
      }
      const tempData = {
        id: lineItem.id,
        description: lineItem.item,
        quantity: lineItem.quantity,
        unit_cost: lineItem.unit_price,
        taxRate: lineItem.user_input.erp_tax_rate,
        chartOfAccounts: lineItem.user_input.erp_account,
        erp_item_id: lineItem.erp_item_id,
        net_amount: lineItem.net_amount,
        total_amount: lineItem.total_amount,
        is_ai_generated: lineItem.is_ai_generated,
      };
      if (lineItem?.trx_amount) {
        tempData.trx_amount = lineItem?.trx_amount;
      }
      return Object.assign({}, tempData, ...categoryKeys);
    });
    return lineItemWrapper;
  }
}

export function getLineItemAmountFields(amount, currency) {
  return moneyFormatter(amount, 2, currency?.currency);
}

export function getLineItemWithSuggestedERPDetails({ data, lineItems }) {
  const lineItemsSuggestionValues = data?.[0]?.line_items;
  if (lineItemsSuggestionValues?.length && lineItems?.length) {
    const updatedLineItems = lineItems?.map((lineItem, lineItemIdx) => {
      lineItemsSuggestionValues?.[lineItemIdx]?.forEach(
        ({ custom_field_id, value }) => {
          switch (custom_field_id) {
            default:
              lineItem[custom_field_id] = value;
          }
        }
      );
      return {
        ...lineItem,
      };
    });
    return updatedLineItems;
  } else {
    return lineItems;
  }
}

export function checkLineItemsFormItemStatus({
  lineItems,
  isERPMapped,
  connectedErpPlatformName,
  isPO,
}) {
  const isLineItemGeneratedByKlooAI = lineItems?.filter(
    (item) => !!item.is_ai_generated
  ).length;

  let isERPFieldsEmpty = false;
  if (isERPMapped && connectedErpPlatformName !== "") {
    if (lineItems?.length) {
      isERPFieldsEmpty = lineItems.some((lineItem) => {
        const isAccountEmpty = !lineItem?.[LINE_ITEM_TABLE_COLUMNS_KEY.ACCOUNT];
        const isItemListVisible =
          connectedErpPlatformName !==
            ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.xero.key &&
          (ERP_LINE_ITEM_ORGS.includes(getItem(LOCAL_STORAGE_KEY.ORD_ID)) ||
            !isPlatformEnvironment());
        const isItemListEmpty =
          isItemListVisible &&
          !lineItem?.[LINE_ITEM_TABLE_COLUMNS_KEY.ITEM_LIST];
        switch (connectedErpPlatformName) {
          case ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.xero.key:
            return isAccountEmpty || isItemListEmpty;
          default:
            return isAccountEmpty || isItemListEmpty;
        }
      });
    } else {
      isERPFieldsEmpty = true;
    }
  }

  return {
    lineItemContainerClassName: isERPFieldsEmpty
      ? "line-item-error"
      : isLineItemGeneratedByKlooAI
      ? "kloo-ai"
      : "",
    lineItemHelperText: isERPFieldsEmpty
      ? isPO
        ? INVOICE_LINE_ITEMS.poInfo
        : INVOICE_LINE_ITEMS.errorInfo
      : INVOICE_LINE_ITEMS.info2(isLineItemGeneratedByKlooAI, isPO),
    shouldSubmitButtonDisabledForLineItemError: isERPFieldsEmpty,
  };
}

export function addTaxRateConfigToEachLineItem({ lineItems, taxRates }) {
  let isUpdated = false;
  const updatedLineItems = [...lineItems].map((lineItem) => {
    if (!lineItem?.accountTaxRates && lineItem.chartOfAccounts) {
      const taxRateFound = taxRates.find(
        (taxRate) => lineItem.chartOfAccounts === taxRate.erp_id
      );
      if (taxRateFound) {
        lineItem.accountTaxRates = taxRateFound.tax_rates.map((rate) => {
          return {
            ...rate,
            label: rate?.tax_rate_description,
            value: rate?.TaxType,
          };
        });
        isUpdated = true;
      }
    }
    return lineItem;
  });
  return { updatedLineItems, isUpdated };
}

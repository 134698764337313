import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "../../components/common/Box";
import Column from "../../components/common/Grid/Column";
import Row from "../../components/common/Grid/Row";
import When from "../../components/common/When";
import Label from "../../components/common/Label";
import Image from "../../components/common/Image";
import Icon from "../../components/common/Icon";
import CommonToolTip from "../../components/common/Tooltip";
import CommonSelect from "../../components/common/Selector";
import {
  IM_CREATE_INVOICE_FORM,
  INVOICE_FORM,
  INVOICE_POPULATED_USING_AI,
  TAX_RATE_STATUS,
  TRACKING_CATEGORY_KEY_PREFIX,
} from "../../constants";
import { capitalizeFirstLetter, constructClassName } from "../../utils";
import {
  checkEntityMappingStatus,
  getAllTaxRateList,
  getErpAccounts,
  getErpItemLists,
  getTaxRateList,
  getTrackingCatgories,
} from "../../api";
import {
  ERP_INTEGRATIONS_PAGE_CONFIG,
  ERP_PLATFORM_KEYS,
} from "../../constants/Integration/OpenBanking";
import { connectedErpLogo, toCapitalCase } from "../../helper";
import "./style.scss";
import { PaginatedSelect } from "../../components/common";

const hookDefaultReturnState = {
  ChartOfAccounts: () => null,
  TaxRate: () => null,
  TrackingCategories: () => null,
  chartsOfAccountList: [],
  trackingCategoriesList: [],
  taxRateList: [],
  connectedErpPlatformName: "",
  isDataLoading: false,
  isERPMapped: false,
  isFetchingData: false,
  fetchTaxRate: () => {},
};

function useERPFields({
  entityId,
  form,
  getUpdatedSuppliers,
  checkMappingStatus = true,
  isOnlyComponent = false,
  isAllTaxRate = false,
  skipHookExecution = false,
  taxSolution,
}) {
  const [chartsOfAccountList, setChartsOfAccountList] = useState([]);
  const [taxRateList, setTaxRateList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [connectedErpPlatform, setConnectedErpPlatform] = useState("");
  const [trackingCategoriesList, setTrackingCategoriesList] = useState([]);
  const [isERPMapped, setIsERPMapped] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  let taxRateFormInputName = IM_CREATE_INVOICE_FORM.taxRate.name;

  useEffect(() => {
    setIsERPMapped(false);
    if (entityId) {
      fetchData();
    }
  }, [entityId]);

  async function fetchErpAccounts() {
    if (skipHookExecution) return false;
    const response = await getErpAccounts(entityId);
    if (response?.length) {
      setChartsOfAccountList(
        response?.slice(1)?.map((data) => {
          return { ...data, label: data.name, value: data.erp_id };
        })
      );
    }
  }

  async function fetchErpItemList() {
    if (skipHookExecution) return false;
    const response = await getErpItemLists(entityId);
    if (response?.length) {
      setItemList(
        response?.slice(1)?.map((data) => {
          return { ...data, label: data.name, value: data.erp_id };
        })
      );
    }
  }

  async function fetchTrackingCategories() {
    if (skipHookExecution) return false;
    const response = await getTrackingCatgories(entityId);
    setTrackingCategoriesList(response);
  }

  async function fetchTaxRate(entityId, selectedAccount) {
    if (taxSolution) {
      return;
    }
    if (
      skipHookExecution ||
      [ERP_PLATFORM_KEYS.SAGE_INTACCT].includes(connectedErpPlatform)
    )
      return false;
    const isSageIntacct =
      connectedErpPlatform ===
      ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.sageIntacct
        .keyToCheckLogo;
    const response = await getTaxRateList(
      entityId,
      selectedAccount?.classification,
      isSageIntacct ? selectedAccount?.account_number : null,
      isSageIntacct ? taxSolution : null
    );
    setTaxRateList(
      response?.map((data) => {
        return {
          ...data,
          label: data?.description,
          value: data?.TaxType,
          disabled: data?.Status?.toLowerCase() !== TAX_RATE_STATUS.active,
        };
      })
    );
    return response;
  }

  async function fetchAllTaxRate() {
    const response = await getAllTaxRateList(entityId);
    setTaxRateList(
      response?.map((data) => {
        return {
          ...data,
          label: data?.description,
          value: data?.total_tax_rate,
        };
      })
    );
    return response;
  }

  async function fetchData() {
    if (form) {
      const trackingCategorieskeys = Object.keys(form.getFieldsValue()).filter(
        (key) => key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
      );
      trackingCategorieskeys?.map((key) => {
        form.setFieldValue(key, null);
      });
    }

    setChartsOfAccountList([]);
    setTaxRateList([]);
    setTrackingCategoriesList([]);
    setConnectedErpPlatform("");

    let isMapped = false;
    if (checkMappingStatus) {
      const response = await checkEntityMappingStatus(entityId);
      isMapped = response?.mapped === 1;
      if (isMapped) {
        const connectedErpPlatform = response?.integration_name?.toLowerCase();
        setConnectedErpPlatform(connectedErpPlatform);
      }
    } else {
      isMapped = true;
    }

    if (isMapped) {
      setIsERPMapped(true);
      if (form) {
        form.setFieldsValue({
          [INVOICE_FORM.SUPPLIER.name]: null,
          chartOfAccounts: null,
          taxRate: null,
        });
      }
      setIsFetchingData(true);
      Promise.all([
        fetchErpAccounts(),
        fetchErpItemList(),
        fetchTrackingCategories(),
        getUpdatedSuppliers && getUpdatedSuppliers(entityId),
        isAllTaxRate && fetchAllTaxRate(),
      ])
        .then()
        .finally(() => {
          setIsFetchingData(false);
        });
    } else {
      setIsERPMapped(false);
      setIsFetchingData(false);
      getUpdatedSuppliers && getUpdatedSuppliers();
    }
  }

  const FieldLabel = ({ labelText, showAiIcon, optional = false }) => {
    if (isOnlyComponent) return null;

    return (
      <Box className="w-100 p-b-8px" justifyContent="flexStart">
        <Label
          text={labelText}
          suffixIcon="info"
          suffixIconTooltip={labelText}
          optional={optional}
        />
        <When condition={showAiIcon}>
          <CommonToolTip title={INVOICE_POPULATED_USING_AI}>
            <Icon
              iconName={"smart_toy"}
              className={"brand-color ms-5"}
              showIconOutline={true}
            />
          </CommonToolTip>
        </When>
      </Box>
    );
  };

  const FieldERPIcon = () => {
    if (isOnlyComponent) return false;
    return (
      <Column
        span={3}
        className="d-flex h-40px justify-content-center align-items-center erp-fields-prefix-icon"
      >
        <Image src={connectedErpLogo(connectedErpPlatform)} width={30} />
      </Column>
    );
  };

  const ChartOfAccounts = ({
    dataPopulatedByAI,
    inputChangedFromAiValue,
    isDisabledOnEdit,
    formItemName = IM_CREATE_INVOICE_FORM.chartOfAccounts.name,
    preventTaxListLoad = false,
    viewOnlyData = false,
    selectedDataId = "",
    rules,
  }) => {
    if (viewOnlyData) {
      return {
        src: connectedErpLogo(connectedErpPlatform),
        label: IM_CREATE_INVOICE_FORM.chartOfAccounts.labelText,
        value:
          chartsOfAccountList?.find((el) => el.value === selectedDataId)
            ?.label || "",
      };
    }
    async function handleChartOfAccountChange(e, options) {
      if (preventTaxListLoad) {
        return false;
      }
      const selectedEntity = form?.getFieldValue("entity_id") || entityId;
      form?.setFieldValue(taxRateFormInputName);
      setTaxRateList([]);
      fetchTaxRate(selectedEntity, options);
    }

    function handleChartOfAccountClear() {
      if (preventTaxListLoad) {
        return false;
      }
      form?.setFieldValue(taxRateFormInputName);
      setTaxRateList([]);
    }
    return (
      <When condition={chartsOfAccountList?.length}>
        <FieldLabel
          labelText={IM_CREATE_INVOICE_FORM.chartOfAccounts.labelText}
          showAiIcon={dataPopulatedByAI?.chartOfAccounts}
        />
        <Row>
          <FieldERPIcon />
          <Column
            span={isOnlyComponent ? 24 : 21}
            className={constructClassName([isOnlyComponent ? "" : "ps-0"])}
          >
            <CommonSelect
              {...IM_CREATE_INVOICE_FORM.chartOfAccounts}
              name={formItemName}
              getPopupContainer={() => document.body}
              disabled={isDisabledOnEdit}
              options={chartsOfAccountList}
              onSelect={(e, options) => {
                handleChartOfAccountChange(e, options);
              }}
              onClear={() => handleChartOfAccountClear()}
              onChange={(e) =>
                inputChangedFromAiValue(
                  IM_CREATE_INVOICE_FORM.chartOfAccounts.name,
                  e
                )
              }
              rules={rules}
            />
          </Column>
        </Row>
      </When>
    );
  };

  const TaxRate = ({
    dataPopulatedByAI,
    inputChangedFromAiValue,
    isDisabledOnEdit,
    formItemName,
    viewOnlyData = false,
    selectedDataId = "",
    rules,
    options,
  }) => {
    if (formItemName) {
      taxRateFormInputName = formItemName;
    }
    if (viewOnlyData) {
      return {
        src: connectedErpLogo(connectedErpPlatform),
        label: IM_CREATE_INVOICE_FORM.taxRate.labelText,
        value:
          taxRateList?.find((el) => el.value === selectedDataId)?.label || "",
      };
    }

    const selectOptions = options || taxRateList;
    return (
      <When
        condition={
          connectedErpPlatform ===
            ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.xero.key ||
          connectedErpPlatform ===
            ERP_INTEGRATIONS_PAGE_CONFIG.TITLE_CARD_DETAILS.sageIntacct
              .keyToCheckLogo
        }
      >
        <FieldLabel
          labelText={IM_CREATE_INVOICE_FORM.taxRate.labelText}
          showAiIcon={dataPopulatedByAI?.taxRate}
        />
        <Row className="m-l-0px">
          <FieldERPIcon />
          <Column
            span={isOnlyComponent ? 24 : 21}
            className={constructClassName([isOnlyComponent ? "" : "ps-0"])}
          >
            <CommonSelect
              getPopupContainer={() => document.body}
              {...IM_CREATE_INVOICE_FORM.taxRate}
              name={taxRateFormInputName}
              disabled={isDisabledOnEdit || !selectOptions?.length}
              options={selectOptions}
              onChange={() =>
                inputChangedFromAiValue(IM_CREATE_INVOICE_FORM.taxRate.name)
              }
              rules={!selectOptions?.length ? [] : rules}
            />
          </Column>
        </Row>
      </When>
    );
  };

  const ItemList = ({
    dataPopulatedByAI,
    inputChangedFromAiValue,
    isDisabledOnEdit,
    formItemName,
    viewOnlyData = false,
    selectedDataId = "",
    rules,
  }) => {
    if (formItemName) {
      taxRateFormInputName = formItemName;
    }
    if (viewOnlyData) {
      return {
        src: connectedErpLogo(connectedErpPlatform),
        label: IM_CREATE_INVOICE_FORM.taxRate.labelText,
        value: itemList?.find((el) => el.value === selectedDataId)?.label || "",
      };
    }
    return (
      <>
        <FieldLabel
          labelText={IM_CREATE_INVOICE_FORM.itemList.labelText}
          showAiIcon={dataPopulatedByAI?.itemList}
        />
        <Row className="m-l-0px">
          <FieldERPIcon />
          <Column
            span={isOnlyComponent ? 24 : 21}
            className={constructClassName([isOnlyComponent ? "" : "ps-0"])}
          >
            <CommonSelect
              getPopupContainer={() => document.body}
              {...IM_CREATE_INVOICE_FORM.itemList}
              name={taxRateFormInputName}
              disabled={isDisabledOnEdit || !itemList?.length}
              options={itemList}
              onChange={() =>
                inputChangedFromAiValue(IM_CREATE_INVOICE_FORM.itemList.name)
              }
              rules={rules}
            />
          </Column>
        </Row>
      </>
    );
  };

  const TrackingCategories = ({
    dataPopulatedByAI,
    inputChangedFromAiValue,
    isDisabledOnEdit,
    rules,
    renderSpecificCategory = "",
    formItemName = "",
    viewOnlyData = false,
    erp_tracking_categories = [],
    isHorizontalAlign = false,
  }) => {
    if (viewOnlyData) {
      const trackingData = [];
      erp_tracking_categories?.forEach((category) => {
        const selectedTrackingCategoryFound = trackingCategoriesList?.[
          category?.type
        ]?.find((data) => data.value === category?.value);
        if (selectedTrackingCategoryFound) {
          const data = {
            src: connectedErpLogo(connectedErpPlatform),
            label: capitalizeFirstLetter(category.type),
            value: selectedTrackingCategoryFound.label,
          };
          trackingData.push(data);
        }
      });
      return trackingData;
    }

    let trackingCategories = renderSpecificCategory
      ? Object.keys(trackingCategoriesList)?.filter(
          (key) => key === renderSpecificCategory
        )
      : Object.keys(trackingCategoriesList);
    const trackingCategoriesKeys =
      dataPopulatedByAI &&
      Object.keys(dataPopulatedByAI).filter((key) =>
        key.startsWith(TRACKING_CATEGORY_KEY_PREFIX)
      );

    const fetchOptions = async (page, pageSize, category) => {
      // Simulate API call
      const res = await getTrackingCatgories(
        entityId,
        category,
        page,
        pageSize
      );
      return res;
    };
    return (
      <When condition={Object.keys(trackingCategoriesList)?.length}>
        <FieldLabel
          labelText={IM_CREATE_INVOICE_FORM.trackingCategories.labelText}
          showAiIcon={trackingCategoriesKeys?.length}
          optional={true}
        />
        <Row gutter={24}>
          {trackingCategories?.map((key) => (
            <Column span={isHorizontalAlign ? 12 : 24}>
              <Row>
                <FieldERPIcon />
                <Column
                  span={isOnlyComponent ? 24 : 21}
                  className={constructClassName([
                    isOnlyComponent ? "" : "ps-0",
                  ])}
                >
                  {/* <CommonSelect
                    name={
                      formItemName || `${TRACKING_CATEGORY_KEY_PREFIX}${key}`
                    }
                    placeholder={`${toCapitalCase(key)}`}
                    disabled={isDisabledOnEdit}
                    options={trackingCategoriesList[key]}
                    getPopupContainer={() => document.body}
                    onChange={() =>
                      inputChangedFromAiValue(
                        `${TRACKING_CATEGORY_KEY_PREFIX}${key}`
                      )
                    }
                    rules={rules}
                  /> */}
                  <PaginatedSelect
                    name={
                      formItemName || `${TRACKING_CATEGORY_KEY_PREFIX}${key}`
                    }
                    placeholder={`${key}`}
                    rules={rules}
                    fetchOptions={(currentPage, pageSize) =>
                      fetchOptions(currentPage, pageSize, key)
                    }
                  />
                </Column>
              </Row>
            </Column>
          ))}
        </Row>
      </When>
    );
  };

  return {
    ChartOfAccounts,
    TaxRate,
    TrackingCategories,
    ItemList,
    chartsOfAccountList,
    trackingCategoriesList,
    taxRateList,
    connectedErpPlatformName: connectedErpPlatform,
    isDataLoading:
      chartsOfAccountList?.length !== 0 && trackingCategoriesList?.length !== 0,
    isERPMapped,
    isFetchingData,
    fetchTaxRate,
    itemList,
  };
}

export default useERPFields;
useERPFields.propTypes = {
  entityId: PropTypes.string.isRequired, // Entity id is the only required Prop
  form: PropTypes.any, // Form Context
  getUpdatedSuppliers: PropTypes.func, // Update Supplier based on the entity id
  checkMappingStatus: PropTypes.bool, // Skip Mapping status API, Keep it false, if u are sure the entity is already connected with ERP.
  isOnlyComponent: PropTypes.bool, // Components where the label is not required. Eg. line items
  isAllTaxRate: PropTypes.bool, // Normally, Tax rate are fetched by the classification in UI, but there are cases where we need all Tax Rate related to the entity.
  isERPMapped: PropTypes.bool, // is true when entity mapped with ERP, otherwise it's false
  isFetchingData: PropTypes.bool, // is true until the ERP fields detail API's is in progress, otherwise it's false // it can be use to show loader
  skipHookExecution: PropTypes.bool, // Skip Hook Execution, temporary flag, will remove during cleanup.
};

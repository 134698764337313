import {
  ACCENTS,
  BUTTON_VARIANT,
  INVOICES_LABEL,
  PURCHASE_REQUEST_PAGE_UPDATE,
} from "..";
import { ENTITY, PREFIX, TAX_CODES } from "../../utils";
import { CONFIGURE_INTEGRATION_MODAL } from "../Integration/OpenBanking";
import {
  LC_SUPPLIERS_FORM_PLACEHOLDER,
  LC_SUPPLIERS_PLACEHOLDER,
  LC_SUPPLIER_PLACEHOLDER,
} from "../dynamic";

export const CONFIGURATIONS_KEYS = {
  poToSupplier: "auto_email_supplier_po",
  taxCode: "tax_codes_po",
  entity: "org_entities",
  prefix_po: "po_num_prefix",
  taxCodeInvoice: "tax_codes_invoice",
  poKeyRequired: "is_required",
  invoiceKeyRequired: "is_required_invoice",
  workFlowAsignmentInvoice: "workflow_assignment_invoice",
  poWorkflowAssignmentRetoolUpload: "po_workflow_assignment_retool_upload",
  OCRlineItems: "auto_generate_line_items_im",
  lineItems: "line_item_invoice",
  defaultOwnerRaiser: "invoice_default_owner_raiser",
  defaultOwnerPoNumber: "invoice_default_owner_po_number",
  autoMatchConfirmed: "invoice_auto_confirm_match",
  poRecordGRNI: "po_record_grni",
  poFieldRequired: "is_po_number_required_for_invoices",
  isSegregationPOEnabled: "is_segregation_po_enabled",
  isInvoiceWorkflowAssignmentConfirmMatch:
    "invoice_workflow_assignment_confirm_match",
};

export const CONFIGURATIONS_KEYS_INITIAL_STATE = {
  [CONFIGURATIONS_KEYS.poToSupplier]: "",
  [CONFIGURATIONS_KEYS.prefix_po]: "",
  [CONFIGURATIONS_KEYS.taxCode]: "",
  [CONFIGURATIONS_KEYS.entity]: "",
  [CONFIGURATIONS_KEYS.OCRlineItems]: "",
  [CONFIGURATIONS_KEYS.lineItems]: "",
};

const PO_LABEL = PURCHASE_REQUEST_PAGE_UPDATE.LABEL;

export const CONFIGURATIONS_CONSTANTS = {
  HEADER_CONFIG: {
    desc: "",
    header: "Configurations",
    buttonLabel: "",
  },
  TABS: [
    { key: 1, label: "Organisation" },
    {
      key: 2,
      label: PO_LABEL,
    },
    {
      key: 3,
      label: INVOICES_LABEL,
    },
  ],

  AUTOMATIC_EMAIL: {
    [CONFIGURATIONS_KEYS.poToSupplier]: {
      title: `Automatic emailing of POs to ${LC_SUPPLIERS_PLACEHOLDER}`,
      subTitle: `Approved POs will automatically be emailed to ${LC_SUPPLIERS_PLACEHOLDER} who have a registered email address. To add a ${LC_SUPPLIERS_FORM_PLACEHOLDER} email, you can do so through the ${LC_SUPPLIER_PLACEHOLDER} management page. Alternatively, you can manually send POs to ${LC_SUPPLIERS_PLACEHOLDER} via the ‘Completed’ tab within the ${PO_LABEL} page and clicking on ‘Email to ${LC_SUPPLIER_PLACEHOLDER}’.`,
    },
  },
  OCR_LINE_ITEMS: {
    [CONFIGURATIONS_KEYS.OCRlineItems]: {
      title: `Automatic line item generation`,
      subTitle: `Invoice line item will be automatically generated when an invoice has been uploaded via platform or email submission. Whether this is enabled or disabled, users will retain the option to genrate the line items again with Kloo AI via platform submission`,
    },
  },
  LINE_ITEMS: {
    [CONFIGURATIONS_KEYS.lineItems]: {
      title: `Line items`,
      subTitle: `Enable or disable invoice line items for all invoices submitted to Kloo by each entity within your organisation. If this is disabled, ERP categories will be tracked at the invoice header level and will be pushed to your accounting software as a single line item. If this is enabled, ERP categories will be tracked at the invoice line item level and multiple line items will be pushed to your accounting software.`,
    },
  },
  WORKFLOW_ASSIGNMENT: {
    title: `Enforce confirmed matches before workflow assignment`,
    subTitle: `With this toggled on invoices will be required to have a purchase order confirmed matched with it so that the invoice can be assigned to a workflow`,
  },
  DEFAULT_OWNER: {
    defaultOwnertitle: {
      text: "Default owner selection",
      variant: "title",
      fontSize: "20",
      responsiveFontSize: false,
    },
    defaultOwnerSubtitle: {
      variant: "body",
      text: "Determine how the owner field should be automatically populated during invoice submission.",
    },
    ownerSelectionArray: [
      {
        value: CONFIGURATIONS_KEYS.defaultOwnerRaiser,
        text: "Raiser",
      },
      {
        value: CONFIGURATIONS_KEYS.defaultOwnerPoNumber,
        text: "PO number owner",
      },
    ],
  },
  AUTO_MATCH_COMFIRMED: {
    title: "Automatic confirm matching",
    subTitle:
      "With this toggled on, the purchase order number selected during invoice submission will be automatically confirmed matched. If multiple purchase orders has been selected, this will be marked as no match until a manual match has been made. With this toggled off, Kloo AI will suggest the match to be made also requiring manual confirmation from users within your organisation.",
  },
  DEFAULT_ORG_CURRENCY: {
    title: {
      text: "Default currency",
      variant: "title",
      fontSize: "20",
      responsiveFontSize: false,
    },
    subtitle: {
      variant: "body",
      text: "Select a default currency for your organisation.",
    },
    currencyField: {
      name: "currency_id",
      showDropdownTextAsLabel: true,
    },
    currencyFieldDBMappingKey: "default_currency_id",
    currencyFieldUpdateEventName: "update-default-org-currency",
    cancelBn: {
      text: "Cancel",
      accent: ACCENTS.SECONDARY,
      variant: BUTTON_VARIANT.BUTTON_HOLLOW,
      customSize: "large",
    },
  },
  GRNI_CONFIG: {
    title: {
      text: "Record GRNI for purchase orders",
      variant: "title",
      fontSize: "20",
      responsiveFontSize: false,
    },
    subtitle: {
      variant: "body",
      text: "For every purchase order, you can make a goods received, but not invoiced entry to keep track of goods that have been delivered without a matching invoice.",
    },
    subtitle2: {
      variant: "body",
      text: " Enabling this feature will automatically disable automated confirm matching of invoices to purchase orders.",
    },
    tooltip: {
      title:
        "GRNI for purchase requested must be disabled before this configuration can be enabled",
      trigger: "hover",
      placement: "top",
    },
    switchGrni: {
      isFormItem: false,
      className: "ms-3",
      id: "recordGrni",
    },
    disableErrorMessage: ["Please reach out to ", " to disable GRNI."],
  },
  THRESHOLD_MATCHING: {
    title: {
      variant: "title",
      text: "Threshold matching",
      className: "mt-3",
      fontSize: 20,
      responsiveFontSize: false,
    },
    threshold: {
      name: "threshold",
      // rules: THRESHOLD_MATCHING,
      allowClear: false,
      placeholder: "Enter threshold",
      label: "Threshold percentage value",
      labelCol: { span: 24 },
      wrapperClass: "input-class",
      min: 0,
      max: 100,
      formatter: (value) => `${value}%`,
      parser: (value) => value.replace("%", ""),
    },
    fixedValue: {
      name: "fixedValue",
      allowClear: false,
      placeholder: "Enter an amount",
      prefix: "£",
      label: "Fixed value",
      labelCol: { span: 24 },
      wrapperClass: "input-class m-l-100px",
      min: 0,
      precision: 2,
    },
    saveButton: (isEdit) => {
      return {
        text: isEdit ? "Edit" : "Save",
        accent: ACCENTS.PRIMARY,
        variant: BUTTON_VARIANT.BUTTON_FILLED,
        loaderText: "Saving...",
        customSize: "large",
        className: "btn-margin",
      };
    },
    cancelButton: {
      text: "Cancel",
      accent: ACCENTS.SECONDARY,
      variant: BUTTON_VARIANT.BUTTON_HOLLOW,
      customSize: "large",
      className: "btn-margin",
    },
    EXAMPLE_INFO: {
      HEADER: {
        variant: "body",
        text: "For example: ",
        className: "d-inline",
        fontStyle: "semibold",
        textColorType: "tertiary",
        fontSize: 16,
      },
      BODY: {
        variant: "body",
        className: "d-inline",
        text: "You could set your range at +10% of the purchase order value. This means a purchase order of £100 can be threshold matched with an invoice that has a maximum amount of £110.",
        textColorType: "tertiary",
        fontSize: 16,
      },
      PART_1: {
        variant: "body",
        className: "d-inline",
        text: "In this case a purchase order of",
        textColorType: "tertiary",
        fontSize: 16,
      },
      PART_2: (
        withThreshold,
        withFixedAmount,
        consideredAmount,
        threshold,
        fixedAmount
      ) => ({
        variant: "body",
        className: "d-inline",
        text: `would be increased to ${
          threshold && fixedAmount
            ? `${withThreshold} or ${withFixedAmount}. In this case, ${consideredAmount} would be the effective purchase order value`
            : `${threshold ? withThreshold : withFixedAmount}`
        }.`,
        textColorType: "tertiary",
        fontSize: 16,
      }),
    },
  },
  TAX_CODES: {
    titleText: {
      text: "Tax codes",
      fontSize: 20,
      responsiveFontSize: false,
      variant: "title",
    },
    purchaseRequestText: {
      text: "Purchase requests",
      fontSize: 16,
      responsiveFontSize: false,
      variant: "title",
    },
    invoiceManagementText: {
      text: "Invoice management",
      fontSize: 16,
      responsiveFontSize: false,
      variant: "title",
    },
    addTaxButton: {
      id: "notOutside",
      text: "Add tax codes",
      iconName: "add_circle",
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      accent: ACCENTS.PRIMARY,
      customSize: "large",
    },
    OPTIONAL: { label: "Optional", value: 0 },
    MANDATORY: { label: "Mandatory", value: 1 },
    TAX_LIST: {
      TITLE: {
        column1: {
          text: "Name",
          variant: "body",
          fontStyle: "semibold",
        },
        column2: {
          text: "Tax rate",
          variant: "body",
          fontStyle: "semibold",
          className: "text-align-right",
        },
      },
      name: {
        name: "field_name",
        placeholder: "Enter tax code name",
        rules: TAX_CODES.NAME,
      },
      taxRate: {
        name: "field_value",
        placeholder: "%",
        min: 0,
        max: 100,
        formatter: (value) => `${value}%`,
        parser: (value) => value.replace("%", ""),
        controls: false,
        rules: TAX_CODES.VAT,
        suffix: "%",
      },
      submitButton: {
        id: "notOutside",
        iconName: "check",
        variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
        accent: ACCENTS.PRIMARY,
        htmlType: "submit",
        className: "m-b-20px h-40px",
      },
      deleteButton: {
        iconName: "close",
        variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
        accent: ACCENTS.DESTRUCTIVE,
      },
      taxCodeField: {
        id: "notOutside",
        variant: "body",
        className: "list-button",
      },
      VIEW_LESS_LENGTH: 6,
      VIEW_BUTTON: (viewMore) => {
        if (viewMore) {
          return {
            text: "View more",
            accent: ACCENTS.BLACK,
            variant: BUTTON_VARIANT.LINK,
            iconName: "expand_more",
          };
        } else
          return {
            text: "View less",
            accent: ACCENTS.BLACK,
            variant: BUTTON_VARIANT.LINK,
            iconName: "expand_less",
          };
      },

      createErrorMessage: "Tax code creation failed!",
      updateErrorMessage: "Update failed!",
      deleteErrorMessage: "Delete Failed!",
    },
    disableErrorMessage: [
      "Please reach out to ",
      " to disable your organisation’s tax codes.",
    ],
  },
  ENTITY_MANAGEMENT: {
    titleText: {
      text: "Entity management",
      fontSize: 20,
      responsiveFontSize: false,
      variant: "title",
    },
    addEntityButton: {
      id: "notOutside",
      text: "Add entities",
      iconName: "add_circle",
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      accent: ACCENTS.PRIMARY,
      customSize: "large",
    },
    ENTITY_LIST: {
      TITLE: {
        column1: {
          text: "Name",
          variant: "body",
          fontStyle: "semibold",
        },
      },
      entityName: {
        name: "field_name",
        placeholder: "Enter entity name",
        rules: ENTITY.NAME,
      },
      createErrorMessage: "Entity creation failed!",
      updateErrorMessage: "Update failed!",
      deleteErrorMessage: "Delete Failed!",
    },
    disableErrorMessage: [
      "Please reach out to ",
      " to disable your organisation’s entity.",
    ],
  },
  PREFIX_MANAGEMENT: {
    titleText: {
      text: "PO prefix",
      fontSize: 20,
      responsiveFontSize: false,
      variant: "title",
    },
    addPrefixButton: {
      id: "notOutside",
      text: "Add prefix",
      iconName: "add_circle",
      variant: BUTTON_VARIANT.BUTTON_FILLED,
      accent: ACCENTS.PRIMARY,
      customSize: "large",
    },
    PREFIX_LIST: {
      TITLE: {
        column1: {
          text: "Entity name",
          variant: "body",
          fontStyle: "semibold",
        },
        column2: {
          text: "Prefix",
          variant: "body",
          fontStyle: "semibold",
        },
      },
      prefixName: {
        key: "field_name",
        placeholder: "Enter prefix",
        rules: PREFIX.NAME,
        avoidScriptTagRule: true,
        maxLength: 4,
      },
      createErrorMessage: "Prefix creation failed!",
      updateErrorMessage: "Update failed!",
    },
    disableErrorMessage: [
      "Please reach out to ",
      " to disable your organisation’s PO prefix.",
    ],
  },
  COMMON_LIST_EDITOR: {
    fieldItems: {
      id: "notOutside",
      variant: "body",
      className: "list-button",
    },
    saveButton: {
      id: "notOutside",
      iconName: "check",
      variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
      accent: ACCENTS.PRIMARY,
      htmlType: "submit",
      className: "m-b-20px h-40px",
    },
    deleteButton: {
      iconName: "close",
      variant: BUTTON_VARIANT.BUTTON_ONLY_ICON,
      accent: ACCENTS.DESTRUCTIVE,
    },
    VIEW_LESS_LENGTH: 6,
    VIEW_BUTTON: (viewMore) => {
      if (viewMore) {
        return {
          text: "View more",
          accent: ACCENTS.BLACK,
          variant: BUTTON_VARIANT.LINK,
          iconName: "expand_more",
        };
      } else
        return {
          text: "View less",
          accent: ACCENTS.BLACK,
          variant: BUTTON_VARIANT.LINK,
          iconName: "expand_less",
        };
    },
  },
  PO_NUMBER_FOR_IM: {
    title: "PO number for invoice submission",
    subTitle:
      "If enabled, invoices must be submitted to Kloo with a purchase order number and we recommend that all invoices processed on Kloo have their matching purchase orders also processed on platform. Note, when enabled, all invoices in the submitted tab which have no purchase order number will be sent to the draft tab.",
    disableMsg: ["Please reach out to ", " to mark this field as optional."],
  },
};

export const INVOICE_LINE_ITEM_CONFIG_SECTION = {
  ORGANISATION_WIDE: {
    entity_name: "Organisation wide",
    erp_entity_status:
      CONFIGURE_INTEGRATION_MODAL.ENTITY_MAPPING_STATUSES.mapped,
    entity_id: "organisationWide",
  },
  ENTITY_TOGGLE_DISABLED: {
    title: "No ERP account has been connected with this entity",
  },
};

export const LINE_ITEM_ENTITY_INTEGRATION_KEY = {
  INVOICE: "invoice",
};

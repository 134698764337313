import React from "react";
import { Dropdown, Layout, Menu, Modal, Row, Space, Spin } from "antd";
import { ReactComponent as Ukflag } from "../../assets/images/ukflag.svg";
import Logo from "../../assets/images/white-only-logo.svg";
import LogoText from "../../assets/images/white-only-text.svg";
import { NAVIGATION_URL } from "../../constants";
import { Link, Navigate } from "react-router-dom";
import { Box, Column } from "../common";
const { Header } = Layout;

const dropDownMenu = (amount) => {
  return (
    <Menu
      className="bank-menu"
      style={{ marginTop: 21 }}
      items={[
        {
          label:
            amount &&
            "£" +
              Number(amount).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }),
          icon: <Ukflag style={{ borderRadius: "50%" }} />,
          key: "1",
        },
      ]}
    />
  );
};

export default function AppHeader({
  sidebarHandle,
  OrgAmount,
  userType,
  isOrgOthers,
  modularAccount,
  organizationAddress,
  profileMenu,
  isOpen,
}) {
  const onClickLogo = (event) => {
    if (event.ctrlKey) {
      window.open(NAVIGATION_URL.DASHBOARD, "_blank");
    } else {
      Navigate(NAVIGATION_URL.DASHBOARD);
    }
  };

  return (
    <Header className="site-layout-background h-56px">
      <Row span={24} className="h-56px">
        <Column span={12} className="h-56px">
          <Box justifyContent="flexStart" className="h-56px">
            <Link to="../dashboard">
              <div className="logoMain" onClick={onClickLogo}>
                <div
                  className={` ${
                    isOpen.isLeftSidebar ? "logoCombine" : "logoCombine-2"
                  }`}
                >
                  <img className="logoOnly" src={Logo} alt="logo" />
                  <img
                    className={`logoText ${
                      isOpen.isLeftSidebar ? "img-display-open" : "img-display"
                    }`}
                    src={LogoText}
                    alt="logo"
                  />
                </div>
              </div>
            </Link>
          </Box>
        </Column>
        <Column span={12} className="h-56px">
          <Box justifyContent="flexEnd" className="h-56px">
            {/* <div className="burgerMain">
              <span
                className="material-icons"
                onClick={() => sidebarHandle("left")}
              >
                menu
              </span>
            </div> */}
            <div className="headerRight">
              <div className="dropDownMenu">
                {!isOrgOthers ? (
                  <Dropdown
                    overlay={
                      userType !== "platform-user" &&
                      userType !== "analytics-manager" ? (
                        <Spin
                          spinning={OrgAmount === "" ? true : false}
                          delay={0}
                        >
                          {dropDownMenu(OrgAmount)}
                        </Spin>
                      ) : (
                        <></>
                      )
                    }
                    trigger={"hover"}
                  >
                    <a>
                      <Space>
                        <div className="dropText">
                          <span className="dropTopText">
                            {modularAccount[0]?.organization_name || ""}
                          </span>
                          <span className="dropBottomText">
                            {(organizationAddress?.posttown ?? "") +
                              (organizationAddress?.posttown &&
                              organizationAddress?.country
                                ? ", "
                                : "") +
                              (organizationAddress?.country ?? "")}
                          </span>
                        </div>
                        {!isOrgOthers && (
                          <span className="material-icons">visibility</span>
                        )}
                      </Space>
                    </a>
                  </Dropdown>
                ) : (
                  <Space>
                    <div className="dropText">
                      <span className="dropTopText">
                        {modularAccount[0]?.organization_name || ""}
                      </span>
                      <span className="dropBottomText">
                        {(organizationAddress?.posttown ?? "") +
                          (organizationAddress?.posttown &&
                          organizationAddress?.country
                            ? ", "
                            : "") +
                          (organizationAddress?.country ?? "")}
                      </span>
                    </div>
                  </Space>
                )}
              </div>
              <div className="userIconDiv">
                <Dropdown trigger={"hover"} overlay={profileMenu}>
                  <div>
                    <div className="user-profile-dropdown">
                      <span className="material-icons">person</span>
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>
          </Box>
        </Column>
      </Row>
    </Header>
  );
}

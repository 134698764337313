import { LC_SUPPLIER_PLACEHOLDER } from "../constants/dynamic";
import { WORKFLOW_CONSTANT } from "../constants/invoices";
import { moneyFormatter } from "./functions";
const amountValidation = () => ({
  validator(_, value) {
    if (!value) {
      return Promise.reject();
    }
    if (!/^[0-9]+$/.test(value)) {
      return Promise.reject(new Error("Please enter only integer number"));
    }
    if (value === 0) {
      return Promise.reject(new Error("Limit must be greater than zero"));
    }
    if (value > 250000) {
      return Promise.reject(new Error("Card limit cannot exceed £250,000"));
    }
    if (value < 5) {
      return Promise.reject(new Error("Card limit cannot be less than £5"));
    }
    return Promise.resolve();
  },
});

export const isValidNumber = () => ({
  pattern: new RegExp(/^\d+(\.\d{1,2})?$/),
  message: "Please enter a valid number.",
});

export const checkDuplicateItems = ({ items }) => ({
  validator(_, value) {
    if (items.find((item) => item.name === value)) {
      return Promise.reject(new Error(`Item already exists`));
    }
    return Promise.resolve();
  },
});

const gtZero = (label) => ({
  validator(_, value) {
    if (value < 0 || value === 0) {
      return Promise.reject(new Error(`${label} must be greater than zero`));
    }
    return Promise.resolve();
  },
});

const nltZero = (label) => ({
  validator(_, value) {
    if (value < 0) {
      return Promise.reject(new Error(`${label} cannot be less than zero`));
    }
    return Promise.resolve();
  },
});

const ntZero = (label) => ({
  validator(_, value) {
    if (value === 0) {
      return Promise.reject("please enter valid amount value");
    }
    return Promise.resolve();
  },
});

const ltBillion = (label, currencySymbol = "£") => ({
  validator(_, value) {
    if (value > 10000000) {
      return Promise.reject(
        new Error(
          `${label} must be less than ${currencySymbol}${amountFormatterWithoutCurrency(
            10000000
          )}`
        )
      );
    }
    return Promise.resolve();
  },
});

const gtAndltAmount = ({
  label,
  currency = "GBP",
  maxAmount,
  minAmount,
  minAmountErrorMsg,
  maxAmountErrorMsg,
}) => ({
  validator(_, value) {
    if (value === undefined || value === null) {
      return Promise.reject(`Please enter the ${label}`);
    } else if (value > maxAmount) {
      return Promise.reject(
        new Error(
          minAmountErrorMsg
            ? minAmountErrorMsg
            : `The required ${label} must be less than ${moneyFormatter(
                maxAmount,
                2,
                currency
              )}`
        )
      );
    } else if (value < minAmount) {
      return Promise.reject(
        new Error(
          maxAmountErrorMsg
            ? maxAmountErrorMsg
            : `The required ${label} must be greater than or equal to ${moneyFormatter(
                minAmount,
                2,
                currency
              )}`
        )
      );
    }
    return Promise.resolve();
  },
});

export const RE_DIGIT = new RegExp(/^\d+$/);
export const RE_DECIMAL_NUMBER = new RegExp(/^\d*\.?\d{0,2}$/);
/**
 * The following regular expression will match numbers between 0 and 100,
 * including decimal numbers with up to two decimal places.
 */
export const RE_DIGIT_PERCENTAGE = new RegExp(
  /^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$/
);

export const CARD_LIMIT_RULES = [
  {
    required: true,
    message: "Please enter amount",
  },
  amountValidation,
];

export function amountFormatter(value) {
  return `£${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function cardLimitParser(value) {
  return value.replace(/\$\s?|(,*)/g, "");
}

export function cardLimitFormatter(value) {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function sortCodeFormatter(value) {
  return `${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, "-");
}

export const scriptTagRegex = /[<>]/g; // Regex to match < and >

export const getRequiredRule = (
  fieldLabel,
  isSelect = false,
  msg,
  whitespace = false
) => {
  let rule = {
    required: true,
    message: msg || `Please ${isSelect ? "select" : "enter"} ${fieldLabel}`,
  };
  if (whitespace) {
    rule.whitespace = whitespace;
  }
  return rule;
};

export const getMaxRule = (maxLength, customMessage = "") => ({
  max: maxLength,
  message: customMessage
    ? customMessage
    : `Maximum ${maxLength} characters are allowed`,
});

export const getMinRule = (minLength, isDigit = false, customMessage = "") => ({
  min: minLength,
  message: customMessage
    ? customMessage
    : `Minimum ${minLength} ${isDigit ? "digits" : "characters"} required`,
});

const getMinAndMaxRule = (
  fieldLabel,
  minLength,
  maxLength,
  customMessage = ""
) => ({
  min: minLength,
  max: maxLength,
  pattern: "[0-9]",
  message:
    customMessage || `Please enter a valid ${minLength} digits ${fieldLabel}`,
});

const getValidTextRule = () => ({
  pattern: new RegExp(/^(\s+\S+\s*)*(?!\s).*$/),
  message: "Please enter a valid text",
});

const getValidEmailRule = () => ({
  type: "email",
  message: "Please enter a valid email address",
});

const specialAllowedCharacters = () => ({
  pattern: new RegExp(/^[a-zA-Z0-9-.'\s]*$/),
  message:
    "Only alphanumeric characters including space, . , - and ' are allowed",
});

export const alphanumericAllowedWithoutScriptTags = () => ({
  validator(_, value) {
    if (/<|>/.test(value)) {
      return Promise.reject(
        "Please enter alphanumeric characters. Special characters are allowed apart from <>"
      );
    } else {
      return Promise.resolve();
    }
  },
});

export const alphanumericAllowedWithoutScriptTagsAndCurlyBraces = () => ({
  pattern: new RegExp(/^[a-zA-Z0-9!@#$%^&*()¬£₹_+\-=\[\];':"\\|,.\/?`~ ]*$/),
  message:
    "Please enter alphanumeric characters. Special characters are allowed apart from <> and {}",
});

const alphanumericSpaceAllowed = () => ({
  pattern: new RegExp(/^(?=.*[a-zA-Z0-9])[-'a-zA-Z0-9 ]+$/),
  message: "Only alphanumeric characters including space, - and ' are allowed",
});

const onlyAlphanumericAllowed = () => ({
  pattern: new RegExp(/^[a-zA-Z0-9]*$/),
  message: "Only alphanumeric characters are allowed",
});

const alphanumericHyphenUnderscoreAllowed = () => ({
  pattern: new RegExp(/^[a-zA-Z0-9-_\s]*$/),
  message: "Only alphanumeric characters - and _ are allowed",
});

export const tagsNotAllowed = () => ({
  pattern: new RegExp(/^(?!.*<[^>]+>).*/),
  message: "Tags are not allowed",
});

export const atLeastOneUpperCase = () => ({
  pattern: new RegExp(/^(?=.*[A-Z]).*$/),
  message: "At least one uppercase character",
});

export const atLeastOneLowerCase = () => ({
  pattern: new RegExp(/^(?=.*[a-z]).*$/),
  message: "At least one lowercase character",
});

export const atLeastOneDigit = () => ({
  pattern: new RegExp(/\d/),
  message: "At least one number",
});

export const atLeastOneSpecialCharacter = () => ({
  pattern: new RegExp(/[#?!@$%^&*+;:~-]+/),
  message:
    "At least one special character. e.g. !, @, #, $, %, ^, &, *, ?, :, ;, ~, +, - ",
});

const passwordValidation = (value) => ({
  pattern: new RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
  ),
  message: `${value} length should be minimum 8 characters and must contain at least one lowercase letter, uppercase letter, number, and special character`,
});

export const matchConfirmPassword = (getFieldValue, matchWith) => ({
  validator(rule, value) {
    if (!value) {
      return Promise.reject(new Error("Please confirm your password"));
    }
    if (getFieldValue(matchWith) === value) {
      return Promise.resolve();
    }
    return Promise.reject("Passwords do not match");
  },
});

export const passwordSameAsUsername = (getFieldValue, matchWith = "email") => ({
  validator(rule, value) {
    if (!value || getFieldValue(matchWith) !== value) {
      return Promise.resolve();
    }
    return Promise.reject("Password cannot be same as username");
  },
});

export const uniqueValueValidation = (fieldName, array, condition) => ({
  validator(_, value) {
    if (value && array.includes(value.trim()) && condition) {
      return Promise.reject(`${fieldName} must be unique`);
    }
    return Promise.resolve();
  },
});

export const CARD_NICK_NAME_RULES = [
  getRequiredRule("card nickname"),
  getMaxRule(50),
  getValidTextRule(),
];

export const LIMIT_PURPOSE_RULES = [
  getRequiredRule("description"),
  getMaxRule(200),
  getValidTextRule(),
  specialAllowedCharacters(),
];

export const OTP_MODAL_RULES = [
  getRequiredRule("verification code"),
  getMinRule(6, true),
];

export const PO_ITEM_FORM_RULE = {
  ITEM_NAME: [
    getRequiredRule("", false, "Please enter a description for this line item"),
  ],
  QUANTITY: [
    getRequiredRule("", false, "Please enter the quantity for this line item"),
    gtZero("Quantity"),
  ],
  UNIT_COST: [
    getRequiredRule(
      "",
      false,
      "Please enter the cost for each unit of this line item"
    ),
    nltZero("Cost"),
    ltBillion("Cost"),
  ],
};

export const PO_CREATE_FORM_RULE = {
  ENTITY: [getRequiredRule("an entity from your organisation's list", true)],
  SUPPLIER: [
    getRequiredRule(
      "",
      true,
      `Please select a ${LC_SUPPLIER_PLACEHOLDER} from your organisation's approved ${LC_SUPPLIER_PLACEHOLDER} list`
    ),
  ],
  DESCRIPTION: [
    getRequiredRule("", false, "Please enter a description"),
    getMaxRule(240),
  ],
  OWNER: [
    getRequiredRule(
      "",
      true,
      "Please enter the user responsible for accepting the goods or services"
    ),
  ],
  GROSS_AMOUNT: ({ currencySymbol }) => [
    getRequiredRule(
      "",
      false,
      "Please enter the expected cost of the goods or services"
    ),
    nltZero("Total amount"),
    ltBillion("Total amount", currencySymbol),
  ],
  NET_AMOUNT: ({ currencySymbol }) => [
    getRequiredRule(
      "",
      false,
      "Please enter the expected cost of the goods or services"
    ),
    nltZero("Net amount"),
    ltBillion("Net amount", currencySymbol),
  ],
  CURRENCY: [
    {
      required: true,
      message: "Please enter currency",
    },
  ],
  DELIVERY_DATE: [
    getRequiredRule(
      "",
      false,
      "Please enter the date by which delivery is expected to be completed"
    ),
  ],
  START_DATE: [
    {
      required: false,
      message: "Please enter the date by which delivery is expected to start",
    },
  ],
  BUSINESS_UNIT: [getRequiredRule("business unit")],

  TAX_CODE: [getRequiredRule("tax code", true)],
};

export const PO_ASSIGN_FORM_RULE = {
  APPROVER: [getRequiredRule("first approver", true)],
};

export const INVOICE_WORKFLOW_MODAL = {
  WORKFLOW_TYPE: [getRequiredRule("workflow type", true)],
  APPROVER: [getRequiredRule("", true, WORKFLOW_CONSTANT.APPROVER_ERROR)],
};

export const CUSTOM_WATER_FORM = {
  MULTIPLE_LABLE_TYPE: [
    getRequiredRule("label", true),
    getMaxRule(100),
    getValidTextRule(),
  ],
  LABLE_TYPE: [
    getRequiredRule("label", true),
    getMaxRule(100),
    getValidTextRule(),
  ],
};

export const INVOICE_MODAL_WORKFLOW_NAME = [
  getRequiredRule("", false, WORKFLOW_CONSTANT.WORKFLOW_NAME_ERROR),
];

export const IM_INVOICE_NUMBER_FIELD = [getRequiredRule("invoice number")];

export const IM_INVOICE_DESC_FIELD = [];

export const SM_PAYEE_NAME = [
  getRequiredRule(`${LC_SUPPLIER_PLACEHOLDER} name`),
];

export function amountFormatterWithoutCurrency(value) {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function negativeZeroAmountFormatterWithoutCurrency(value, info) {
  const updatedValue = info?.input === "-0" && value === "0" ? "-0" : value;
  return amountFormatterWithoutCurrency(updatedValue);
}

export const PO_MARK_PAID_MODEL = {
  title: "Mark as paid",
  header:
    "You are about to mark this invoice as paid suggesting that your organisation has paid this invoice off-platform",
  checkTitle: "I understand that this action cannot be undone.",
  btMarkASPAid: "Mark as paid",
  btCancel: "Cancel",
  titleApprove: "Approve invoice",
  headerApprove:
    "You are about to approve this invoice. Once approved, this invoice will be moved to the to pay tab, ready for payment.",
  btnApprove: "Approve now",
};

export const REGISTRATION_FORM = {
  ANALYTICS_TYPE: {
    FIRST_NAME: [
      getRequiredRule("your first name"),
      getMinRule(2, false, `Minimum length 2 characters`),
    ],
    LAST_NAME: [
      getRequiredRule("your surname"),
      getMinRule(3, false, `Minimum length 3 characters`),
    ],
    COMPANY_NAME: [
      getRequiredRule("your company name", true),
      getMinRule(3, false, `Minimum length 3 characters`),
    ],
    JOB_TITLE: [
      getRequiredRule("your job title", true),
      getMinRule(3, false, `Minimum length 3 characters`),
    ],
    COUNTRY: [getRequiredRule("your country", true)],
    EMAIL: [
      getRequiredRule("your business email address"),
      getValidEmailRule(),
    ],
    PASSWORD: [
      getRequiredRule("password"),
      getMinRule(8, false, `A minimum of 8 characters`),
      atLeastOneUpperCase(),
      atLeastOneLowerCase(),
      atLeastOneDigit(),
      atLeastOneSpecialCharacter(),
      tagsNotAllowed(),
      ({ getFieldValue }) => passwordSameAsUsername(getFieldValue),
    ],
    PASSWORD_EXIST: [
      getRequiredRule("password"),
      getMinRule(8, false, `A minimum of 8 characters`),
    ],
    CONFIRM_PASSWORD: [
      ({ getFieldValue }) => matchConfirmPassword(getFieldValue, "password"),
    ],
    MOBILE: [getRequiredRule("your mobile number")],
    DOB: [getRequiredRule("your date of birth")],
    KBA_QUESTION: [
      getRequiredRule("the name of your favourite childhood friend"),
    ],
  },
  PO_TYPE: {
    PASSWORD: [
      getRequiredRule("password"),
      getMinRule(8, false, `A minimum of 8 characters`),
      atLeastOneUpperCase(),
      atLeastOneLowerCase(),
      atLeastOneDigit(),
      atLeastOneSpecialCharacter(),
    ],
    PASSWORD_EXIST: [
      getRequiredRule("password"),
      getMinRule(8, false, `A minimum of 8 characters`),
    ],
    COUNTRY: [getRequiredRule("your country", true)],
  },
  SSO: {
    COUNTRY: [getRequiredRule("country", true)],
    MOBILE: [getRequiredRule("your mobile number")],
    DOB: [getRequiredRule("your date of birth")],
    KBA_QUESTION: [
      getRequiredRule("the name of your favourite childhood friend"),
    ],
  },
};
export const CUSTOM_EXPENSE_FORM = {
  NAME: [getRequiredRule("name"), getMaxRule(100), getValidTextRule()],
};
export const PROFILE_FORM = {
  FIRST_NAME: [
    getRequiredRule("your first name"),
    getMinRule(2, false, `Minimum length 2 characters`),
  ],

  LAST_NAME: [
    getRequiredRule("your last name"),
    getMinRule(3, false, `Minimum length 3 characters`),
  ],
  EMAIL: [getRequiredRule("your email address"), getValidEmailRule()],
  MOBILE: [getRequiredRule("your mobile number")],
  DOB: [getRequiredRule("your date of birth")],
  FRIEND: [getRequiredRule("the name of your childhood friend")],
};

export const WORKFLOW_FORM = {
  STEP_TYPE: [getRequiredRule("step type", true)],
};

export const PAYMENT_JOURNEY_FORM = {
  PAY_NOW: {
    SORTCODE: [
      getRequiredRule("sort code"),
      getMinAndMaxRule("sort code", 6, 6),
    ],
    ACCOUNT_NUMBER: [
      getRequiredRule("account number"),
      getMinAndMaxRule("account number", 8, 8),
    ],
    EMAIL: [getRequiredRule(" email address"), getValidEmailRule()],
    PAYEE: [getRequiredRule(` ${LC_SUPPLIER_PLACEHOLDER}`, true)],
    SUPPLIER_ACCOUNT: [
      getRequiredRule(` ${LC_SUPPLIER_PLACEHOLDER} account`, true),
    ],
  },
  SCHEDULED: {
    PAYMENT_DATE: [getRequiredRule(" payment date", true)],
  },
};

export const ASSIGN_WORKFLOW_MODAL = {
  WORKFLOW_NAME: [
    getRequiredRule(
      "",
      true,
      "Please select whether to assign to workflow or individual"
    ),
  ],
};

export const PAYMENT_RUNS_DRAWER = {
  PAYMENT_RUN_NAME: [
    getRequiredRule("payment run name", false),
    getMinRule(3),
    getMaxRule(40),
  ],
  SCHEDULED_DATE: [getRequiredRule("scheduled date", false)],
};

export const PR_SCHEDULE_PAYMENT_MODAL = {
  FUND_ACCOUNT: [getRequiredRule(" fund account", true)],
  SCHEDULED_DATE: [getRequiredRule(" scheduled date", true)],
  SORTCODE: [
    getRequiredRule(" sort code"),
    getMinAndMaxRule("sort code", 6, 6),
  ],
  ACCOUNT_NUMBER: [
    getRequiredRule(" account number"),
    getMinAndMaxRule(" account number", 8, 8),
  ],
  EMAIL: [getRequiredRule(" email address"), getValidEmailRule()],
  EMAIL_VALIDATION: [getValidEmailRule()],
  SUPPLIER: [getRequiredRule(" supplier", true)],
  SUPPLIER_ACCOUNT: [getRequiredRule(" supplier account", true)],
};

export const SUPPLIER_MANAGEMENT = {
  ADD_SUPPLIER_FORM: {
    SUPPLIER_NAME: [
      getRequiredRule(`${LC_SUPPLIER_PLACEHOLDER} name`),
      getMaxRule(70),
    ],
    ACCOUNT_NUMBER: [
      getRequiredRule("account number"),
      getMinAndMaxRule(" account number", 8, 8),
    ],
    SORT_CODE: [
      getRequiredRule("sort code"),
      getMinAndMaxRule("sort code", 6, 6),
    ],
    EMAIL: [getValidEmailRule()],
    SUPPLIER_ACCOUNT: {
      ACCOUNT_TYPE: [getRequiredRule("account type", true)],
      ACCOUNT_NAME: [
        getRequiredRule("account name"),
        getMaxRule(100),
        getMinRule(2),
      ],
      ACCOUNT_NUMBER: [
        getRequiredRule("account number"),
        getMinAndMaxRule(" account number", 8, 8),
      ],
      SORT_CODE: [
        getRequiredRule("sort code"),
        getMinAndMaxRule("sort code", 6, 6),
      ],
      CURRENCY: [getRequiredRule("currency", true)],
      BANK_ADDRESS: {
        ADDRESS_LINE_1: [getRequiredRule("address field")],
        POST_CODE: [getRequiredRule("postcode")],
        COUNTRY: [getRequiredRule("country", true)],
      },
    },
  },
};

export const THRESHOLD_MATCHING = [getRequiredRule("threshold range")];
export const CARD_EXPENSE_FORM = {
  VAT: [getRequiredRule("VAT amount")],
  RECEIPT: [getRequiredRule(null, null, "Receipt is required")],
};

export const CUSTOM_FIELD_RULES = {
  OPTION: [getRequiredRule("option", true)],
  TYPE: [getRequiredRule("type", true)],
};

export const PR_CONFIG_PAYMENT_FILE = {
  FILE_PROCESSING_DATE: [getRequiredRule("processing date", true)],
  SORT_CODE: [
    getRequiredRule("sort code"),
    getMinAndMaxRule("sort code", 6, 6),
  ],
  ACCOUNT_NUMBER: [
    getRequiredRule("account number"),
    getMinAndMaxRule(" account number", 8, 8),
  ],
};

export const GRNI_FORM_FIELD_RULES = {
  DATE: [getRequiredRule("date of goods receipt", true)],
  DESCRIPTION: [
    getRequiredRule("description of goods received"),
    getMaxRule(255),
  ],
  RECEIVED_AMOUNT: ({ currency, minAmount, maxAmount }) => [
    gtAndltAmount({
      label: "value of goods received",
      minAmount,
      maxAmount,
      currency,
    }),
  ],
};

export const LOGIN_FORM = {
  EMAIL: [getRequiredRule(" email address"), getValidEmailRule()],
  PASSWORD: [getRequiredRule("your password")],
};
export const OTP_FORM = {
  verificationCode: [
    getRequiredRule("verification code"),
    getMinAndMaxRule("code", 6, 6, "Please enter a valid verification code"),
  ],
};

export const FORGOT_PASSWORD_FORM = {
  EMAIL: [getRequiredRule(" email address"), getValidEmailRule()],
  PASSWORD: [
    getRequiredRule("your new password"),
    getMinRule(8, false, `A minimum of 8 characters`),
    atLeastOneUpperCase(),
    atLeastOneLowerCase(),
    atLeastOneDigit(),
    atLeastOneSpecialCharacter(),
    tagsNotAllowed(),
    ({ getFieldValue }) => passwordSameAsUsername(getFieldValue),
  ],
  CONFIRM_PASSWORD: [
    ({ getFieldValue }) => matchConfirmPassword(getFieldValue, "password"),
  ],
  verificationCode: [
    getRequiredRule("verification code"),
    getMinAndMaxRule("code", 6, 6, "Please enter a valid verification code"),
  ],
};
export const SEND_SUPPIER_EMAIL_FORM = {
  EMAIL: [getRequiredRule(" email address"), getValidEmailRule()],
};

export const GRNI_OFFSET_MODAL_FIELD_RULES = {
  OFFSET_AMOUNT: ({ minOffsetAmount, maxOffsetAmount, currency }) => {
    return [
      gtAndltAmount({
        label: "GRNI offset amount",
        minAmount: Number(minOffsetAmount),
        maxAmount: maxOffsetAmount,
        minAmountErrorMsg: "You cannot exceed the total GRNI amount",
        maxAmountErrorMsg: `The required GRNI offset amount cannot be less than ${moneyFormatter(
          Number(minOffsetAmount),
          2,
          currency
        )}`,
        currency,
      }),
    ];
  },
};

export const IM_BULK_ACTION_FORM = {
  WORKFLOW_FIELD: [getRequiredRule("a workflow", true)],
};

export const CONDITIONAL_FIELDS_FORM = {
  NAME: [getRequiredRule("name"), getMaxRule(100), getValidTextRule()],
  VISIBILITY: [getRequiredRule("field visibility", true)],
  FIELD_TYPE: [getRequiredRule("field type", true)],
  OPTION: [getRequiredRule("option", true)],
};

export const VALUES_FIELD = {
  values: [getRequiredRule("value"), getValidTextRule(), getMaxRule(100)],
};

export const TAX_CODES = {
  NAME: [
    getRequiredRule("a tax code name to proceed."),
    getMaxRule(100),
    getValidTextRule(),
  ],
  VAT: [getRequiredRule("a tax rate to proceed.")],
};

export const ENTITY = {
  NAME: [
    getRequiredRule("a entity name to proceed."),
    getMaxRule(100),
    getValidTextRule(),
  ],
};

export const PREFIX = {
  NAME: [
    getRequiredRule("a prefix to proceed."),
    getMaxRule(4, "Please enter a prefix that is less than four characters."),
    onlyAlphanumericAllowed(),
  ],
};

export const IM_CREATE_INVOICE_FORM_RULE = {
  CURRENCY: [getRequiredRule("currency", true)],
  NET_AMOUNT: [nltZero("Net amount"), ltBillion("Net amount")],
  TAX_AMOUNT: [nltZero("Tax amount")],
  NET_AMOUNT_MANDATORY: [
    getRequiredRule("Net amount", true),
    nltZero("Net amount"),
    ltBillion("Net amount"),
  ],
  TAX_AMOUNT_MANDATORY: [
    getRequiredRule("Tax amount", true),
    nltZero("Tax amount"),
  ],
  TOTAL_AMOUNT: [nltZero("Tax amount")],
  TOTAL_AMOUNT_MANDATORY: [
    getRequiredRule("Total amount", true),
    nltZero("Total amount"),
  ],
  TAX_CODE: [getRequiredRule("tax code", true)],
  CHART_OF_ACCOUNTS: [getRequiredRule("account", true)],
  TAX_RATE: [getRequiredRule("tax rate", true)],
  TRACKING_CATEGORIES: [getRequiredRule("a value", true)],
  ITEM_LIST: [getRequiredRule("item", true)],
};

export const CREATE_CREDIT_NOTES_FORM_RULE = {
  CREDIT_NOTE_NUMBER: [
    getRequiredRule("credit note number"),
    getMinRule(1),
    getMaxRule(50),
  ],
  CREDIT_NOTE_DATE: [getRequiredRule("credit note date")],
  SUPPLIER: [getRequiredRule(LC_SUPPLIER_PLACEHOLDER, true)],
  INVOICE_NUMBER: [getMaxRule(50)],
  NET_AMOUNT: [
    nltZero("Amount"),
    ltBillion("Amount"),
    getRequiredRule("amount"),
  ],
  TAX_RATE: [
    getRequiredRule("tax rate"),
    {
      pattern: RE_DIGIT_PERCENTAGE,
      message: "Please enter value between 0 and 100",
    },
  ],
  DESCRIPTION: [getMaxRule(300)],
};

export const CREATE_USER = {
  FIRST_NAME: [
    getRequiredRule(`first name`),
    getMinRule(2, false, `Minimum length 2 characters`),
    alphanumericSpaceAllowed(),
  ],
  LAST_NAME: [
    getRequiredRule(`last name`),
    getMinRule(3, false, `Minimum length 3 characters`),
    alphanumericSpaceAllowed(),
  ],
  EMAIL: [getRequiredRule("user's company email address"), getValidEmailRule()],
  ROLE: [getRequiredRule(`a role`, true)],
};

export const WORKFLOW_SELECT_RULE = [
  getRequiredRule("workflowSelect", true, "Please select a workflow"),
];

export const INVOICE_FORM_RULES = {
  AMOUNT: [
    getRequiredRule(`amount`),
    {
      pattern: /^(\d+(\.\d+)?)$/,
      message: "Please enter valid amount",
    },
  ],
  VAT_RATE: [
    {
      pattern: new RegExp(/\d+/g),
      message: "Please enter valid VAT rate",
    },
  ],
  SUPPLIER: [getRequiredRule(LC_SUPPLIER_PLACEHOLDER, true)],
  INVOICE_DATE: [getRequiredRule(`invoice date`)],
  DUE_DATE: [getRequiredRule(`due date`)],
  TAX_SOLUTION: [getRequiredRule("a tax solution.", true)],
};

export const LINE_ITEMS_FORM_RULES = {
  DESCRIPTION: [getRequiredRule(`description`)],
  UNIT_COST: [
    getRequiredRule(
      "",
      false,
      "Please enter the cost for each unit of this line item"
    ),
    nltZero("Cost"),
    ltBillion("Cost"),
  ],
  QUANTITY: [
    getRequiredRule("", false, "Please enter the quantity for this line item"),
    gtZero("Quantity"),
  ],
  CHART_OF_ACCOUNTS: [getRequiredRule("account", true)],
  TAX_RATE: [getRequiredRule("tax rate", true)],
  ITEM_LIST: [getRequiredRule("item", true)],
  TRACKING_CATEGORIES: [getRequiredRule("a value", true)],
};

export const MAX_FILE_SIZE = (maxSize = "4", customMessage = "") => {
  return {
    maxSize,
    maxSizeErrorMessage: customMessage
      ? customMessage
      : `File size must be ${maxSize}MB or less. Please reduce the file size or upload a different document`,
  };
};

import { Typography } from "../components/common";
import {
  CATEGORISATION_CONSTANTS,
  INVOICE_MANAGEMENT_TAB_KEYS,
} from "../constants";
import {
  getOwnerNameInColumns,
  getRaisedByNameInColumns,
  isListingApiKeysRefactored,
} from "../helper";
import { getLineItemWrapper } from "../helper/LineItems";
import {
  customExpenseGetList,
  getCustomFieldValuesList,
} from "../redux/action/setting";
import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";
import {
  GEN_AI_INVOICE_EMAIL_ID,
  GET_PREDEFINE_LIST,
} from "../redux/service/types";
import {
  DEFAULT_FORMAT,
  appendKeyForFilters,
  generateEncodedURIString,
  getFormattedDate,
  convertJSObjectToFormData,
  GLOBAL_NOT_APPLICABLE_SYMBOL,
  renderAmount,
  ACCOUNTS_PAYABLE_APPROVAL,
  LOCAL_STORAGE_KEY,
  getItem,
} from "../utils";

export async function getInvoiceManagementList({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
  statusUrl = "",
  pageSize = 10,
  nextApproverUrl = "",
  cancelPreviousRequest = false,
}) {
  const pageSizeURL = generateEncodedURIString(
    "perPageCount",
    pageSize.toFixed()
  );

  const tempResponse = await apiClient({
    url: `${PATH.invoiceManagement.listing}?page=${page}${statusUrl}${nextApproverUrl}${filtersUrl}${searchUrl}${pageSizeURL}`,
    method: "GET",
    cancelPreviousRequest,
  });
  const response = {
    data: {
      list: tempResponse?.data?.result?.data?.map(
        ({
          supplier,
          user_organization_details,
          owner_organization_details,
          currency,
          amount,
          invoice_payments,
          due_date,
          paymentrun_name,
          status,
          invoice_payment_date,
          invoice_on_hold_details,
          owner_name,
          user_name,
          currency_symbol,
          draft_date,
          ...rest
        }) => {
          return {
            payee:
              status === INVOICE_MANAGEMENT_TAB_KEYS.DELETE
                ? GLOBAL_NOT_APPLICABLE_SYMBOL
                : supplier?.name || GLOBAL_NOT_APPLICABLE_SYMBOL,
            raised_by: isListingApiKeysRefactored()
              ? user_name || GLOBAL_NOT_APPLICABLE_SYMBOL
              : getRaisedByNameInColumns({ user_organization_details }),
            owner_name: isListingApiKeysRefactored()
              ? owner_name || GLOBAL_NOT_APPLICABLE_SYMBOL
              : getOwnerNameInColumns({ owner_organization_details }),
            totalAmount: `${currency?.symbol || "£"}${renderAmount(
              amount || 0,
              "commaWithDecimal"
            )}`,
            scheduledDate: `${getFormattedDate(
              invoice_payments?.payment_scheduled_type === "scheduled"
                ? invoice_payments?.payment_scheduled_at
                : invoice_payments?.created_at,
              DEFAULT_FORMAT,
              GLOBAL_NOT_APPLICABLE_SYMBOL
            )}`,
            formattedDueDate: getFormattedDate(
              due_date,
              DEFAULT_FORMAT,
              GLOBAL_NOT_APPLICABLE_SYMBOL
            ),
            previous_status: invoice_on_hold_details?.pervious_status,
            invoice_on_hold_details,
            supplier,
            currency,
            amount,
            invoice_payments,
            due_date,
            paymentrun_name: paymentrun_name || GLOBAL_NOT_APPLICABLE_SYMBOL,
            invoice_payment_date:
              invoice_payment_date || GLOBAL_NOT_APPLICABLE_SYMBOL,
            status,
            creationDate: getFormattedDate(
              draft_date,
              DEFAULT_FORMAT,
              GLOBAL_NOT_APPLICABLE_SYMBOL
            ),
            ...rest,
          };
        }
      ),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
      filters: [],
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function getInvoiceManagementListDownload({
  page = 1,
  filtersUrl = "",
  searchUrl = "",
  statusUrl = "",
  nextApproverUrl = "",
}) {
  const tempResponse = await apiClient({
    url: `${PATH.invoiceManagement.download}?page=${page}${statusUrl}${nextApproverUrl}${filtersUrl}${searchUrl}`,
    method: "GET",
  });
  const response = {
    data: {
      downloadLink: tempResponse?.data?.result?.["download-link"],
    },
  };
  return response.data;
}

export async function createPaymentRequest(payload) {
  const tempResponse = await apiClient({
    url: PATH.accountPayable.invoicePayment,
    method: "POST",
    data: payload,
  });

  return tempResponse.data;
}

export async function reSchedulePaymentRequest(payload) {
  const tempResponse = await apiClient({
    method: "PATCH",
    url: PATH.accountPayable.reschedulePayment,
    data: payload,
  });

  return tempResponse.data;
}

export async function resubmitRejectedInvoices(data, id, file) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.accountPayable.resubmitRejectedInvoice(id),
    data: convertJSObjectToFormData(data),
  });
  return tempResponse.data;
}

export const setCustomExpenseDetails =
  ({ getPredefineList, setCustomFieldValueLoader }) =>
  async (dispatch) => {
    let CustomPayload = {};
    setCustomFieldValueLoader(true);
    await dispatch(
      customExpenseGetList(
        "custom_categories",
        CATEGORISATION_CONSTANTS.ACCOUNT_PAYABLE,
        true
      )
    )
      .then(async (res) => {
        if (res?.data?.result && res?.data?.result?.length > 0) {
          // Use Promise.all to wait for all the async calls to complete
          await Promise.all(
            res?.data?.result.map(async (field, i) => {
              if (field?.attribute_type !== "textbox") {
                try {
                  const fieldValuesRes = await dispatch(
                    getCustomFieldValuesList(
                      field?.id,
                      field?.attribute_type,
                      field?.field_name,
                      getPredefineList,
                      null,
                      true
                    )
                  );

                  CustomPayload = {
                    ...CustomPayload,
                    [field?.attribute_type]: {
                      ...(Object.keys(CustomPayload).includes(
                        field?.attribute_type
                      ) && { ...CustomPayload[field?.attribute_type] }),
                      [field?.field_name]: fieldValuesRes?.data?.result,
                    },
                  };
                  dispatch({
                    type: GET_PREDEFINE_LIST,
                    payload: CustomPayload || {},
                  });
                } catch (error) {
                  dispatch({
                    type: GET_PREDEFINE_LIST,
                    payload: CustomPayload || {},
                  });
                  // Handle the error as needed
                }
              }
            })
          ).finally(() => setCustomFieldValueLoader(false));
        }
      })
      .finally(() => setCustomFieldValueLoader(false));
  };

export async function getDOBRequirementDetails() {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.user.getDOBRequirement,
  });

  return tempResponse.data.result;
}

export async function updateDOB(payload) {
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.user.getDOBRequirement,
    data: payload,
  });

  return tempResponse.data.result;
}

export async function deleteInvoice({ id }) {
  return apiClient({
    method: "DELETE",
    url: `${PATH.invoiceManagement.delete(id)}`,
  });
}

export function changeInvoiceStatus({ id, status }) {
  return apiClient({
    method: "PATCH",
    url: `${PATH.invoiceManagement.changeStatus(id, status)}`,
  });
}

export const reAssignWorkflowInvoice = async (action, workflowId, refIds) => {
  const payload = {
    workflow_id: workflowId,
    invoices: refIds,
    type: ACCOUNTS_PAYABLE_APPROVAL,
  };
  const tempResponse = await apiClient({
    method: "PUT",
    url: PATH.invoice.reassignWorkflow(action),
    data: payload,
  });

  return tempResponse.data.result;
};

export async function getAuditLogByInvoiceId({
  id,
  page = 1,
  filtersUrl,
  searchUrl = "",
}) {
  const tempResponse = await apiClient({
    url: `${PATH.accountPayable.getAuditLogByInvoiceId(
      id
    )}?page=${page}${filtersUrl}${searchUrl}`,
    method: "GET",
  });
  const response = {
    data: {
      list: tempResponse?.data?.result?.data.map(
        ({ id, email, actioning_user, content, created_at }) => {
          return {
            id,
            email,
            user: actioning_user,
            before: content?.before?.map((item) => (
              <Typography
                text={`${item.action}${
                  "value" in item ? `: ${item?.value || ""}` : ""
                }`}
                variant={"body"}
                ellipsis={{
                  tooltip: `${item.action}${
                    "value" in item ? `: ${item.value || ""}` : ""
                  }`,
                }}
              />
            )),
            after: content?.after?.map((item) => (
              <Typography
                text={`${item.action}${
                  "value" in item ? `: ${item.value || ""}` : ""
                }`}
                variant={"body"}
                ellipsis={{
                  tooltip: `${item.action}${
                    "value" in item ? `: ${item.value || ""}` : ""
                  }`,
                }}
              />
            )),
            date: created_at,
          };
        }
      ),
      page: tempResponse?.data?.result?.meta?.current_page,
      pageSize: tempResponse?.data?.result?.meta?.per_page,
    },
  };

  if (page === 1) {
    response.data.filters = appendKeyForFilters(
      tempResponse?.data?.result?.meta?.filters
    );
  }
  return response.data;
}

export async function validatePaymentRequest(payload) {
  const tempResponse = await apiClient({
    url: PATH.accountPayable.validatePayment,
    method: "POST",
    data: payload,
  });
  return tempResponse?.data?.result?.validate;
}

export const customFieldsFromGenAI = async (data) => {
  const tempResponse = await apiClient({
    url: PATH.accountPayable.customFieldByAi,
    method: "POST",
    data,
  });
  return tempResponse?.data?.result?.data;
};

export async function invoiceBulkRequest(data) {
  return apiClient({
    url: PATH.accountPayable.bulkAction,
    method: "PATCH",
    data,
  });
}

export async function bulkAssignWorkflow(data) {
  return apiClient({
    url: PATH.invoice.bulkAssignWorkflow,
    method: "POST",
    data,
  });
}

export const getOrganisationAIEmail = () => async (dispatch) => {
  const orgId = getItem(LOCAL_STORAGE_KEY.ORD_ID);
  dispatch({
    type: GEN_AI_INVOICE_EMAIL_ID,
    payload: { loader: true },
  });
  const result = await apiClient({
    url: PATH.invoice.emailUpload(orgId),
    method: "GET",
  });

  dispatch({
    type: GEN_AI_INVOICE_EMAIL_ID,
    payload: { loader: false, data: result.data.result[0] },
  });

  return result.data.result[0];
};

export async function getAiTriggers(data) {
  const tempResponse = await apiClient({
    url: PATH.accountPayable.getAiTriggers,
    method: "POST",
    data,
  });
  return tempResponse.data.result;
}

export async function getCustomExpenseValuesForInvoice({ id }) {
  const tempResponse = await apiClient({
    method: "GET",
    url: `${PATH.invoiceManagement.getCustomExpenseValues(id)}`,
  });
  return tempResponse?.data?.result?.data;
}

export async function checkEntityMappingStatus(entityId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.integrations.checkEntityMappingStatus(entityId),
  });
  return tempResponse?.data?.result;
}

export async function getErpAccounts(entityId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.integrations.getErpAccounts(entityId),
  });
  return tempResponse?.data?.result;
}

export async function getErpItemLists(entityId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.integrations.getErpItemLists(entityId),
  });
  return tempResponse?.data?.result;
}

export async function getTrackingCatgories(
  entityId,
  category,
  page,
  size,
  search
) {
  const tempResponse = await apiClient({
    method: "GET",
    url: `${PATH.integrations.getTrackingCatgories(entityId)}${
      category ? `&&category_type=${category}` : ""
    }${page ? `&&page=${page}` : ""}${size ? `&&count=${size}` : ""}${
      search ? `&&search=${search}` : ""
    }`,
  });
  return category
    ? tempResponse?.data?.result?.[category]?.data ||
        tempResponse?.data?.result?.data
    : tempResponse?.data?.result;
}

export async function getTaxRateList(
  entityId,
  classification,
  accountNumber,
  taxSolution
) {
  const xeroParams = `&&classification=${classification}`;
  const sageParams = `&&tax_solution_id=${taxSolution}`;

  const tempResponse = await apiClient({
    method: "GET",
    url: `${PATH.integrations.getTaxRateList(entityId, classification)}${
      taxSolution ? sageParams : xeroParams
    }`,
  });
  return tempResponse?.data?.result;
}

export async function getAllTaxRateList(entityId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.integrations.getAllTaxRateList(entityId),
  });
  return tempResponse?.data?.result;
}

export async function getOCRLineItemDetails(invoiceId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.accountPayable.invoiceOCRLineItemDetails(invoiceId),
  });
  return tempResponse?.data?.result;
}

export async function createDraftInvoice({ data }) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.accountPayable.createDraftInvoice,
    data,
  });

  return tempResponse?.data?.result;
}

export async function updateDraftInvoice({ data, id }) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.accountPayable.updateDraftInvoice({
      id,
    }),
    data,
  });

  return tempResponse?.data?.result;
}

export async function deleteDraftAttachment({ id }) {
  const tempResponse = await apiClient({
    method: "DELETE",
    url: PATH.accountPayable.deleteDraftAttachment({
      id,
    }),
  });

  return tempResponse?.data?.result;
}

export async function getInvoiceDetailsById(id, status) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.accountPayable.getInvoiceDetailsById(id, status),
  });
  const { line_items } = tempResponse?.data?.result;
  return {
    lineItemWrapper: getLineItemWrapper(line_items),
    invoiceDetails: tempResponse?.data?.result,
  };
}

export async function getTaxRatesByEntity(entityId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: PATH.accountPayable.getTaxRatesByEntity(entityId),
  });
  return tempResponse?.data?.result;
}

export async function getTaxSolutionOptions(entityId) {
  const tempResponse = await apiClient({
    method: "GET",
    url: `${PATH.invoice.taxSolutionDropdownOption}?entity_id=${entityId}`,
  });
  return tempResponse?.data?.result;
}

export async function isTaxSolutionValid(data) {
  const tempResponse = await apiClient({
    method: "POST",
    url: PATH.accountPayable.validateTaxSolution,
    data,
  });
  return tempResponse?.data?.result;
}
